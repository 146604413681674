import { FC, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import { DeliveryGroupState } from "../../../../../../state/User/User.state";
import DeliveryAreaZipCodes from "./components/DeliveryAreaZipCodes";
import {
  DeliveryAreaZipCodesInfoProps,
  ZipCode,
} from "./DeliveryAreaZipCodesInfo.interfaces";
import {
  _DeliveryAreaZipCodesInfoHeader,
  _DeliveryAreaZipCodesInfoTextWrapper,
  _DeliveryAreaZipCodesInfoWrapper,
} from "./DeliveryAreaZipCodesInfo.styled";

const StoreDeliveryInfoDumb: FC<DeliveryAreaZipCodesInfoProps> = (props) => {
  const { headline, zipCodes } = props;
  return (
    <_DeliveryAreaZipCodesInfoWrapper>
      <_DeliveryAreaZipCodesInfoTextWrapper>
        <_DeliveryAreaZipCodesInfoHeader style={"m24"} variant={"semibold"}>
          {headline}
        </_DeliveryAreaZipCodesInfoHeader>
        {/*
          <_StoreDeliveryPriceLabel style={"p14"}>
          {deliveryPriceInfo}
        </_StoreDeliveryPriceLabel>
          */}
      </_DeliveryAreaZipCodesInfoTextWrapper>
      <DeliveryAreaZipCodes zipCodes={zipCodes} />
    </_DeliveryAreaZipCodesInfoWrapper>
  );
};
const DeliveryAreaZipCodesInfo: FC = () => {
  const [zipCodes, setZipCodes] = useState<ZipCode[]>([]);
  const activeDeliveryGroup = useRecoilValue(DeliveryGroupState);
  const headline = useMemo(
    () => `Unser Liefergebiet in ${activeDeliveryGroup?.name}`,
    [activeDeliveryGroup]
  );

  useEffect(() => {
    if (activeDeliveryGroup) {
      fetch(`/api/cms/zipcodes/${encodeURIComponent(activeDeliveryGroup.id)}`)
        .then(async (response) => {
          const zipCodes = await response.json();
          if (zipCodes) {
            setZipCodes(zipCodes);
          }
        })
        .catch((err) => {});
    }
  }, [activeDeliveryGroup]);
  return <StoreDeliveryInfoDumb headline={headline} zipCodes={zipCodes} />;
};

export default DeliveryAreaZipCodesInfo;
