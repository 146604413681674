import styled from "@emotion/styled";
import { LibTheme } from "@stadtsalat/component-lib";

export const _HomeIconWrapper = styled.div`
  opacity: 0;
  animation: fadeIn 0.5s forwards;

  & > svg {
    path {
      stroke: ${LibTheme.colors.text} !important;
      fill: ${LibTheme.colors.text} !important;
    }
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
`;
