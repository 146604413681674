import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { FC, forwardRef, memo, useCallback, useEffect, useRef } from "react";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import useCMS from "src/hooks/useCMS";

import useAccount from "../../../../hooks/useAccount";
import useBrowserInfo from "../../../../hooks/useBrowserInfo";
import useClickOutside from "../../../../hooks/useClickOutside";
import { FirstTimeUserState } from "../../../../state/Menu/Menu.state";
import {
  CartCountState,
  OrderState,
} from "../../../../state/Order/Order.state";
import { RouteMap } from "../../../../util/router";
import { ConfiguratorIdState } from "../../hooks/useConfigurator/useConfigurator.state";
import { CMSDataMenu } from "../../MenuViewPage.interfaces";
import {
  CartActiveState,
  MenuBackgroundBlurActiveState,
  StoreAlertAndVoucherBoxHeightSelector,
  UpsellModalActiveState,
} from "../../state/Menu";
import BurgerIcon from "../BurgerIcon";
import SupportIcon from "../SupportIcon";
import HomeIcon from "./components/HomeIcon";
import {
  AccountMenuActiveState,
  MenuActiveState,
  UserActiveState,
} from "./navbar.state";
import {
  _CartValueLoading,
  _LayoutWrapper,
  _NavbarBurgerMenu,
  _NavbarButtonWrapper,
  _NavbarWrapper,
  _OnlyDesktopWrapper,
  _UserIconLoading,
} from "./Navbar.styled";

export const NavbarTestIDs = {
  homeIcon: "home-icon",
  menuIcon: "menu-icon",
  userIcon: "user-icon",
  cartIcon: "bag-icon",
  burgerMenuIcon: "burger-menu-icon",
};

const AccountDropDown = dynamic(() => import("../AccountDropDown"), {
  ssr: false,
});
const AccountMenuDropDown = dynamic(() => import("../AccountMenuDropDown"), {
  ssr: false,
});
const MenuDropDown = dynamic(() => import("../MenuDropDown"), {
  ssr: false,
});

const UserIcon = dynamic(() => import("./components/UserIcon"), {
  ssr: false,
  loading: () => <_UserIconLoading />,
});

const CartValue = dynamic(() => import("./components/CartValue"), {
  ssr: false,
  loading: () => <_CartValueLoading />,
});

const MobileDropDownPortal = dynamic(
  () => import("./components/MobileDropDownPortal"),
  {
    ssr: false,
  }
);

const NavbarDumb = memo(
  forwardRef<
    HTMLDivElement,
    {
      navbarActive: boolean;
      userLoggedIn: boolean;
      onBurgerMenuClick: () => void;
      onHomeClick: () => void;
      onUserClick: () => void;
      onCartClick: () => void;
      cartLabel: string;
      cartItemCount: number;
      onBlurClick: () => void;
      isMobile: boolean;
      alertBoxHeight: number;
      isReady: boolean;
    }
  >((props, ref) => {
    const {
      userLoggedIn,
      navbarActive,
      onUserClick,
      onCartClick,
      onHomeClick,
      onBurgerMenuClick,
      cartItemCount,
      cartLabel,
      alertBoxHeight,
      isMobile,
      isReady,
    } = props;
    return (
      <_NavbarWrapper
        active={navbarActive}
        alertBoxHeight={alertBoxHeight}
        ref={ref}
      >
        <_LayoutWrapper>
          <_NavbarButtonWrapper>
            <HomeIcon
              className={"icon"}
              onClick={onHomeClick}
              testId={NavbarTestIDs.homeIcon}
            />
            <UserIcon
              onUserClick={onUserClick}
              active={userLoggedIn}
              className={"icon"}
            />
            <SupportIcon />
            <CartValue
              cartActive={false}
              onCartClick={onCartClick}
              cartItemCount={cartItemCount}
              cartLabel={cartLabel}
            />
          </_NavbarButtonWrapper>
          <_NavbarBurgerMenu
            onClick={onBurgerMenuClick}
            data-testid={NavbarTestIDs.burgerMenuIcon}
          >
            <BurgerIcon />
          </_NavbarBurgerMenu>
          <MobileDropDownPortal />
          <_OnlyDesktopWrapper>
            <MenuDropDown />
            <AccountMenuDropDown />
            <AccountDropDown id={"desktop"} />
          </_OnlyDesktopWrapper>
        </_LayoutWrapper>
      </_NavbarWrapper>
    );
  })
);

const Navbar: FC = () => {
  const [userActive, setUserActive] = useRecoilState(UserActiveState);
  const [accountMenuActive, setAccountMenuActive] = useRecoilState(
    AccountMenuActiveState
  );
  const [menuActive, setMenuActive] = useRecoilState(MenuActiveState);
  const configuratorId = useRecoilValue(ConfiguratorIdState);
  const [cartActive, setCartActive] = useRecoilState(CartActiveState);
  const isFirstTimeUser = useRecoilValue(FirstTimeUserState);
  const isUpsellModalActive = useRecoilValue(UpsellModalActiveState);
  const setBackgroundBlurActive = useSetRecoilState(
    MenuBackgroundBlurActiveState
  );

  const { pathname, push, isReady } = useRouter();

  const { getCMSValueByKeyGenerator } = useCMS();
  const getNavbarLabels = getCMSValueByKeyGenerator<
    CMSDataMenu,
    CMSDataMenu["navbar"]
  >("navbar");

  const cartItemCount = useRecoilValue(CartCountState);
  const { isLoggedIn } = useAccount();
  const alertBoxHeight = useRecoilValue(StoreAlertAndVoucherBoxHeightSelector);
  const { isMobile } = useBrowserInfo();

  const onHomeClick = useCallback(() => {
    if (pathname.startsWith(RouteMap.MENU())) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      push(RouteMap.MENU()).catch((err) => {});
    }
  }, []);

  const onBurgerMenuClick = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        try {
          const menuActive = await snapshot.getPromise(MenuActiveState);
          setMenuActive(!menuActive);
          setBackgroundBlurActive(!menuActive);
          setUserActive(false);
          setAccountMenuActive(false);
          setCartActive(false);
        } catch (err) {}
      },
    [
      setMenuActive,
      setAccountMenuActive,
      setUserActive,
      setBackgroundBlurActive,
    ]
  );

  const onUserClick = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        try {
          const [accountMenuActive, userActive] = await Promise.all([
            snapshot.getPromise(AccountMenuActiveState),
            snapshot.getPromise(UserActiveState),
          ]);
          if (isLoggedIn) {
            setUserActive(false);
            setMenuActive(false);
            setCartActive(false);
            setBackgroundBlurActive(!accountMenuActive);
            setAccountMenuActive(!accountMenuActive);
          } else {
            setAccountMenuActive(false);
            setMenuActive(false);
            setCartActive(false);
            setBackgroundBlurActive(!userActive);
            setUserActive(!userActive);
          }
        } catch (err) {}
      },
    [
      isLoggedIn,
      setMenuActive,
      setAccountMenuActive,
      setUserActive,
      setBackgroundBlurActive,
      setCartActive,
    ]
  );
  const onCartClick = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        try {
          const [cartActive, order] = await Promise.all([
            snapshot.getPromise(CartActiveState),
            snapshot.getPromise(OrderState),
          ]);
          setUserActive(false);
          setAccountMenuActive(false);
          setMenuActive(false);
          setCartActive(!cartActive);
          setBackgroundBlurActive(!cartActive);
        } catch (err) {}
      },
    [
      setMenuActive,
      setCartActive,
      setUserActive,
      setAccountMenuActive,
      setBackgroundBlurActive,
    ]
  );

  const closeDialogs = useCallback(() => {
    setMenuActive(false);
    setUserActive(false);
    setAccountMenuActive(false);
    setCartActive(false);
    setBackgroundBlurActive(false);
  }, [
    setMenuActive,
    setCartActive,
    setUserActive,
    setAccountMenuActive,
    setBackgroundBlurActive,
  ]);
  const ref = useRef(null);

  useClickOutside({
    ref,
    onClick: closeDialogs,
    addEventListener:
      !cartActive &&
      (!configuratorId || configuratorId === "") &&
      !isUpsellModalActive &&
      !isFirstTimeUser &&
      !accountMenuActive &&
      !menuActive &&
      !userActive,
  });

  useEffect(() => {
    const escapeHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        closeDialogs();
      }
    };
    document.addEventListener("keydown", escapeHandler);
    return () => document.removeEventListener("keydown", escapeHandler);
  }, []);

  return (
    <NavbarDumb
      ref={ref}
      navbarActive={true}
      onHomeClick={onHomeClick}
      onBurgerMenuClick={onBurgerMenuClick}
      userLoggedIn={isLoggedIn}
      onUserClick={onUserClick}
      onCartClick={onCartClick}
      cartItemCount={cartItemCount}
      cartLabel={getNavbarLabels("cartLabel")}
      alertBoxHeight={alertBoxHeight}
      onBlurClick={closeDialogs}
      isMobile={isMobile}
      isReady={isReady}
    />
  );
};

export default Navbar;
