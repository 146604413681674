import StoreInfo = Definitions.StoreInfo;
import { dateIsInStoreOpeningHours, parseISO8601Duration } from "../util/time";

export const getStoreDeliveryTime = (
  estimatedDeliveryDuration: number,
  store: StoreInfo
) => {
  if (!!estimatedDeliveryDuration || !!store?.estimatedDeliveryDuration) {
    const deliveryInMS = !!estimatedDeliveryDuration
      ? estimatedDeliveryDuration
      : parseISO8601Duration(store?.estimatedDeliveryDuration as string);
    if (!!deliveryInMS) return deliveryInMS / 1000 / 60;
  }
  return null;
};

export const isInStoreOpeningTime = (store: StoreInfo) => {
  if (!!store) {
    const isOpen = dateIsInStoreOpeningHours(
      new Date(),
      new Date(store.businessDay.businessHours[0].openingTime),
      new Date(store.businessDay.end)
    );
    return isOpen;
  }
  return false;
};
