"use client";
import { BaseIcon, Icons, Typography } from "@stadtsalat/component-lib";
import { useRouter } from "next/router";
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";

import useClickOutside from "../../../../../../hooks/useClickOutside";
import { InfoFormDropdownProps } from "./InfoFormDropdown.interfaces";
import {
  _ChevronWrapper,
  _Dropdown,
  _DropdownLabel,
  _DropdownOptions,
  _InfoFormDropdownWrapper,
  _InfoFormOption,
  _LabelWrapper,
  _MobileSelect,
} from "./InfoFormDropdown.styled";
import LoadingInfoDropDown from "./LoadingComponent";

const InfoFormDropdown: React.FC<InfoFormDropdownProps> = React.memo(
  (props) => {
    const { children, label, onChange, options, selectedOption, hideMargin } =
      props;
    const { isReady } = useRouter();
    if (!isReady) return <LoadingInfoDropDown />;
    const [isOpen, setIsOpen] = useState(false);
    const [isPending, startTransition] = useTransition();
    const wrapperRef = useRef<HTMLDivElement>(null);
    useClickOutside({
      ref: wrapperRef,
      addEventListener: isOpen,
      onClick: () => setIsOpen(false),
    });
    const handleMobileSelectChange = useCallback(
      (event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange(event.target.value);
      },
      [onChange]
    );

    const handleOptionClick = useCallback(
      (value: string) => {
        startTransition(() => {
          onChange(value);
          setIsOpen(false);
        });
      },
      [onChange, startTransition]
    );

    const selectedLabel = useMemo(
      () => options.find((option) => option.value === selectedOption)?.label,
      [options, selectedOption]
    );

    const renderedOptions = useMemo(
      () =>
        options.map((option) => (
          <_InfoFormOption
            key={`option-${option.value}`}
            isSelected={selectedOption === option.value}
            onClick={() => handleOptionClick(option.value)}
            data-testid={`option-${option.value}`}
          >
            <Typography style="m14">{option.label}</Typography>
          </_InfoFormOption>
        )),
      [options, selectedOption, handleOptionClick]
    );

    const renderedMobileOptions = useMemo(
      () =>
        options.map((option) => (
          <option key={`option-mobile-${option.value}`} value={option.value}>
            {option.label}
          </option>
        )),
      [options]
    );

    return (
      <_InfoFormDropdownWrapper
        hideMargin={hideMargin}
        data-testid="info-select-dropdown-wrapper"
      >
        <_DropdownLabel>
          <Typography style="p12">{label}</Typography>
        </_DropdownLabel>
        <div ref={wrapperRef}>
          <_Dropdown
            onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
            data-testid="dropdown"
          >
            <_MobileSelect
              onChange={handleMobileSelectChange}
              value={selectedOption}
              data-testid="mobile-select"
            >
              {renderedMobileOptions}
            </_MobileSelect>
            <_LabelWrapper style="p14">
              {selectedLabel}
              {children}
            </_LabelWrapper>
            <_ChevronWrapper isOpen={isOpen} className="chevron-wrapper">
              <BaseIcon icon={Icons.CHEVRON_DOWN} />
            </_ChevronWrapper>
          </_Dropdown>
          <_DropdownOptions isOpen={isOpen} data-testid="dropdown-options">
            {renderedOptions}
          </_DropdownOptions>
        </div>
      </_InfoFormDropdownWrapper>
    );
  }
);

export default InfoFormDropdown;
