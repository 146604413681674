import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      d="M140.852 5.586v.079c1.26 2.598 1.26 5.827-.63 7.717L86.833 78.976h.079v44.963l-32.128 16.064V78.897h-.079L1.317 13.303c-1.26-2.599-1.89-5.119-.63-7.717 1.338-2.599 3.858-3.859 6.457-3.859h127.251c2.598 0 5.197 1.26 6.457 3.859ZM35 44.55h71L134 9H7l28 35.55Z"
      transform="matrix(3.52784 0 0 3.61596 0 -6.245)"
    />
  </svg>
);
export default SvgComponent;
