import Loading from "@components/Loading";
import styled from "@emotion/styled";

const _LoadingDrodownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
const _LoadingInfoSelectLabelWrapper = styled.div`
  width: 100%;
  height: 1rem;
`;

const _LoadingInfoSelectWrapper = styled.div`
  width: 90%;
  height: 2.25rem;
`;

const LoadingInfoFormDropDown = () => (
  <_LoadingDrodownWrapper>
    <_LoadingInfoSelectLabelWrapper>
      <Loading
        width={"2.5rem"}
        height={"0.85rem"}
        baseColor="#f5e9d9"
        highlightColor="#f8f3ec"
      />
    </_LoadingInfoSelectLabelWrapper>
    <_LoadingInfoSelectWrapper>
      <Loading
        width={"100%"}
        height={"100%"}
        baseColor="#f5e9d9"
        highlightColor="#f8f3ec"
      />
    </_LoadingInfoSelectWrapper>
  </_LoadingDrodownWrapper>
);

export default LoadingInfoFormDropDown;
