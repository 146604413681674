import dynamic from "next/dynamic";

import ProductCard from "../ProductCard";
import { IProductCardProps } from "../ProductCard/ProductCard.interfaces";

const StoredCustomsProductCard = dynamic(
  () => import("./StoredCustomsProductCard"),
  { ssr: false }
);
const CustomsProductCard = (props: IProductCardProps) => {
  return (
    <>
      <ProductCard {...props} dataTestId={null} />
      <StoredCustomsProductCard
        customsAreAvailable={props.isAvailable}
        dataTestId={props.dataTestId}
      />
    </>
  );
};

export default CustomsProductCard;
