///////// NEW
import { FetcherFunctionResponse } from "@api/fetcher";
import axios from "axios";
import useSWR, { SWRConfiguration } from "swr";

import { CMSStore } from "../../../pages/[[...city]]";
import { CMSConfiguratorResponseData } from "../../../pages/api/cms/configurator";

export type CMSImageMediaType = {
  data: {
    id: number;
    attributes: {
      url: string;
    };
  };
};

interface CMSResponse {
  postPurchase: any;
  paymentError: any;
  orderNotFound: any;
  adyenPaymentErrors: any;
  checkout: any;
}

type CMSCollectionResponse<T> = {
  data: {
    attributes: T;
  }[];
};
export const getCMSCollectionById = async <T>(
  id: string,
  populate: string[],
  authToken?: string
): Promise<T | null> => {
  try {
    const res = await axios.get<CMSCollectionResponse<T>>(
      `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}/api/${id}`,
      {
        ...(authToken
          ? { headers: { Authorization: `Bearer ${authToken}` } }
          : {}),
        params: {
          populate,
        },
      }
    );
    return res.data.data as T;
  } catch (err) {
    return null;
  }
};
export const getCMSDataByID = async (
  id: string,
  populate: string[]
): Promise<CMSResponse | null> => {
  try {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}/api/${id}`,
      {
        params: {
          populate: populate,
        },
      }
    );
    return res.data.data.attributes;
  } catch (err) {
    return null;
  }
};

export const getConfiguratorCMSData =
  async (): Promise<CMSConfiguratorResponseData | null> => {
    try {
      const res = await axios.get("/api/cms/configurator");
      return !!res?.data ? res.data : null;
    } catch (err) {
      return null;
    }
  };

export const getHeroBannerCMSData = (
  swrConfig?: SWRConfiguration
): FetcherFunctionResponse<{ data: CMSStore[] | null }> => {
  const { error, data, mutate } = useSWR<{ data: CMSStore[] }>(
    "/api/cms/heroBanner",
    () => axios.get("/api/cms/heroBanner"),
    swrConfig
  );
  return {
    error,
    data,
    mutate,
  };
};
