import * as React from "react";
import { FC } from "react";

import { HomeIconProps } from "./HomeIcon.interfaces";
import { _HomeIconWrapper } from "./HomeIcon.styled";

const Icon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      fill="#000"
      fillRule="nonzero"
      d="M249.94 23.711c-6.522 0-13.044 2.31-18.207 6.93L4.561 235.257C-4.678 243.41 1.03 258.9 13.528 258.9h46.196v190.216c0 14.946 12.228 27.174 27.173 27.174h81.522c14.945 0 27.173-12.228 27.173-27.174V313.247h108.695v135.868c0 14.946 12.228 27.174 27.174 27.174h81.52c14.946 0 27.175-12.228 27.175-27.174V258.9h46.195c12.5 0 18.478-15.489 8.967-23.64L268.146 30.64c-5.163-4.62-11.685-6.929-18.206-6.929m0 36.25 183.83 165.57c-15.707 2.988-27.582 16.792-27.582 33.368v183.423h-67.934V313.247c0-18.75-15.217-33.967-33.967-33.967H195.592c-18.75 0-33.967 15.217-33.967 33.967v129.075H93.691V258.899c0-16.576-11.875-30.38-27.581-33.369L249.94 59.96"
    />
  </svg>
);

const HomeIcon: FC<HomeIconProps> = (props) => {
  const { onClick, testId, className } = props;

  return (
    <_HomeIconWrapper
      onClick={onClick}
      className={className}
      data-testid={testId}
    >
      <Icon />
    </_HomeIconWrapper>
  );
};

export default HomeIcon;
