import { FC } from "react";

import { OpeningHoursProps } from "./OpeningHours.interfaces";
import {
  _OpeningHourHeadline,
  _OpeningHourLabel,
  _OpeningHoursGroup,
  _OpeningHoursWrapper,
} from "./OpeningHours.styled";

const OpeningHours: FC<OpeningHoursProps> = (props) => {
  const { openingHours, weekdayLabel, weekendLabel } = props;

  return (
    <_OpeningHoursWrapper>
      <_OpeningHoursGroup>
        <_OpeningHourHeadline style={"p16"} variant={"semibold"}>
          {weekdayLabel}
        </_OpeningHourHeadline>
        <_OpeningHourLabel style={"p16"}>
          {openingHours && openingHours.weekdays && openingHours.weekends
            ? `${openingHours.weekdays[0]}-${openingHours.weekdays[1]}`
            : "---"}
        </_OpeningHourLabel>
      </_OpeningHoursGroup>
      <_OpeningHoursGroup>
        <_OpeningHourHeadline style={"p16"} variant={"semibold"}>
          {weekendLabel}
        </_OpeningHourHeadline>
        <_OpeningHourLabel style={"p16"}>
          {openingHours && openingHours.weekdays && openingHours.weekends
            ? `${openingHours.weekends[0]}-${openingHours.weekends[1]}`
            : "---"}
        </_OpeningHourLabel>
      </_OpeningHoursGroup>
    </_OpeningHoursWrapper>
  );
};

export default OpeningHours;
