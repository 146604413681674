import { atom, selector, selectorFamily } from "recoil";
import { ProductGroupState } from "src/state/Products";

import { CartState } from "../../../../state/Order/Order.state";
import {
  getImageForCustomBowl,
  getImageUrlByProduct,
  mapCommentToRemovedIngredientIds,
} from "../../../../util/product.util";
import { IngredientType } from "../../components/Configurator/components/InfoView/components/Ingredients/Ingredients.interfaces";
import {
  ConfiguratorDescriptionEditable,
  ConfiguratorEditedProductInitialCount,
  ConfiguratorInitialAddons,
  ConfiguratorInitialRemovedIngredients,
  ConfiguratorIsOpenState,
  ConfiguratorProductSelector,
  ConfiguratorSelectedDisplayAddons,
  ConfiguratorSelectedItemsSelector,
  ConfiguratorSelectedVariantId,
  StoreProductState,
} from "../../components/Configurator/configurator.state";
import HydratedDietaryInfo = Definitions.HydratedDietaryInfo;
import ProductGroup = Definitions.ProductGroup;

export const ConfiguratorIdState = atom<string | null>({
  key: "ConfiguratorIdState",
  default: null,
});
export const ConfiguratorSequenceIdState = atom<number | null>({
  key: "ConfiguratorSequenceIdState",
  default: null,
});
export const SetConfiguratorBowlBySequenceId = selector<number | null>({
  key: "SetConfiguratorBowlBySequenceId",
  get: () => null,
  set: ({ set, get }, sequenceId) => {
    const internalCart = get(CartState);
    const itemWithSequenceId = internalCart.find(
      (item) => item.sequenceId === sequenceId
    );
    const productGroup = get(ProductGroupState).find((group: ProductGroup) =>
      group.items.some((item) => item.id === itemWithSequenceId?.id)
    );
    if (itemWithSequenceId) {
      set(
        ConfiguratorInitialAddons,
        itemWithSequenceId.addons
          .map((addon) => Array(addon.count).fill(addon.id))
          .flat(1)
      );
      set(
        ConfiguratorSelectedItemsSelector("addons"),
        itemWithSequenceId.addons.map(({ id, count }) => ({
          id,
          count,
        }))
      );
      if (
        itemWithSequenceId?.deliveryDetailInfo?.customerComment &&
        itemWithSequenceId?.deliveryDetailInfo?.customerComment !== ""
      ) {
        const initialRemovedIngredients = mapCommentToRemovedIngredientIds(
          itemWithSequenceId?.deliveryDetailInfo?.customerComment
        );
        set(ConfiguratorInitialRemovedIngredients, initialRemovedIngredients);
        set(RemovedIngredientsSelector, initialRemovedIngredients);
        set(ConfiguratorDescriptionEditable, true);
      }
      set(ConfiguratorIsOpenState, true);
      set(
        ConfiguratorIdState,
        productGroup ? productGroup.id : itemWithSequenceId.id
      );
      set(ConfiguratorSelectedVariantId, itemWithSequenceId.id);
      set(ConfiguratorSequenceIdState, itemWithSequenceId.sequenceId);
      set(ConfiguratorEditedProductInitialCount, itemWithSequenceId.count);
    }
  },
});

export const ConfiguratorImageState = atom<string>({
  key: "ConfiguratorImageState",
  default: "",
});

export const ConfiguratorIsNotCompleteState = atom<boolean>({
  key: "ConfiguratorIsNotCompleteState",
  default: false,
});

export const ConfiguratorDescriptionState = atom<string>({
  key: "ConfiguratorDescriptionState",
  default: "",
});

export const ConfiguratorNameState = atom<string>({
  key: "ConfiguratorNameState",
  default: "",
});

export const ConfiguratorRemovedIngredientsState = atom<string[]>({
  key: "ConfiguratorRemovedIngredientsState",
  default: [],
});

export const ConfiguratorNutritionState = atom<HydratedDietaryInfo>({
  key: "ConfiguratorNutritionState",
  default: {} as HydratedDietaryInfo,
});

export const ConfiguratorTotalPriceState = atom<number>({
  key: "ConfiguratorTotalPriceState",
  default: 0,
});

export const ConfiguratorAddonsState = atom<IngredientType[]>({
  key: "ConfiguratorAddonsState",
  default: [],
});

export const ConfiguratorWarningMessageState = atom<string>({
  key: "ConfiguratorWarningMessageState",
  default: "",
});

export const ImageSelector = selector({
  key: "ImageSelector",
  get: ({ get }) => get(ConfiguratorImageState),
  set: ({ set }, newValue: string) => {
    set(ConfiguratorImageState, newValue);
  },
});

export const ConfiguratorImageSelector = selectorFamily<string, number>({
  key: "_ConfiguratorImageSelector",
  get:
    (size) =>
    ({ get }) => {
      const configuratorProduct = get(ConfiguratorProductSelector);
      const selectedAddons = get(ConfiguratorSelectedDisplayAddons);
      const storeProducts = get(StoreProductState);
      if (
        configuratorProduct &&
        configuratorProduct.tags.includes("product.configurable")
      ) {
        return getImageForCustomBowl(
          {
            ...configuratorProduct,
            addons: selectedAddons.map((addon) =>
              storeProducts.find((product) => product.id === addon.id)
            ),
          },
          size
        );
      } else {
        return getImageUrlByProduct(configuratorProduct, size, true);
      }
    },
});

export const IsConfiguratorNotCompleteSelector = selector({
  key: "IsConfiguratorNotCompleteSelector",
  get: ({ get }) => get(ConfiguratorIsNotCompleteState),
  set: ({ set }, newValue: boolean) => {
    set(ConfiguratorIsNotCompleteState, newValue);
  },
});

export const DescriptionSelector = selector({
  key: "DescriptionSelector",
  get: ({ get }) => get(ConfiguratorDescriptionState),
  set: ({ set }, newValue: string) => {
    set(ConfiguratorDescriptionState, newValue);
  },
});

export const NameSelector = selector({
  key: "NameSelector",
  get: ({ get }) => get(ConfiguratorNameState),
  set: ({ set }, newValue: string) => {
    set(ConfiguratorNameState, newValue);
  },
});

export const RemovedIngredientsSelector = selector({
  key: "RemovedIngredientsSelector",
  get: ({ get }) => get(ConfiguratorRemovedIngredientsState),
  set: ({ set }, newValue: string[]) => {
    set(ConfiguratorRemovedIngredientsState, newValue);
  },
});

export const AddonsSelector = selector<IngredientType[]>({
  key: "AddonsSelector",
  get: ({ get }) => get(ConfiguratorAddonsState),
  set: ({ set }, newValue: IngredientType[]) => {
    set(ConfiguratorAddonsState, newValue);
  },
});

export const NutritionSelector = selector({
  key: "NutritionSelector",
  get: ({ get }) => get(ConfiguratorNutritionState),
  set: ({ set }, newValue: HydratedDietaryInfo) => {
    set(ConfiguratorNutritionState, newValue);
  },
});

export const TotalPriceSelector = selector({
  key: "TotalPriceSelector",
  get: ({ get }) => get(ConfiguratorTotalPriceState),
  set: ({ set }, newValue: number) => {
    set(ConfiguratorTotalPriceState, newValue);
  },
});

export const WarningMessageSelector = selector({
  key: "WarningMessageSelector",
  get: ({ get }) => get(ConfiguratorWarningMessageState),
  set: ({ set }, newValue: string) => {
    set(ConfiguratorWarningMessageState, newValue);
  },
});
