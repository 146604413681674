import styled from "@emotion/styled";
import { Typography } from "@stadtsalat/component-lib";

export const _DynamicVoucherBoxWrapper = styled.div<{
  top: number;
  backgroundColor: string;
  textColor: string;
}>`
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  position: sticky;
  opacity: 1;
  transition: height 0.3s ease-in-out;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  top: ${({ top }) => `${top}px`};

  > p {
    border: ${({ textColor }) => textColor};
    color: ${({ textColor }) => textColor};
  }

  z-index: 10;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const _VoucherDescription = styled(Typography)`
  margin: 0;
`;

export const _CopyVoucherButton = styled.button<{
  color: string;
  backgroundColor: string;
}>`
  width: 6rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: 1px solid ${({ color }) => color};
  color: ${({ color }) => color};
  border-radius: 0.75rem;
  background-color: transparent;
  box-shadow: none;

  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease;

  &.copied {
    background-color: ${({ color }) => color};
    border-color: ${({ color }) => color};
    color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 3rem;
    transition: background-color 0.3s ease, border-radius 0.3s ease;

    & > span {
      display: flex;
      color: ${({ backgroundColor }) => backgroundColor};
    }

    & svg > path {
      stroke: ${({ backgroundColor }) => backgroundColor};
    }
  }

  &&:hover {
    color: ${({ backgroundColor }) => backgroundColor};
    background-color: ${({ color }) => color};
    border-color: ${({ color }) => color};
    transition: color 0.3s ease, background-color 0.3s ease,
      border-color 0.3s ease;
  }
`;
