import useCMS from "../useCMS";
import { CMSSeasonColors, IUseSeasonColor } from "./useSeasonColor.interfaces";

const FallbackColors: IUseSeasonColor = {
  primary: {
    background: "#dc5a00",
    text: "#ffffff",
  },
  medium: {
    background: "#ffe69b",
    text: "#dc5a00",
  },
  light: {
    background: "#fff0d2",
    text: "#dc5a00",
  },
};
const useSeasonColor = (): IUseSeasonColor => {
  const { getCMSValueByKeyGenerator } = useCMS();
  const getSeasonColors = getCMSValueByKeyGenerator<
    CMSSeasonColors,
    CMSSeasonColors["seasonColors"]
  >("seasonColors");

  const primary = getSeasonColors("primary");
  const medium = getSeasonColors("medium");
  const light = getSeasonColors("light");
  return {
    primary: primary ?? FallbackColors.primary,
    medium: medium ?? FallbackColors.medium,
    light: light ?? FallbackColors.light,
  };
};

export default useSeasonColor;
