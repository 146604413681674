import styled from "@emotion/styled";
import { LibTheme, Typography } from "@stadtsalat/component-lib";
import Image from "next/image";

import { _LinkButtonStyle } from "../../StoreInfoSection.styled";

export const _DeliveryStoreInfosWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${LibTheme.mediaBreakpoints.desktop}) {
    padding-left: 1rem;
  }
`;

export const _DeliveryAndOpeningInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  @media (min-width: ${LibTheme.mediaBreakpoints.desktop}) {
    margin-bottom: 0;
  } ;
`;

export const _DeliveryStoreHeadline = styled(Typography)`
  && {
    line-height: 1;
  }
`;
export const _DeliveryAreaAndDietInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const _DeliveryAreaButton = styled.button`
  ${_LinkButtonStyle}
`;
export const _DietaryInfoLink = styled.a`
  ${_LinkButtonStyle};
  text-decoration: none;
`;

export const _BioLabelWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  @media (min-width: ${LibTheme.mediaBreakpoints.desktop}) {
    margin-bottom: 0;
  }
`;

export const _BioLogo = styled(Image)`
  object-fit: contain;
`;

export const _BioLabel = styled(Typography)`
  && {
    line-height: 1;
  }
`;
