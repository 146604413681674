import styled from "@emotion/styled";
import { LibTheme, Typography } from "@stadtsalat/component-lib";

export const _OpeningHoursWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 1rem;
`;
export const _OpeningHoursGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const _OpeningHourHeadline = styled(Typography)`
  && {
    line-height: 1;
  }
`;
export const _OpeningHourLabel = styled(Typography)`
  && {
    line-height: 1;
    color: ${LibTheme.colors.paper_dark_600};
    font-weight: 200;
  }
`;
