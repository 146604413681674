import { LibTheme } from "@stadtsalat/component-lib";
import { FC } from "react";

import {
  BudgetIcon,
  ChefsChoiceIcon,
  FoodlabIcon,
  HighProteinIcon,
  NewIcon,
  RecipeIcon,
  RegionalIcon,
  SeasonalIcon,
  SoldOutIcon,
  SuggestedIcon,
  VeganIcon,
  VegetarianIcon,
} from "./icons";
import WarmIcon from "./icons/WarmIcon";
import {
  ProductLabelProps,
  ProductLabelVariant,
} from "./ProductLabel.interfaces";
import {
  _IconWrapper,
  _ProductLabel,
  _ProductLabelText,
} from "./ProductLabel.styled";

const VariantColorMap: {
  [key in ProductLabelVariant]: {
    background: keyof typeof LibTheme["colors"];
    icon: keyof typeof LibTheme["colors"];
  };
} = {
  "not-available": { background: "coral", icon: "coral_light" },
  suggested: { background: "green_light", icon: "green_dark" },
  seasonal: { background: "green_light", icon: "green_dark" },
  budget: { background: "green_light", icon: "green_dark" },
  "chefs-choice": { background: "green_light", icon: "green_dark" },
  foodlab: { background: "green_light", icon: "green_dark" },
  "high-protein": { background: "green_light", icon: "green_dark" },
  new: { background: "green_light", icon: "green_dark" },
  recipe: { background: "green_light", icon: "green_dark" },
  regional: { background: "green_light", icon: "green_dark" },
  vegan: { background: "green_light", icon: "green_dark" },
  vegetarian: { background: "green_light", icon: "green_dark" },
  variants: { background: "green_light", icon: "green_dark" },
  warm: { background: "green_light", icon: "green_dark" },
};

const Icon: FC<{ variant: ProductLabelVariant }> = ({ variant }) => {
  let _Icon;
  if (variant === "not-available") _Icon = <SoldOutIcon />;
  if (variant === "suggested") _Icon = <SuggestedIcon />;
  if (variant === "seasonal") _Icon = <SeasonalIcon />;
  if (variant === "budget") _Icon = <BudgetIcon />;
  if (variant === "chefs-choice") _Icon = <ChefsChoiceIcon />;
  if (variant === "foodlab") _Icon = <FoodlabIcon />;
  if (variant === "high-protein") _Icon = <HighProteinIcon />;
  if (variant === "new") _Icon = <NewIcon />;
  if (variant === "recipe") _Icon = <RecipeIcon />;
  if (variant === "regional") _Icon = <RegionalIcon />;
  if (variant === "vegan") _Icon = <VeganIcon />;
  if (variant === "vegetarian") _Icon = <VegetarianIcon />;
  if (variant === "variants") _Icon = <HighProteinIcon />;
  if (variant === "warm") _Icon = <WarmIcon />;
  return (
    <_IconWrapper color={VariantColorMap[variant].icon}>{_Icon}</_IconWrapper>
  );
};
const ProductLabel: FC<ProductLabelProps> = (props) => {
  const { variant, text } = props;

  return (
    <_ProductLabel background={VariantColorMap[variant].background}>
      {Icon ? <Icon variant={variant} /> : null}
      {text ? (
        <_ProductLabelText color={VariantColorMap[variant].icon} style={"p12"}>
          {text}
        </_ProductLabelText>
      ) : null}
    </_ProductLabel>
  );
};

export default ProductLabel;
