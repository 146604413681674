import { HeroRestriction } from "../../../../../pages/[[...city]]";

export type HeroVoucherRestriction = "newCustomer";

export type VoucherProps = {
  voucherCode: string;
  voucherDescription: string;
  backgroundColor: string;
  textColor: string;
  voucherRestrictions?: HeroVoucherRestriction[];
};

export interface HeroProps {
  heroImage: string;
  heroImageMobile: string;
  heroImageAltText: string;
  heroLogo: string;
  heroLogoMobile: string;
  heroLogoAltText: string;
  voucher?: VoucherProps;
  restrictions?: HeroRestriction[];
}

export interface HeroShiftProps extends HeroProps {
  active: boolean;
}

export enum StoreShiftType {
  LUNCH = "lunchShift",
  DINNER = "dinnerShift",
}

export interface CMSDataStoreInfoSelect {
  scrollLabel: string;
  toMenuLabel: string;
  storeDropdownLabel: string;
  deliveryDropdownLabel: string;
  defaultBanners: HeroProps;
}

export interface StoreInfoSelectDumbProps {
  isClosingByButton: boolean;
  isFirstTimeUser: boolean;
  onClickClose: () => void;
  onClickOrder: () => void;
  scrollY: number;
  scrollLabel: string;
  toMenuLabel: string;
  storeDropdownLabel: string;
  deliveryDropdownLabel: string;
}
