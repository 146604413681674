import axios from "axios";
import Position = Definitions.Position;

export const getUserLocationByIP = async (): Promise<{
  position: Position;
  city: string;
} | null> => {
  try {
    const response = await axios("https://ipapi.co/json/");
    if (response?.data) {
      return {
        position: {
          latitude: response?.data.latitude,
          longitude: response.data.longitude,
        },
        city: response.data.city,
      };
    }
    return null;
  } catch (err) {
    return null;
  }
};
