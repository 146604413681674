import { Address } from "../../hooks/useAddress";
import { PaymentMethod } from "../../views/CheckoutPageView/CheckoutPage/components/Payment";
import StorageAdapterGenerator from "./StorageAdapterGenerator";
import AddressBookEntry = Definitions.AddressBookEntry;

const DELIVERY_GROUP_KEY = "ss.activeDeliveryGroup";
export const DeliveryGroupStorageAdapter = StorageAdapterGenerator<string>(
  DELIVERY_GROUP_KEY,
  "",
  false
);

const ADDRESS_ID_KEY = "ss.addressId";
export const AddressIdStorageAdapter =
  StorageAdapterGenerator<string>(ADDRESS_ID_KEY);

const USED_ADDRESSES_KEY = "ss.used_addresses_v2";
export const UsedAddressesStorageAdapted = StorageAdapterGenerator<
  AddressBookEntry[]
>(USED_ADDRESSES_KEY, [], true);

const INVOICE_ADDRESS_KEY = "ss.invoiceAddress";

export const InvoiceAddressStorageAdapter = StorageAdapterGenerator<Address>(
  INVOICE_ADDRESS_KEY,
  undefined,
  true
);

const INVOICE_ADDRESS_ENABLED_KEY = "ss.invoice_address_enabled";
export const InvoiceAddressEnabledStorageAdapter =
  StorageAdapterGenerator<boolean>(INVOICE_ADDRESS_ENABLED_KEY, false, true);

const AUTHENTICATED_KEY = "ss.authToken";
export const AuthStorageAdapter =
  StorageAdapterGenerator<AddressBookEntry[]>(AUTHENTICATED_KEY);
const AUTHENTICATED_B2B_KEY = "ss.b2b#authToken";
export const B2BAuthStorageAdapted = StorageAdapterGenerator<
  AddressBookEntry[]
>(AUTHENTICATED_B2B_KEY);

const SELECTED_PAYMENTMETHOD_KEY = "ss.paymentMethodV2";

export const SelectedPaymentMethodStorageAdapter =
  StorageAdapterGenerator<PaymentMethod | null>(
    SELECTED_PAYMENTMETHOD_KEY,
    null,
    false
  );

const SELECTED_STORED_PAYMENTMETHOD_KEY = "ss.selectedStoredPaymentMethod";

export const SelectedStoredPaymentMethodStorageAdapter =
  StorageAdapterGenerator<string>(
    SELECTED_STORED_PAYMENTMETHOD_KEY,
    undefined,
    false
  );
const PAYMENTMETHOD_B2B_KEY = "ss.b2b#paymentMethod";
export const B2BPaymentMethodStorageAdapter =
  StorageAdapterGenerator<PaymentMethod>(SELECTED_PAYMENTMETHOD_KEY);

const PHONENUMBER_KEY = "ss.phoneNumber";

export const PhoneNumberStorageAdapter = StorageAdapterGenerator<string>(
  PHONENUMBER_KEY,
  "",
  false
);

const EMAILADDRESS_KEY = "ss.emailAddress";

export const EmailAddressStorageAdapter = StorageAdapterGenerator<string>(
  EMAILADDRESS_KEY,
  "",
  false
);

const DEFAULT_FIRSTNAME_KEY = "ss.defaultFirstName";

export const DefaultFirstNameStorageAdapter = StorageAdapterGenerator<string>(
  DEFAULT_FIRSTNAME_KEY,
  "",
  false
);
const DEFAULT_LASTNAME_KEY = "ss.defaultLastName";

export const DefaultLastNameStorageAdapter = StorageAdapterGenerator<string>(
  DEFAULT_LASTNAME_KEY,
  "",
  false
);

const VETO_NEWSLETTER_KEY = "ss.vetoNewsletter";
export const NewsletterVetoStorageAdapter = StorageAdapterGenerator<boolean>(
  VETO_NEWSLETTER_KEY,
  false,
  true
);

const SUBSCRIBE_NEWSLETTER_KEY = "ss.subscribeToNewsletter";
export const NewsletterSubscriptionStorageAdapter =
  StorageAdapterGenerator<boolean>(SUBSCRIBE_NEWSLETTER_KEY, true, true);

const RESUBSCRIBE_NEWSLETTER_KEY = "ss.resubscribeToNewsletter";
export const ResubscribeNewsletterStorageAdapter =
  StorageAdapterGenerator<boolean>(RESUBSCRIBE_NEWSLETTER_KEY, false, true);

const AGB_KEY = "ss.agbAccepted";
export const AGBStorageAdapter = StorageAdapterGenerator<boolean>(
  AGB_KEY,
  false,
  false
);
const BONUSCARD_KEY = "ss.subscribeToBonusCard";
export const BonusCardStorageAdapter = StorageAdapterGenerator<boolean>(
  BONUSCARD_KEY,
  false,
  true
);

const ORDER_COMMENT_KEY = "ss.comment";
export const OrderCommentStorageAdapter = StorageAdapterGenerator<string>(
  ORDER_COMMENT_KEY,
  "",
  false
);

const USER_FIRSTNAME_KEY = "ss.userFirstName";
export const UserFirstNameStorageAdapter = StorageAdapterGenerator<string>(
  USER_FIRSTNAME_KEY,
  "",
  false
);
const USER_LASTNAME_KEY = "ss.userLastName";
export const UserLastNameStorageAdapter = StorageAdapterGenerator<string>(
  USER_LASTNAME_KEY,
  "",
  false
);

const USER_V2_KEY = "ss.checkoutV2User";

export const V2UserStorageAdapter = StorageAdapterGenerator<boolean>(
  USER_V2_KEY,
  false,
  true
);
