import Chat from "@components/Chat";
import dynamic from "next/dynamic";
import { FC } from "react";
import StoreInfoSection from "src/views/MenuViewPage/components/StoreInfoSection";

import CategoryNavbar from "./components/CategoryNavbar/CategoryNavbar";
import DeliveryAreaModal from "./components/DeliveryAreaModal";
import DynamicVoucherBox from "./components/DynamicVoucherBox";
import Menu from "./components/Menu/Menu";
import Navbar from "./components/Navbar";
import StoreInfoSelect from "./components/StoreInfoSelect";
import { MenuViewPageProps } from "./MenuViewPage.interfaces";
import { _MenuViewPageWrapper } from "./MenuViewPage.styled";

const MenuStateWrapper = dynamic(
  () => import("./components/MenuStateWrapper/MenuStateWrapper"),
  { ssr: false }
);
const SideCart = dynamic(() => import("./components/SideCart/SideCart"), {
  ssr: false,
});
const ProductUnavailableModal = dynamic(
  () => import("./components/ProductUnavailableModal"),
  {
    ssr: false,
  }
);

const Footer = dynamic(() => import("@components/Footer"), {
  ssr: false,
});

const Configurator = dynamic(() => import("./components/Configurator"), {
  ssr: false,
});

const UpsellModal = dynamic(() => import("./components/UpsellModal"), {
  ssr: false,
});

const AlertBox = dynamic(() => import("../../components/StoreAlertBox"), {
  ssr: false,
});

const BackgroundBlurPortal = dynamic(
  () => import("./components/BackgroundBlurPortal"),
  {
    ssr: false,
  }
);

const MenuViewPage: FC<MenuViewPageProps> = (props) => {
  const {
    preFetchedAvailableDeliveryGroups,
    activeDeliveryGroupId,
    pathDeliveryGroupId,
  } = props;

  return (
    <_MenuViewPageWrapper>
      <AlertBox />
      <DynamicVoucherBox />
      <MenuStateWrapper
        preFetchedAvailableDeliveryGroups={preFetchedAvailableDeliveryGroups}
        activeDeliveryGroupId={activeDeliveryGroupId}
        pathDeliveryGroupId={pathDeliveryGroupId}
      />
      <Navbar />
      <SideCart />
      <ProductUnavailableModal />
      <StoreInfoSelect />
      <CategoryNavbar />
      <Configurator />
      <UpsellModal />
      <Menu preFetchedProducts={props.preFetchedProducts} />
      <DeliveryAreaModal />
      <StoreInfoSection />
      <Chat />
      <Footer />
      <BackgroundBlurPortal />
    </_MenuViewPageWrapper>
  );
};

export default MenuViewPage;
