import styled from "@emotion/styled";
import { LibTheme, Typography } from "@stadtsalat/component-lib";

export const _ProductLabel = styled.div<{
  background: keyof typeof LibTheme["colors"];
}>`
  flex-direction: row;
  display: flex;
  gap: 0.3rem;
  padding: 0.25rem 0.4rem 0.25rem 0.45rem;
  background-color: ${({ background }) => LibTheme.colors[background]};
  color: ${LibTheme.colors.white};
  border-radius: 0.25rem;
  align-items: center;
  width: fit-content;
`;

export const _ProductLabelText = styled(Typography)<{
  color: keyof typeof LibTheme["colors"];
}>`
  && {
    line-height: 1;
    color: ${({ color }) => LibTheme.colors[color]};
    letter-spacing: -0.1px;
  }
`;

export const _IconWrapper = styled.div<{
  color: keyof typeof LibTheme["colors"];
}>`
  width: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;

  && > svg {
    path {
      fill: ${({ color }) => LibTheme.colors[color]};
      stroke: ${({ color }) => LibTheme.colors[color]};
    }
  }
`;
