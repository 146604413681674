import * as React from "react";

const FoodlabIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      fill="none"
      fillRule="nonzero"
      d="M5.839 138.699a10.462 10.462 0 0 1-8.955-5.206 10.346 10.346 0 0 1 0-10.413l33.739-57.106a20.687 20.687 0 0 0 2.915-10.622v-197.934c0-5.748 4.666-10.413 10.455-10.413h62.479c5.748 0 10.414 4.665 10.414 10.413v57.314c0 5.749-4.666 10.414-10.414 10.414h-27.49c-8.623 0-15.62 6.997-15.62 15.619 0 8.623 6.997 15.62 15.62 15.62h27.49c5.748 0 10.414 4.665 10.414 10.413v20.868c0 5.749-4.666 10.414-10.414 10.414h-27.49c-8.623 0-15.62 6.997-15.62 15.619 0 8.623 6.997 15.62 15.62 15.62h27.49c5.748 0 10.414 4.665 10.414 10.413v15.62c0 3.749.999 7.414 2.915 10.622l33.531 57.106c2.041 3.165 2.041 7.205 0 10.413-1.916 3.207-5.415 5.165-9.164 5.206H5.839Zm246.46 69.602L161.496 55.352a20.684 20.684 0 0 1-2.916-10.621v-187.313a10.39 10.39 0 0 1 10.413-10.413h31.24c11.496 0 20.826-9.33 20.826-20.826s-9.33-20.827-20.826-20.827h-250c-11.538 0-20.826 9.331-20.826 20.827 0 11.496 9.288 20.826 20.826 20.826h31.239c5.749 0 10.414 4.665 10.414 10.413V44.939c0 3.749-1 7.414-2.916 10.621l-90.012 151.492c-19.785 28.282-12.912 67.227 15.37 87.012 10.538 7.373 23.076 11.33 35.905 11.288h250c34.53-.083 62.396-28.157 62.312-62.687-.041-12.205-3.665-24.201-10.455-34.364h.209Z"
      transform="translate(174.897 194.648)"
    />
  </svg>
);
export default FoodlabIcon;
