import styled from "@emotion/styled";
import { LibTheme, Typography } from "@stadtsalat/component-lib";

export const _ProductCardButton = styled.button`
  align-self: flex-start;
  background-color: transparent;
  padding: 0.5rem 2rem;
  border-radius: 18px;
  border: solid 1px #695f50;
  cursor: pointer;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;

  & > p {
    line-height: 1.25;
    letter-spacing: -0.1px;
    font-size: 10px !important;
  }

  & > span {
    font-size: 10px;
  }

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    margin-top: auto;
  }
`;
export const _ProductCardOuterWrapper = styled.div<{ isAvailable: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  pointer-events: ${({ isAvailable }) => (isAvailable ? "all" : "none")};
`;

export const _ProductCardWrapper = styled.div<{
  isAvailable: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;
  animation: fadeIn 1s ease;
  pointer-events: ${({ isAvailable }) => (isAvailable ? "auto" : "none")};
  opacity: ${({ isAvailable }) => (isAvailable ? 1 : 0.5)};
  flex-grow: 1;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .image {
    img {
      transform: scale(1);
    }
  }

  &:hover {
    .image {
      img {
        transform: scale(1.03);
      }
    }
  }
`;

export const _ProductCardImage = styled.div<{ isLoaded: boolean }>`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  flex-grow: 0;
  background-color: #f8f0e5;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
    object-fit: contain; // Add this line
    transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
  }
`;

export const _ImageWrapper = styled.div`
  position: absolute; // Add this line
  top: 0; // Add this line
  left: 0; // Add this line
  width: 100%; // Add this line
  height: 100%; // Add this line
  padding: 0.75rem;
`;

export const _ProductCardTitle = styled(Typography)`
  align-self: flex-start;
  text-transform: uppercase;
  line-height: 1.17;
  letter-spacing: -0.3px;
  color: #28190a !important;
  margin: 0.75rem 0 0.5rem 0 !important;
  font-size: 1.25rem !important;
`;

export const ProductCardDescription = styled(Typography)`
  && {
    color: #28190a;
    line-height: 1.43;
    letter-spacing: normal;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    min-width: 100%;
    @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
      line-height: 1.5;
      letter-spacing: -0.1px;
      font-size: 0.75rem;
    }
  }
`;

export const ProductCardVariants = styled(Typography)`
  && {
    color: #28190a;
    line-height: 1.43;
    letter-spacing: normal;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    min-width: 100%;
    @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
      line-height: 1.5;
      letter-spacing: -0.1px;
      font-size: 0.75rem;
    }
  }
`;

export const _ProductCardVariant = styled.span<{ isAvailable: boolean }>`
  color: ${({ isAvailable }) =>
    isAvailable ? "" : LibTheme.colors.paper_dark_400};
  text-decoration: ${({ isAvailable }) => (isAvailable ? "" : "line-through")};
  &:hover {
    text-decoration: ${({ isAvailable }) =>
      isAvailable ? "underline" : "line-through"};
  }
`;

export const _ProductTagWrapper = styled.div`
  padding: 0.75rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const _LoadingProduct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;

  .loading-button {
    border-radius: 3rem;
    overflow: hidden;
  }
`;
