import * as React from "react";

const RegionalIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      fill="none"
      fillRule="nonzero"
      d="M30.352 29.612c-1.407 4.445-5.926 7.519-11.037 7.519H-107.83c-4.852 0-9.185-2.741-10.815-6.889l-40.333-104.146c-1.185-3.222-.666-6.778 1.408-9.593 2.222-2.703 5.703-4.333 9.444-4.37H24.389c4.741-.37 8.778-3.333 10.111-7.518l17.037-51.666c2.741-8.259 12.407-12.963 21.555-10.518 9.148 2.481 14.37 11.222 11.63 19.481l-54.37 167.7ZM7.316 99.611c-12.704 0-23.037-9.333-23.037-20.814 0-11.519 10.333-20.852 23.037-20.852 12.74 0 23.036 9.333 23.036 20.852 0 11.481-10.296 20.814-23.036 20.814Zm-103.628 0c-12.741 0-23.037-9.333-23.037-20.814 0-11.519 10.296-20.852 23.037-20.852 12.703 0 23.037 9.333 23.037 20.852 0 11.481-10.334 20.814-23.037 20.814Zm61.74-343.734c-120.813.111-218.7 88.665-218.811 197.922 0 136.442 149.701 260.403 195.774 294.772 12.778 9.741 31.444 9.741 44.221 0C34.5 214.202 184.201 90.241 184.201-46.201c-.111-109.257-97.998-197.811-218.773-197.922Z"
      transform="translate(284.59 244.123)"
    />
  </svg>
);
export default RegionalIcon;
