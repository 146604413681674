import * as React from "react";

const VeganIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      fill="none"
      fillRule="nonzero"
      d="M164.465-110.884c-6.246 62.374-37.203 133.658-91.998 211.786-57.597 82.17-84.88 130.213-93.102 145.416C-16.96 214.351-3.411 112.477 28.327 24.52 65.164-77.4 138.882-166.827 171.952-203.617c-1.516 23.332-4.134 59.663-7.487 92.733Zm11.713-119.281c-5.328 2.572-130.994 64.257-182.39 170.54-15.433 31.922-38.49 100.542-52.637 186.156-.689-15.019-2.159-34.447-5.282-51.717-6.292-35.229-3.674-33.392-11.85-59.343l-65.818-178.807c-4.18-11.621-16.673-20.347-29.029-20.347h-77.071c-7.119 0-13.32 2.985-16.994 8.221-3.629 5.236-4.364 12.08-1.929 18.832l146.977 398.861c4.226 11.62 16.719 20.393 29.028 20.393h77.072c.413 0 .827-.046 1.24-.092.046 0 .138-.046.23-.046a6.502 6.502 0 0 0 1.01-.23c.092 0 .138 0 .184-.046a10.946 10.946 0 0 0 2.25-.918c.092-.046.184-.138.23-.184.322-.184.597-.367.827-.551.092-.046.138-.092.184-.138.321-.23.597-.505.872-.781.046-.092.138-.184.23-.275.184-.23.413-.46.597-.735.046-.092.092-.138.138-.23.275-.321.459-.689.643-1.056.046 0 .046-.046.046-.046.275-.505 25.491-49.559 92.78-145.554 55.53-79.276 86.992-152.168 93.514-216.563 5.374-53.417 8.865-115.377 8.911-116.02l.918-16.535-14.881 7.211Z"
      transform="translate(288.59 237.376)"
    />
  </svg>
);
export default VeganIcon;
