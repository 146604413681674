import * as React from "react";

const VegetarianIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      fill="none"
      fillRule="nonzero"
      d="M228.726-66.779c6.848 0 12.431 5.582 12.431 12.43 0 56.263-20.107 110.869-56.612 153.699-36.506 42.829-87.23 71.266-142.795 80.164-6.76 1.09-11.689 6.847-11.776 13.695v36.156c0 10.511-8.505 19.016-19.016 19.016-10.468 0-19.016-8.505-19.016-19.016v-59.36c0-63.241 24.642-122.732 69.347-167.437 44.749-44.705 104.196-69.347 167.437-69.347Zm-448.272 0c79.248 0 152.826 39.34 196.877 105.199 1.832 3.62 1.744 7.85-.306 11.427-15.963 34.586-24.86 71.484-26.474 109.56-.131 3.969-2.05 7.589-5.277 9.988-2.181 1.875-4.972 2.878-7.851 2.878-1.003 0-2.05-.13-3.009-.392-48.674-15.047-90.37-44.705-120.595-85.703-30.269-40.998-46.232-89.585-46.188-140.527 0-6.848 5.539-12.43 12.386-12.43h.437ZM4.241-251.619c3.315 0 6.455 1.265 8.81 3.577C58.629-202.683 85.539-142.32 88.854-78.075A12.344 12.344 0 0 1 83.14-66.91C54.573-49.115 29.581-26.654 8.733-.136c-.916 1.439-2.486 2.268-4.187 2.268-2.18 0-3.838-.829-4.841-2.311-20.586-26.518-45.49-48.893-74.058-66.426-3.794-2.529-5.975-6.935-5.757-11.47 3.228-64.201 30.051-124.564 75.541-169.967 2.355-2.312 5.495-3.577 8.81-3.577Z"
      transform="translate(245.606 251.62)"
    />
  </svg>
);
export default VegetarianIcon;
