import { OnlineDeliveryType } from "../../../../../../util/storage/order.storage";
import DeliveryGroupInfo = Definitions.DeliveryGroupInfo;

export interface CMSDataStoreInfoForm {}

export interface StoreInfoFormProps {
  onClickOrder: () => void;
  isFirstTimeUser: boolean;
  toMenuLabel: string;
  storeDropdownLabel: string;
  deliveryDropdownLabel: string;
}

export interface StoreInfoFormDumbProps {
  activeDeliveryGroup: DeliveryGroupInfo | null;
  availableDeliveryGroups: DeliveryGroupInfo[];
  deliveryDropdownLabel: string;
  deliveryType: OnlineDeliveryType;
  isFirstTimeUser: boolean;
  onChangeDeliveryType: (deliveryType: OnlineDeliveryType) => void;
  onChangeStore: (store: string) => void;
  onClickOrder: () => void;
  orderLabel: string;
  storeDropdownLabel: string;
  storeInfoComponent: React.ReactNode;
  dataTestid?: string;
  seasonalColor?: string;
  seasonalColorText?: string;
}

export const DeliveryTypeToLabelMap = {
  [OnlineDeliveryType.ONLINE_TAKEOUT]: "Abholung",
  [OnlineDeliveryType.ONLINE_EATIN]: "Vor Ort Essen",
  [OnlineDeliveryType.ONLINE_DELIVERY]: "Lieferung",
};
