import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { LibTheme } from "@stadtsalat/component-lib";

import { _MenuWrapper } from "../Menu/Menu.styled";

export const _StoreInfoSectionWrapper = styled(_MenuWrapper)``;

export const _StoreInfosInnerWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2.5rem;
  @media (min-width: ${LibTheme.mediaBreakpoints.desktop}) {
    grid-template-columns: 66% max-content;
  }
`;

export const _LinkButtonStyle = css`
  width: fit-content;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
  color: ${LibTheme.colors.paper_dark_900};
  font-size: 0.75rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  padding: 0.35rem 0;
  border-radius: 12rem;
  transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out;

  &:hover {
    color: ${LibTheme.colors.paper_dark_600};
    transition: color 1s ease-in-out, text-decoration 0.5s ease;
    text-decoration: underline;
  }
`;
