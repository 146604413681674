import styled from "@emotion/styled";
import { LibTheme, Typography } from "@stadtsalat/component-lib";

export const _MarginWrapper = styled.div<{ isFirstTimeUser: boolean }>`
  position: relative;

  margin: 0;

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    transition: margin 0.75s ease-in-out;

    margin: ${({ isFirstTimeUser }) =>
      isFirstTimeUser ? "0" : "0 1rem 1rem 1rem"};
  }
`;

export const _InfoFormWrapper = styled.div<{
  isFirstTimeUser: boolean;
}>`
  position: absolute;
  z-index: ${({ isFirstTimeUser }) => (isFirstTimeUser ? 10 : 8)};
  left: 0;
  right: 0;

  transition: height 0.5s ease-in-out, max-width 0.75s ease-in-out,
    margin 0.75s ease-in-out, transform 0.75s ease-in-out,
    border-radius 0.75s ease-in-out, box-shadow 0.75s ease-in-out;

  margin: ${({ isFirstTimeUser }) =>
    isFirstTimeUser
      ? "0"
      : "calc(var(--hero-image-overlap-mobile)* -1) 1rem 1rem 1rem"};
  box-shadow: ${({ isFirstTimeUser }) =>
    isFirstTimeUser
      ? "0 -2px 5px 0 rgba(0, 0, 0, 0.2)"
      : "0 2px 8px 0 rgba(0, 0, 0, 0.15)"};
  border-radius: ${({ isFirstTimeUser }) =>
    isFirstTimeUser ? "0 0 16px 16px" : "16px"};
  max-width: ${({ isFirstTimeUser }) => (isFirstTimeUser ? "75rem" : "60rem")};
  padding: 1.5rem 2rem 2rem 2rem;

  background-color: #fffaf5;

  &:hover {
    .chevron-wrapper {
      animation-duration: 0.5s !important;
    }
  }

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    margin: ${({ isFirstTimeUser }) =>
      isFirstTimeUser
        ? "0"
        : "calc(var(--hero-image-overlap-tablet) * -1) auto 1rem auto"};
  }

  @media (min-width: ${LibTheme.mediaBreakpoints.desktop}) {
    transform: ${({ isFirstTimeUser }) =>
      isFirstTimeUser ? "scale(1.2)" : "scale(1)"};

    margin: ${({ isFirstTimeUser }) =>
      isFirstTimeUser
        ? "0rem auto 0 auto"
        : "calc(var(--hero-image-overlap-desktop)* -1) auto 0 auto"};
  }
`;

export const _InfoFormDropdownWrapper = styled.div<{
  isButtonVisible: boolean;
}>`
  position: relative;
  z-index: 12;
  grid-template-columns: 1fr;
  grid-template-rows: ${({ isButtonVisible: showButton }) =>
    showButton ? "4rem 4rem 3rem" : "4rem 4rem 0rem"};
  flex-direction: column;
  align-items: center;
  justify-content: center;

  display: grid;

  transition: gap 0.75s ease-in-out, grid-template-columns 0.75s ease-in-out,
    grid-template-rows 0.75s ease-in-out;

  background-color: #fffaf5;

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    grid-template-rows: 1fr;
    grid-template-columns: ${({ isButtonVisible: showButton }) =>
      showButton ? "1fr 1fr 1fr" : "1fr 1fr 0fr"};
  }
`;

export const _InfoFormDropdown = styled.div`
  z-index: 12;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  display: flex;

  transition: background-color 0.3s ease-in-out;

  padding-left: 0.75rem;

  background-color: #f8f0e5;
  cursor: pointer;

  line-height: 1.43;

  user-select: none;

  p {
    padding: 0.5rem 0;

    pointer-events: none;
  }
`;

export const _LiveStatusBubble = styled.span<{ live: boolean }>`
  position: relative;

  display: inline-block;

  margin: 0 0.25rem 0 0.5rem;
  border-radius: 100%;
  width: 0.5rem;
  height: 0.5rem;

  background-color: ${LibTheme.colors.success};

  &:after {
    content: "";

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0.5;

    animation: ${({ live }) => (live ? "pulse" : "none")} 2s infinite;

    border-radius: 100%;

    background-color: ${LibTheme.colors.success};
  }

  @keyframes pulse {
    0% {
      opacity: 1;
      transform: scale(0.5);
    }
    100% {
      opacity: 0;
      transform: scale(2);
    }
  }
`;

export const _StoreDeliveryTime = styled(Typography)`
  && {
    display: inline-block;
    opacity: 1;

    transition: opacity 0.5s ease-in;

    padding: 0;
  }
`;

export const _OrderButton = styled.div<{
  showButton: boolean;
  color?: string;
  colorText?: string;
}>`
  align-self: flex-end;
  align-items: center;
  justify-content: center;

  display: flex;
  opacity: ${({ showButton }) => (showButton ? "1" : "0")};

  overflow: hidden;

  transition: opacity 0.5s ease-in-out, filter 0.25s ease-in-out;

  border-radius: 10rem;
  height: 3rem;
  padding: 0.75rem 0rem;

  background-color: ${({ color }) => color || "#372819"};
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }

  p {
    font-weight: 600;
    letter-spacing: -0.1px;
    color: ${({ colorText }) => colorText || "#fffaf5"};
    overflow: hidden;
    height: ${({ showButton }) => (showButton ? "100%" : "0")};
    transition: height 0.5s ease-in-out;
  }
`;
