import styled from "@emotion/styled";
import { LibTheme } from "@stadtsalat/component-lib";

export const _NavbarWrapper = styled.div<{
  active: boolean;
  alertBoxHeight: number;
}>`
  opacity: ${({ active }) => (active ? 1 : 0)};
  transform: ${({ active }) =>
    active ? "translateY(0)" : "translateY(-20px)"};
  pointer-events: ${({ active }) => (active ? "all" : "none")};
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: fixed;
  right: 0rem;
  z-index: 13;
  margin-top: -52px;
  pointer-events: none;
  bottom: 1rem;
  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    position: sticky;
    top: ${({ alertBoxHeight }) => 16 + alertBoxHeight}px;
    bottom: unset;
    transition: top 0.35s ease-in;
  }
`;

export const _LayoutWrapper = styled.div`
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding-right: 1.5rem;
  z-index: 11;

  svg > path {
    transition: stroke 0.3s ease, fill 0.3s ease;
  }

  svg.active {
    path {
      stroke: ${LibTheme.colors.primary} !important;
    }
  }

  svg.active-fill {
    path {
      stroke: ${LibTheme.colors.primary} !important;
      fill: ${LibTheme.colors.primary} !important;
    }
  }

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    position: relative;
  }
`;

export const _NavbarButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  background-color: #f8f0e5;
  padding: 0.5rem 1.25rem;
  border: 1px solid #e7dccb;
  border-radius: 3rem;
  pointer-events: all;
  position: relative;
  z-index: 13;

  svg {
    width: 20px;
    height: 20px;
  }

  > .icon {
    transition: scale 0.3s ease;

    &:hover {
      cursor: pointer;
      scale: 1.05;
    }
  }

  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    box-shadow: none;
  }
`;

export const _NavbarBurgerMenu = styled.div`
  background-color: #f8f0e5;
  padding: 0.5rem 0.75rem;
  border: 1px solid #e7dccb;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  z-index: 13;

  &:hover {
    cursor: pointer;

    svg {
      scale: 1.1;
    }
  }

  svg {
    transition: scale 0.3s ease;
    width: 18px;
    height: 18px;
  }

  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    box-shadow: none;
  }
`;
export const _SeperatorWrapper = styled.div`
  hr {
    margin-bottom: 1rem;
  }
`;

export const _SellingPointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;

  > div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;

    svg > path {
      fill: #6eb400;
    }
  }
`;

export const _SecondaryButton = styled.div<{ color: string }>`
  margin-top: 1rem;
  width: 100%;
  border: 1px solid
    ${({ color }) => (color == "primary" ? LibTheme.colors.primary : "#28190a")};
  align-self: flex-end;
  border-radius: 10rem;
  padding: 0.4rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    color: ${({ color }) =>
      color == "primary" ? LibTheme.colors.primary : "#28190a"};
    font-weight: 600;
    letter-spacing: -0.1px;
  }
`;
export const _OnlyDesktopWrapper = styled.div`
  display: none;
  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    display: block;
  }
`;

export const _UserIconLoading = styled.div`
  width: 1.25rem;
  height: 1.25rem;
`;

export const _CartValueLoading = styled.div`
  height: 1.25rem;
  width: 3rem;
`;
