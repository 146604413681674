"use client";
import Loading from "@components/Loading";
import { Typography } from "@stadtsalat/component-lib";
import { getImageUrlByProduct } from "@util/product.util";
import Image from "next/image";
import { FC, useState } from "react";

import { _LoadingProduct } from "../Menu/Menu.styled";
import ProductLabel from "../ProductLabel";
import { ProductLabelVariant } from "../ProductLabel/ProductLabel.interfaces";
import {
  CMSDataProductCard,
  IProductCardProps,
} from "./ProductCard.interfaces";
import {
  _ImageWrapper,
  _ProductCardButton,
  _ProductCardImage,
  _ProductCardOuterWrapper,
  _ProductCardTitle,
  _ProductCardVariant,
  _ProductCardWrapper,
  _ProductTagWrapper,
  ProductCardDescription,
  ProductCardVariants,
} from "./ProductCard.styled";

export const TestIds = {
  loadingCard: "loading-card",
  productImage: "product-image",
};
const getProductLabelTagMap: (cmsData: CMSDataProductCard) => {
  [index: string]: {
    variant: ProductLabelVariant;
    text: string;
  };
} = (cmsData) => ({
  "label.warm": {
    variant: "warm",
    text: cmsData.labels.warm,
  },
  "label.budget-deal": {
    variant: "budget",
    text: cmsData.labels.budget,
  },
  "label.chefs-choice": {
    variant: "chefs-choice",
    text: cmsData.labels.chefsChoice,
  },
  "label.food-lab": {
    variant: "foodlab",
    text: cmsData.labels.foodlab,
  },
  "label.high-protein": {
    variant: "high-protein",
    text: cmsData.labels.highProtein,
  },
  "label.new": {
    variant: "new",
    text: cmsData.labels.new,
  },
  "label.new-recipe": {
    variant: "recipe",
    text: cmsData.labels.recipe,
  },
  "label.regional": {
    variant: "regional",
    text: cmsData.labels.regional,
  },
  "label.vegan": {
    variant: "vegan",
    text: cmsData.labels.vegan,
  },
  "label.vegetarian": {
    variant: "vegetarian",
    text: cmsData.labels.vegetarian,
  },
  "label.seasonal": {
    variant: "seasonal",
    text: cmsData.labels.seasonal,
  },
  "label.variant": {
    variant: "variants",
    text: cmsData.labels.variants,
  },
});
const ProductCard: FC<IProductCardProps> = ({
  name,
  image,
  description,
  price,
  kcal,
  onClick,
  cmsData,
  priority = false,
  isAvailable = true,
  isProductGroup = false,
  variants = [],
  tags = [],
  dataTestId,
  dataCy,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hoveredImageURL, setHoveredImageURL] = useState<string | null>(null);
  return (
    <_ProductCardOuterWrapper
      onClick={() => onClick()}
      isAvailable={isAvailable}
      data-testid={dataTestId}
      data-cy={dataCy}
      id={`product-card-${name.toLowerCase().replaceAll(" ", "-")}`}
    >
      <_ProductCardWrapper isAvailable={isAvailable}>
        <_ProductCardImage className="image" isLoaded={isLoaded}>
          <_ImageWrapper>
            {!!image ? (
              <Image
                onLoad={() => setIsLoaded(true)}
                src={hoveredImageURL || image || ""}
                alt={`Produktbild von ${name}`}
                width={250}
                height={250}
                quality={80}
                style={{ width: "100%", height: "100%" }}
                priority={priority}
                sizes={
                  "(max-width: 767px) 100vw, (max-width: 837px) 350px, (max-width: 1111px) 240px"
                }
                data-testid={TestIds.productImage}
                id={`product-card-image-${name
                  .toLowerCase()
                  .replaceAll(" ", "-")}`}
              />
            ) : null}
          </_ImageWrapper>
        </_ProductCardImage>
        <_ProductCardTitle style={"m24"} variant={"semibold"}>
          {name}
        </_ProductCardTitle>
        <ProductCardVariants style={"m14"} variant={"semibold"}>
          {variants?.length
            ? variants.map((v, index) => (
                <span key={`${name}-${v.shortName}-${index}`}>
                  <_ProductCardVariant
                    onMouseEnter={() =>
                      v.available &&
                      setHoveredImageURL(
                        getImageUrlByProduct(v as any, 500, true)
                      )
                    }
                    onClick={(e) => {
                      if (v.available) {
                        e.stopPropagation();
                        onClick(v.id);
                      }
                    }}
                    isAvailable={v.available}
                  >
                    {v.shortName}
                  </_ProductCardVariant>
                  {index < variants.length - 1 ? " | " : ""}
                </span>
              ))
            : null}
        </ProductCardVariants>
        <ProductCardDescription style={"m14"}>
          {description}
        </ProductCardDescription>
        {price ? (
          <_ProductCardButton className="button">
            <Typography style={"p10"}>
              {isProductGroup ? `ab ${price}` : price}
            </Typography>
            {!isProductGroup ? (
              <Typography as={"span"} style={"p10"}>
                · {`${!!kcal ? kcal : 0} KCAL`}
              </Typography>
            ) : null}
          </_ProductCardButton>
        ) : null}
      </_ProductCardWrapper>
      <_ProductTagWrapper>
        {!isAvailable ? (
          <ProductLabel
            variant={"not-available"}
            text={cmsData.labels.soldOut}
          />
        ) : null}
        {isAvailable
          ? tags
              .filter((tag) =>
                tag == "label.variant"
                  ? variants.filter((v) => v.available).length > 1
                  : true
              )
              .map((tag) => {
                const tagMap = getProductLabelTagMap(cmsData)[tag];
                return !!tagMap ? (
                  <ProductLabel
                    key={`${name}-${tag}`}
                    variant={tagMap?.variant}
                    text={tagMap?.text}
                  />
                ) : null;
              })
          : null}
      </_ProductTagWrapper>
    </_ProductCardOuterWrapper>
  );
};

export const LoadingProductCard = () => {
  return (
    <_LoadingProduct data-testid={TestIds.loadingCard}>
      <Loading
        width={"100%"}
        height={"282px"}
        baseColor="#f8f0e5"
        className="loading-image"
      />
      <Loading width={"200px"} height={"30px"} baseColor="#f8f0e5" />
      <Loading width={"250px"} height={"60px"} baseColor="#f8f0e5" />
      <Loading
        width={"200px"}
        height={"35px"}
        baseColor="#f8f0e5"
        className="loading-button"
      />
    </_LoadingProduct>
  );
};
export default ProductCard;
