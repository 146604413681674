import Script from "next/script";
import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import { ChatIsOnline, ChatPluginLoaded } from "../../state/Chat/Chat.state";

const Chat: FC = () => {
  const [_chatIsOnline, setChatIsOnline] = useRecoilState(ChatIsOnline);
  const [chatPluginLoaded, setChatPluginLoaded] =
    useRecoilState(ChatPluginLoaded);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [chatStateInterval, setChatStateInterval] = useState<
    null | number | NodeJS.Timeout
  >();

  useEffect(() => {
    if (!scriptLoaded) {
      setChatPluginLoaded(false);
      const interval = setInterval(() => {
        if (window.$zopim && window.chatIsOnline === undefined) {
          window.$zopim.livechat.setOnStatus((status: string) => {
            if (status === "online") {
              window.chatIsOnline = true;
              setChatIsOnline(true);
            } else {
              window.chatIsOnline = false;
              setChatIsOnline(false);
            }
            setScriptLoaded(true);
          });
        }
      }, 2000);
      setChatStateInterval(interval as NodeJS.Timeout);
    } else if (!chatPluginLoaded) {
      setChatPluginLoaded(true);
    }
  }, [scriptLoaded]);

  useEffect(() => {
    return () => {
      clearInterval(chatStateInterval as NodeJS.Timeout);
    };
  }, [chatStateInterval]);
  return (
    <>
      <Script>
        {`
            window.zESettings = {
                webWidget: {
                    color: { theme: "#ff506e" },
                    offset: { horizontal: "0px", vertical: "40px" },
                    mobile: {
                        labelVisible: true,
                    },
                },
            };
            `}
      </Script>
    </>
  );
};

export default Chat;
