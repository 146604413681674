import { atom, selector } from "recoil";

import { Address } from "../../hooks/useAddress";
import {
  AddressIdStorageAdapter,
  AGBStorageAdapter,
  AuthStorageAdapter,
  BonusCardStorageAdapter,
  DeliveryGroupStorageAdapter,
  EmailAddressStorageAdapter,
  InvoiceAddressEnabledStorageAdapter,
  InvoiceAddressStorageAdapter,
  NewsletterSubscriptionStorageAdapter,
  NewsletterVetoStorageAdapter,
  OrderCommentStorageAdapter,
  PhoneNumberStorageAdapter,
  ResubscribeNewsletterStorageAdapter,
  SelectedPaymentMethodStorageAdapter,
  SelectedStoredPaymentMethodStorageAdapter,
  UsedAddressesStorageAdapted,
  UserFirstNameStorageAdapter,
  UserLastNameStorageAdapter,
  V2UserStorageAdapter,
} from "../../util/storage/user.storage";
import { PaymentMethod } from "../../views/CheckoutPageView/CheckoutPage/components/Payment";
import { localStorageEffect } from "../effect";
import AddressBookInfo = Definitions.AddressBookInfo;
import DeliveryGroupInfo = Definitions.DeliveryGroupInfo;

const DeliveryGroupDefault = "hamburg";
const _InternalDeliveryGroupIdState = atom<string>({
  key: "_DeliveryGroupIdStateInternal",
  default: DeliveryGroupDefault,
  effects: [
    localStorageEffect({
      get: DeliveryGroupStorageAdapter.get,
      set: DeliveryGroupStorageAdapter.set,
      delete: DeliveryGroupStorageAdapter.delete,
    }),
  ],
});
export const DeliveryGroupIdState = selector<string>({
  key: "_DeliveryGroupIdStateSelector",
  get: ({ get }) => {
    const storedDeliveryGroupId = get(_InternalDeliveryGroupIdState);
    if (
      storedDeliveryGroupId &&
      storedDeliveryGroupId !== "" &&
      storedDeliveryGroupId.match("^([a-z]+)")
    ) {
      return storedDeliveryGroupId;
    }
    return DeliveryGroupDefault;
  },
  set: ({ set }, newDeliveryGroupId) => {
    set(_InternalDeliveryGroupIdState, newDeliveryGroupId);
  },
});

export const DeliveryGroupState = atom<DeliveryGroupInfo | null>({
  key: "_DeliveryGroupState",
  default: null,
});
export const AvailableDeliveryGroupsState = atom<DeliveryGroupInfo[]>({
  key: "_AvailableDeliveryGroupsState",
  default: [],
});
export const SelectedAddressState = atom<Address | null>({
  key: "_SelectedAddressState",
  default: null,
});
export const SelectedAddressIdState = atom<string | null>({
  key: "_SelectedAddressIdState",
  default: "",
  effects: [
    localStorageEffect({
      get: AddressIdStorageAdapter.get,
      set: (addressId) =>
        addressId !== null && addressId !== ""
          ? AddressIdStorageAdapter.set(addressId)
          : AddressIdStorageAdapter.delete(),
      delete: AddressIdStorageAdapter.delete,
    }),
  ],
});
export const UserAddressBookState = atom<AddressBookInfo | null>({
  key: "_UserAddressBookState",
  default: null,
});
export const AddressesState = atom<Address[]>({
  key: "_AddressesState",
  default: [],
});
export const UsedAddressState = atom<Address[]>({
  key: "_UsedAddressState",
  default: [],
  effects: [
    localStorageEffect({
      get: UsedAddressesStorageAdapted.get,
      set: (uA = []) =>
        uA?.length > 0
          ? UsedAddressesStorageAdapted.set(uA)
          : UsedAddressesStorageAdapted.delete(),
      delete: UsedAddressesStorageAdapted.delete,
    }),
  ],
});

export const InvoiceAddressState = atom<Address | undefined>({
  key: "_InvoiceAddressState",
  default: undefined,
  effects: [
    localStorageEffect({
      get: InvoiceAddressStorageAdapter.get,
      set: InvoiceAddressStorageAdapter.set,
      delete: InvoiceAddressStorageAdapter.delete,
    }),
  ],
});
export const InvoiceProjectcodeState = atom<string>({
  key: "_InvoiceProjectcodeState",
  default: "",
});

export const InvoiceAddressEnabledState = atom<boolean>({
  key: "_InvoiceAddressEnabledState",
  default: false,
  effects: [
    localStorageEffect({
      get: InvoiceAddressEnabledStorageAdapter.get,
      set: InvoiceAddressEnabledStorageAdapter.set,
      delete: InvoiceAddressEnabledStorageAdapter.delete,
    }),
  ],
});

export const AuthenticatedState = atom<boolean>({
  key: "_AuthState",
  default: false,
  effects: [
    localStorageEffect({
      get: AuthStorageAdapter.get,
      set: AuthStorageAdapter.set,
      delete: AuthStorageAdapter.delete,
    }),
  ],
});

export const PhoneNumberState = atom<string>({
  key: "_PhoneNumberState",
  default: "",
  effects: [
    localStorageEffect({
      get: PhoneNumberStorageAdapter.get,
      set: PhoneNumberStorageAdapter.set,
      delete: PhoneNumberStorageAdapter.delete,
    }),
  ],
});
export const InputGroupValidState = atom<boolean>({
  key: "_InputGroupValidState",
  default: false,
});
export const EmailState = atom<string>({
  key: "_EmailState",
  default: "",
  effects: [
    localStorageEffect({
      get: EmailAddressStorageAdapter.get,
      set: EmailAddressStorageAdapter.set,
      delete: EmailAddressStorageAdapter.delete,
    }),
  ],
});

export const SelectedPaymentMethodState = atom<PaymentMethod | null>({
  key: "_DefaultPaymentMethodState",
  default: null,
  effects: [
    localStorageEffect({
      get: SelectedPaymentMethodStorageAdapter.get,
      set: SelectedPaymentMethodStorageAdapter.set,
      delete: SelectedPaymentMethodStorageAdapter.delete,
    }),
  ],
});

export const SelectedStoredPaymentMethodState = atom<string | null>({
  key: "_SelectedStoredPaymentMethodState",
  default: undefined,
  effects: [
    localStorageEffect({
      get: SelectedStoredPaymentMethodStorageAdapter.get,
      set: SelectedStoredPaymentMethodStorageAdapter.set,
      delete: SelectedStoredPaymentMethodStorageAdapter.delete,
    }),
  ],
});

export const AGBAcceptedState = atom<boolean>({
  key: "_AGBAcceptedState",
  default: false,
  effects: [
    localStorageEffect({
      get: AGBStorageAdapter.get,
      set: AGBStorageAdapter.set,
      delete: AGBStorageAdapter.delete,
    }),
  ],
});

export const SubscribedToBonusCardState = atom<boolean>({
  key: "_SubscribedToBonusCardState",
  default: false,
  effects: [
    localStorageEffect({
      get: BonusCardStorageAdapter.get,
      set: BonusCardStorageAdapter.set,
      delete: BonusCardStorageAdapter.delete,
    }),
  ],
});

//FINE

export const SubscribeToNewsletterState = atom<boolean>({
  key: "_SubscribeToNewsletterState",
  default: false,
  effects: [
    localStorageEffect({
      get: NewsletterSubscriptionStorageAdapter.get,
      set: NewsletterSubscriptionStorageAdapter.set,
      delete: NewsletterSubscriptionStorageAdapter.delete,
    }),
  ],
});
export const NewsletterVetoState = atom<boolean>({
  key: "_NewsletterVetoState",
  default: false,
  effects: [
    localStorageEffect({
      get: NewsletterVetoStorageAdapter.get,
      set: NewsletterVetoStorageAdapter.set,
      delete: NewsletterVetoStorageAdapter.delete,
    }),
  ],
});
export const ResubscribeToNewsletterState = atom<boolean>({
  key: "_ResubscribeToNewsletterState",
  default: false,
  effects: [
    localStorageEffect({
      get: ResubscribeNewsletterStorageAdapter.get,
      set: ResubscribeNewsletterStorageAdapter.set,
      delete: ResubscribeNewsletterStorageAdapter.delete,
    }),
  ],
});
export const OrderCommentState = atom<string>({
  key: "_OrderCommentState",
  default: "",
  effects: [
    localStorageEffect({
      get: OrderCommentStorageAdapter.get,
      set: OrderCommentStorageAdapter.set,
      delete: OrderCommentStorageAdapter.delete,
    }),
  ],
});

export const UserFirstNameState = atom<string>({
  key: "_UserFirstNameState",
  default: "",
  effects: [
    localStorageEffect({
      get: UserFirstNameStorageAdapter.get,
      set: UserFirstNameStorageAdapter.set,
      delete: UserFirstNameStorageAdapter.delete,
    }),
  ],
});

export const UserLastNameState = atom<string>({
  key: "_UserLastNameState",
  default: "",
  effects: [
    localStorageEffect({
      get: UserLastNameStorageAdapter.get,
      set: UserLastNameStorageAdapter.set,
      delete: UserLastNameStorageAdapter.delete,
    }),
  ],
});

export const BellNameState = atom<string>({
  key: "_BellName",
  default: "",
});

export const IsV2UserState = atom<boolean>({
  key: "_IsV2UserState",
  default: false,
  effects: [
    localStorageEffect({
      get: V2UserStorageAdapter.get,
      set: V2UserStorageAdapter.set,
      delete: V2UserStorageAdapter.delete,
    }),
  ],
});
