import { atom, selector } from "recoil";

import { IChatState } from "./Chat.interface";

const ChatState = atom<IChatState>({
  key: "_ChatState",
  default: {
    chatIsOnline: false,
    chatPluginLoaded: false,
  },
});

export const ChatIsOnline = selector<boolean>({
  key: "_ChatIsOnline",
  get: ({ get }) => {
    const { chatIsOnline } = get(ChatState);
    return chatIsOnline;
  },
  set: ({ get, set }, chatIsOnline) => {
    const chatState = get(ChatState);
    set(ChatState, { ...chatState, chatIsOnline });
  },
});

export const ChatPluginLoaded = selector<boolean>({
  key: "_ChatPluginLoaded",
  get: ({ get }) => {
    const { chatPluginLoaded } = get(ChatState);
    return chatPluginLoaded;
  },
  set: ({ get, set }, chatPluginLoaded) => {
    const chatState = get(ChatState);
    set(ChatState, { ...chatState, chatPluginLoaded });
  },
});
