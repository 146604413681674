import styled from "@emotion/styled";
import { LibTheme, Typography } from "@stadtsalat/component-lib";

export const _CategoryNavbarOutWrapper = styled.div<{
  isSticky: boolean;
  alertBoxHeight: number;
}>`
  position: sticky;
  top: ${({ alertBoxHeight }) => alertBoxHeight}px;
  width: 100%;
  box-shadow: ${({ isSticky }) =>
    isSticky ? "0 2px 8px 0 rgba(0, 0, 0, 0.15)" : "none"};
  transition: ${({ isSticky }) =>
    isSticky ? "box-shadow 0.3s ease-in-out" : "none"};
  z-index: 7;
  background-color: ${LibTheme.colors.paper_light_100};
`;
export const _CategoryNavbarWrapper = styled.div<{ filterActive: boolean }>`
  padding: 1rem 1.25rem 1rem 0.75rem;
  margin-top: 7rem;
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  height: ${({ filterActive }) => (filterActive ? "7rem" : "5.125rem")};
  transition: height 0.3s ease-in-out 0.1s;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    padding: 1rem 1.25rem;
    margin: 0 auto;
    max-width: 90rem;
    margin-top: 6rem;
  }
`;

export const _LayoutWrapper = styled.div<{ gap: string; active?: boolean }>`
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ gap }) => gap};
  padding-left: 0;
  display: ${({ active }) => (active ? "flex" : "none")};
  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    padding-left: 0.75rem;
  }
`;

export const _Category = styled.div<{ isActive?: boolean }>`
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s,
    transform 0.3s cubic-bezier(0.25, 0.75, 0.75, 1), font-weight 0.3s;
  transform: ${({ isActive }) => (isActive ? "scale(1.05)" : "scale(1)")};

  &.last {
    margin-right: 1rem;
  }

  &:hover {
    transform: scale(1.05);

    p {
      /* font-weight: semibold !important; */
    }
  }
`;
export const _CategoryLabel = styled(Typography)<{ isActive?: boolean }>`
  && {
    position: absolute;
    width: 100%;
    text-align: center;
    font-weight: ${({ isActive }) => (isActive ? "600" : "normal")};
    line-height: 1.25;
    letter-spacing: -0.2px;
    white-space: nowrap;
  }
`;
export const _PseudoCategoryLabel = styled(Typography)`
  && {
    visibility: hidden;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: -0.2px;
    white-space: nowrap;
    transform: scale(1.05);
  }
`;
export const _CategoryWrapper = styled.div``;
export const _FilterIconInnerWrapper = styled.div`
  position: relative;
`;
export const _FilterIconWrapper = styled.div<{ isActive: boolean }>`
  position: sticky;
  height: 50px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgba(255, 250, 245, 0) 0%,
    rgba(255, 250, 245, 1) 40%,
    rgba(255, 250, 245, 1) 100%
  );
  padding-left: 1.5rem;
  padding-right: 0.5rem;
  margin-left: -2rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    transform: scale(1);
    transition: transform 0.3s ease;

    path {
      transition: fill 0.3s ease;
      fill: ${({ isActive }) =>
        isActive ? LibTheme.colors.green : LibTheme.colors.paper_dark_700};
    }

    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const _FilterPill = styled.div<{ isActive: boolean }>`
    border: ${({ isActive }) => (isActive ? "1px solid" : "1px solid")};
    border-color: ${({ isActive }) =>
      isActive ? LibTheme.colors.green : "#bcbcbc"};
    background-color: ${({ isActive }) =>
      isActive ? "rgba(230, 255, 180, 0.25)" : "transparent"};
    border-radius: 1.5rem;
    padding: 0.1rem 0.75rem;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;

    p {
        transition: color 0.3s;
        color: ${LibTheme.colors.text}
        user-select: none;
        pointer-events: none;
    }

    display: flex;
    width: auto;
    flex-grow: 0;
`;

export const _NavScrollbar = styled.div`
  position: relative;
  width: 100%;
`;
export const _FilterBar = styled.div<{ isFilterBarActive: boolean }>`
  width: 100%;
  height: ${({ isFilterBarActive }) => (isFilterBarActive ? "auto" : "0px")};
  transform: ${({ isFilterBarActive }) =>
    isFilterBarActive ? "translateY(0px)" : "translateY(20px)"};
  opacity: ${({ isFilterBarActive }) => (isFilterBarActive ? "1" : "0")};
  transition: transform 0.3s
      ${({ isFilterBarActive }) => (isFilterBarActive ? "ease-in" : "ease-out")},
    opacity 0.15s
      ${({ isFilterBarActive }) => (isFilterBarActive ? "ease-in" : "ease-out")},
    height 0.3s
      ${({ isFilterBarActive }) => (isFilterBarActive ? "ease-in" : "ease-out")}
      0.15s;
  ${({ isFilterBarActive }) => (isFilterBarActive ? "ease-in" : "ease-out")};
`;
export const _ScrollContainer = styled.div<{ gap: string; isSticky: boolean }>`
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  gap: ${({ gap }) => gap};
  position: relative;
  padding-left: 0.5rem;
  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    margin-right: ${({ isSticky }) => (isSticky ? "19.5rem" : "0")};
  }

  &&::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

export const _FilterCountChip = styled.div<{
  isVisible: boolean;
}>`
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  align-items: center;
  justify-content: center;

  display: flex;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  transition: opacity 0.3s;

  border-radius: 50%;
  width: 14px;
  height: 14px;

  background-color: #a0dc41;

  p {
    color: #fffaf5;
    font-size: 10px !important;
  }
`;
