import styled from "@emotion/styled";
import { LibTheme, Typography } from "@stadtsalat/component-lib";

export const _InfoFormDropdownWrapper = styled.div<{
  hideMargin?: boolean;
}>`
  position: relative;
  flex-direction: column;
  transition: margin 0.75s ease-in-out;

  display: flex;

  margin-bottom: ${({ hideMargin }) => (hideMargin ? "0" : "1.5rem")};

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    margin-right: ${({ hideMargin }) => (hideMargin ? "0" : "1.5rem")};
    margin-bottom: 0;
  }
`;

export const _DropdownLabel = styled.div`
  margin-left: 0.5rem;
  margin-bottom: 0.25rem;

  user-select: none;
`;

export const _Dropdown = styled.div`
  position: relative;
  z-index: 13;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  display: flex;

  transition: background-color 0.3s ease-in-out;

  border-radius: 4px;

  background-color: #f8f0e5;
  cursor: pointer;

  line-height: 1.43;
  user-select: none;

  &:hover {
    background-color: #f2e7d9;
  }

  p {
    padding: 0.5rem 0;

    pointer-events: none;
  }
`;

export const _MobileSelect = styled.select`
  position: absolute;
  z-index: 100;
  inset: 0;

  opacity: 0;

  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0;

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    display: none;
  }
`;

export const _LabelWrapper = styled(Typography)`
  padding-left: 0.75rem !important;
`;

export const _ChevronWrapper = styled.div<{ isOpen: boolean }>`
  width: 30px;
  height: 30px;

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    animation: ${({ isOpen }) => (isOpen ? "rotateIn" : "rotateOut")} 0.5s;

    transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
    transition: transform 0.5s;
  }
`;

export const _DropdownOptions = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 12;
  top: 100%;
  right: 0;
  left: 0;
  flex-direction: column;
  gap: 0.5rem;

  display: none;
  opacity: ${({ isOpen: open }) => (open ? "1" : "0")};
  transform: ${({ isOpen: open }) =>
    open ? "translateY(0)" : "translateY(-20%)"};

  overflow: hidden;

  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;

  margin-top: 0.75rem;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  background-color: #fffaf5;

  pointer-events: ${({ isOpen: open }) => (open ? "all" : "none")};
  user-select: none;

  > div:first-of-type {
    margin-top: 0.5rem;
  }

  > div:last-child {
    margin-bottom: 0.5rem;
  }

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    display: flex;
  }
`;

export const _InfoFormOption = styled.div<{ isSelected: boolean }>`
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  display: flex;

  transition: background-color 0.25s ease-in-out, border 0.25s ease-in-out;

  margin: 0 0.5rem;
  border: ${({ isSelected: selected }) =>
    selected ? "1px solid #a0dc41" : "1px solid #e6e1d7"};
  border-radius: 4px;
  padding: 0.5rem 0.75rem;

  background-color: ${({ isSelected: selected }) =>
    selected ? "rgba(230, 255, 180, 0.25)" : "transparent"};

  cursor: pointer;

  &:hover {
    ${({ isSelected: selected }) =>
      selected ? "" : "border: 1px solid rgba(40, 25, 10, 0.5)"};
  }
`;
