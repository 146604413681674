"use client";
import withExperiment from "@components/hocs/withExperiment";
import {
  BaseNotification,
  BaseNotificationVariantEnum,
} from "@stadtsalat/component-lib";
import {
  createRef,
  FC,
  useCallback,
  useDeferredValue,
  useEffect,
  useState,
} from "react";
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import useCart from "src/hooks/useCart";
import useProducts from "src/hooks/useProducts/useProducts";
import { ProductGroupState } from "src/state/Products";
import { formatNumber, getImageUrlByProduct } from "src/util/product.util";

import useBrowserInfo from "../../../../hooks/useBrowserInfo";
import useCMS from "../../../../hooks/useCMS";
import { StoreState } from "../../../../state/Store/Store.state";
import {
  AddonsSelector,
  ConfiguratorIdState,
} from "../../hooks/useConfigurator/useConfigurator.state";
import useFilter from "../../hooks/useFilter";
import {
  CMSDataMenu,
  MenuProductCategory,
} from "../../MenuViewPage.interfaces";
import {
  MenuFilterOpenState,
  MenuScrollActiveCategory,
  StoreAlertAndVoucherBoxHeightSelector,
} from "../../state/Menu";
import {
  ConfiguratorInitialAddons,
  ConfiguratorIsOpenState,
  ConfiguratorSelectedItemsSelector,
  ConfiguratorSelectedVariantId,
} from "../Configurator/configurator.state";
import CustomsProductCard from "../CustomsProductCard";
import ProductCard, { LoadingProductCard } from "../ProductCard";
import {
  IDumbMenuProps,
  IMenuProps,
  MenuProductCategoryWithRef,
  ProductCategory,
} from "./Menu.interfaces";
import {
  _CategoryHeader,
  _CategoryWrapper,
  _MenuCategoryInfoWrapper,
  _MenuWrapper,
  _ProductGrid,
} from "./Menu.styled";
import HydratedProduct = Definitions.HydratedProduct;
import ProductGroup = Definitions.ProductGroup;

export const TestIds = {
  loadingProducts: "loading-products",
  productGrid: "product-grid",
  emptyCategoryBanner: "empty-category-banner",
  lastCustomCard: "last-custom-card",
  productCard: "product-card",
  productCardUnavailable: "product-card-unavailable",
};

const sortProducts = (products: HydratedProduct[]) => {
  if (!products) return [];
  const sortedProducts = [...products].sort((productA, productB) => {
    return productA.sorting - productB.sorting;
  });
  return sortedProducts;
};

export const getProductCardTestId = (categoryIndex: number, index: number) =>
  `product-card-${categoryIndex}-${index}`;

export const getProductCardUnavailableTestId = (
  categoryIndex: number,
  index: number
) => `product-card-unavailable-${categoryIndex}-${index}`;

const MenuWrapperDumb: FC<IDumbMenuProps> = (props) => {
  const {
    productCategories,
    pageIsLoading,
    addToCart,
    resetFilters,
    cmsData,
    onClickBowl,
    isMobile,
    getProductById,
    productGroups,
    activeFilters,
  } = props;

  const getFilteredVariants = useCallback(
    (product: HydratedProduct) => {
      if (!product.tags.includes("product.productGroup")) {
        return [];
      }

      const group = productGroups.find((group) => group.id === product.id);
      if (!group) {
        return [];
      }

      return group.items
        .filter((item) => item !== undefined)
        .map((item) => {
          return {
            ...item,
            isAvailable: item?.available && !item?.permanentlyUnavailable,
            image: item.image,
          };
        })
        .sort((a, b) =>
          a.isAvailable === b.isAvailable ? 0 : a.isAvailable ? -1 : 1
        )
        .filter(
          (item) =>
            !(activeFilters as typeof product.dietaryInfo.tags)
              .map((filter) => {
                return item.dietaryTags.includes(filter);
              })
              .some((includeFilter) => !includeFilter)
        );
    },
    [activeFilters, productGroups]
  );

  const handleBowlClick = useCallback(
    (product: HydratedProduct, variantId?: string) => {
      const productGroup = productGroups.find(
        (group) => group.id === product.id
      );
      const filteredVariants = getFilteredVariants(product);
      const selectedVariantId =
        variantId ||
        filteredVariants.reduce((prev, cur) => {
          if (
            cur.isAvailable &&
            cur.price?.withVat > (prev?.price?.withVat || 0)
          ) {
            return cur;
          }
          return prev;
        }, filteredVariants[0] || null)?.id ||
        null;

      const finalVariantId =
        selectedVariantId || productGroup?.defaultOptionId || product.id;

      const variant = getProductById(finalVariantId);
      const addons = variant?.includes.map((a) => a.id) || [];

      onClickBowl(productGroup?.id || product.id, addons, selectedVariantId);
    },
    [getProductById, onClickBowl, productGroups, getFilteredVariants]
  );

  const handleCardClick = useCallback(
    (product: HydratedProduct, variantId?: string) => {
      if (
        product.tags.includes("product.bowl") ||
        product.tags.includes("product.productGroup")
      ) {
        handleBowlClick(product, variantId);
      } else {
        addToCart(product);
      }
    },
    [addToCart, handleBowlClick]
  );

  return (
    <_MenuWrapper>
      {productCategories?.map(
        (
          {
            id,
            label,
            availableProducts,
            unavailableProducts,
            noProductMatchingFilter,
            isCustomCategory,
            ref,
          },
          categoryIndex
        ) => {
          const Card = isCustomCategory ? CustomsProductCard : ProductCard;
          return (
            <_CategoryWrapper id={id} key={`category-${id}`} ref={ref}>
              <_CategoryHeader style={"m24"} variant={"semibold"}>
                {label}
              </_CategoryHeader>
              {pageIsLoading ? (
                <_ProductGrid
                  visible={true}
                  data-testid={`${TestIds.loadingProducts}-${id}`}
                >
                  <LoadingProductCard />
                  <LoadingProductCard />
                  <LoadingProductCard />
                  <LoadingProductCard />
                </_ProductGrid>
              ) : null}
              <_ProductGrid
                visible={!pageIsLoading}
                data-testid={TestIds.productGrid}
              >
                {availableProducts?.length === 0 &&
                unavailableProducts.length === 0 ? (
                  <_MenuCategoryInfoWrapper
                    data-testid={TestIds.emptyCategoryBanner}
                  >
                    <BaseNotification
                      variant={BaseNotificationVariantEnum.PRODUCT_INFO}
                      text={
                        noProductMatchingFilter
                          ? cmsData.noProductMatchingFilterMessage
                          : cmsData.categoryEmptyMessage
                      }
                      hasIcon={true}
                      buttons={
                        noProductMatchingFilter
                          ? [
                              {
                                text: cmsData.resetFilterLabels,
                                onClick: () => {
                                  resetFilters();
                                },
                              },
                            ]
                          : undefined
                      }
                    />
                  </_MenuCategoryInfoWrapper>
                ) : null}
                {(pageIsLoading ? [] : availableProducts)?.map(
                  (product, index) => {
                    let priority = false;
                    if (isMobile) {
                      if (categoryIndex === 0 && index === 0) {
                        priority = true;
                      }
                    } else {
                      if (categoryIndex === 0 && index < 5) {
                        priority = true;
                      }
                    }
                    return (
                      <Card
                        key={`${product.id}#${index}`}
                        name={product.name}
                        image={product.image}
                        onClick={(variantId?: string) =>
                          handleCardClick(
                            product,
                            variantId ? variantId : undefined
                          )
                        }
                        description={product.description}
                        price={
                          isCustomCategory
                            ? null
                            : `${formatNumber(product.price.withVat)} €`
                        }
                        kcal={
                          isCustomCategory
                            ? null
                            : Math.round(
                                parseFloat(
                                  product.dietaryInfo?.nutrients?.energyKcal
                                )
                              )
                        }
                        priority={priority}
                        tags={
                          product.tags.includes("product.productGroup")
                            ? ["label.variant"]
                            : product.productTags
                        }
                        cmsData={cmsData}
                        dataTestId={
                          product.tags.includes("product.configurable")
                            ? TestIds.lastCustomCard
                            : getProductCardTestId(categoryIndex, index)
                        }
                        isAvailable={true}
                        isProductGroup={product.tags.includes(
                          "product.productGroup"
                        )}
                        variants={getFilteredVariants(product)}
                      />
                    );
                  }
                )}
                {(pageIsLoading ? [] : unavailableProducts)?.map(
                  (product, index) => (
                    <Card
                      key={product.id}
                      name={product.name}
                      image={product.image}
                      onClick={() => {}}
                      description={product.description}
                      price={product.price.withVat}
                      kcal={Math.round(
                        parseFloat(product.dietaryInfo?.nutrients?.energyKcal)
                      )}
                      isAvailable={false}
                      priority={false}
                      cmsData={cmsData}
                      dataTestId={
                        product.tags.includes("product.configurable")
                          ? TestIds.lastCustomCard
                          : getProductCardUnavailableTestId(
                              categoryIndex,
                              index
                            )
                      }
                      isProductGroup={product.tags.includes(
                        "product.productGroup"
                      )}
                      variants={
                        product.tags.includes("product.productGroup")
                          ? productGroups.find(
                              (group) => group.id === product.id
                            )?.items
                          : []
                      }
                    />
                  )
                )}
              </_ProductGrid>
            </_CategoryWrapper>
          );
        }
      )}
    </_MenuWrapper>
  );
};
const mapProductCategoryToProductCategoryWithRef = (
  category: MenuProductCategory
): MenuProductCategoryWithRef => ({
  ...category,
  ref: createRef<HTMLDivElement>(),
});
const MenuWrapper: FC<IMenuProps & { variant: "control" | "A" }> = (props) => {
  const { preFetchedProducts, variant } = props;
  const { activeFilters, resetFilters } = useFilter();

  const { getCMSValueByKeyGenerator, cmsData } = useCMS();
  const getProductLabels = getCMSValueByKeyGenerator<
    CMSDataMenu,
    CMSDataMenu["productLabels"]
  >("productLabels");
  const getProductCategories = getCMSValueByKeyGenerator<
    CMSDataMenu,
    CMSDataMenu["productOverview"]
  >("productOverview");
  const { getProductById } = useProducts();
  const { isMobile } = useBrowserInfo();
  const { addToCart } = useCart();
  const store = useRecoilValue(StoreState);
  const filterOpen = useRecoilValue(MenuFilterOpenState);
  const [_activeCategory, setActiveCategory] = useRecoilState(
    MenuScrollActiveCategory
  );
  const fetchedProductGroups = useRecoilValue(ProductGroupState);
  const [products, setProducts] = useState(
    sortProducts(store?.products ? store.products : preFetchedProducts)
  );
  const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);
  const alertBoxHeight = useRecoilValue(StoreAlertAndVoucherBoxHeightSelector);
  const productCategories = getProductCategories(
    "categories"
  ) as CMSDataMenu["productOverview"]["categories"];
  const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);

  const productCategoriesWithRef = productCategories?.map(
    mapProductCategoryToProductCategoryWithRef
  );

  const addProductToCart = useCallback(
    (product: HydratedProduct) => {
      const includedAddons = product.includes.map((included) => {
        return {
          id: included.id,
          product: included.id,
          productId: included.id,
          count: 1,
        };
      });
      addToCart(product.id, includedAddons);
    },
    [addToCart]
  );
  const mapProductsToMenuCategories = useCallback(
    (_productCategoriesWithRef: MenuProductCategoryWithRef[]) => {
      const mappedProductGroups = productGroups
        .filter((group) => group.items.length >= 1)
        .map((group) => {
          const defaultProduct = group.items.find(
            (item) => item.id === group.defaultOptionId
          );
          const cheapestProduct = group.items.reduce((prev, cur) => {
            if (cur?.price?.withVat < prev?.price?.withVat) {
              return cur;
            }
            return prev;
          }, defaultProduct);

          return {
            name: group.name,
            description: group.ingredients.map((i) => i.name).join(" · "),
            price: cheapestProduct?.price || {
              withVat: 0,
              withoutVat: 0,
            },
            image: defaultProduct?.image || "",
            available:
              group.items.reduce((prev, cur) => {
                if (cur.available && !cur.permanentlyUnavailable) {
                  return [...prev, cur];
                }
                return prev;
              }, []).length > 0,
            permanentlyUnavailable: false,
            productTags: defaultProduct?.tags.includes("category.top-seller")
              ? ["product.productGroup", "product.bowl", "category.top-seller"]
              : ["product.productGroup", "product.bowl"],
            id: group.id,
            tags: ["product.productGroup"],
            includes: [],
            sorting: group.sorting,
            dietaryInfo: {
              tags: Array.from(
                new Set(group.items.map((item) => item.dietaryTags).flat())
              ),
            },
          } as HydratedProduct;
        });

      const mergedProducts = (
        variant === "control" ? [] : mappedProductGroups
      ).concat(
        variant === "control"
          ? products
          : products.filter((product) => {
              const allProductGroupItems = productGroups.reduce(
                (prev, cur) => [...prev, ...cur.items],
                [] as ProductGroup["items"]
              );
              return !allProductGroupItems.some(
                (item) => item.id === product.id
              );
            })
      );

      return _productCategoriesWithRef?.reduce((prev, cur) => {
        const productsOfCategory = mergedProducts.filter(
          (product) =>
            cur.productTags.some((tag) =>
              product.productTags.includes(tag as any)
            ) &&
            (cur.excludeTags.length === 0 ||
              !cur.excludeTags.some((tag) =>
                product.productTags.includes(tag as any)
              ))
        );

        const filteredProducts = productsOfCategory
          .filter(
            (product) =>
              !(activeFilters as typeof product.dietaryInfo.tags)
                .map((filter) => {
                  return (
                    product.dietaryInfo.tags.includes(filter) ||
                    product.tags.includes("product.configurable")
                  );
                })
                .some((includeFilter) => !includeFilter)
          )
          .map(
            (product) =>
              Object.assign(
                {},
                {
                  image: "",
                },
                { ...product },
                {
                  image: getImageUrlByProduct(
                    product,
                    isMobile ? 600 : 500,
                    !product?.tags.includes("product.drink")
                  ),
                }
              ) as HydratedProduct
          );

        const category: ProductCategory = {
          id: cur.id,
          label: cur.label,
          ref: cur.ref,
          availableProducts: sortProducts(
            filteredProducts.filter(
              (product) => product.available && !product.permanentlyUnavailable
            )
          ),
          unavailableProducts: sortProducts(
            filteredProducts.filter(
              (product) => !product.available || product.permanentlyUnavailable
            )
          ),
          noProductMatchingFilter:
            productsOfCategory.filter((product) => product.available).length >
              0 &&
            filteredProducts.filter((product) => product.available).length ===
              0,
          isCustomCategory: cur.productTags.includes("product.configurable"),
        };
        return [...prev, category];
      }, []);
    },
    [activeFilters, products, productGroups, variant]
  );
  const menuCategories = mapProductsToMenuCategories(productCategoriesWithRef);
  const deferredMenuCategories = useDeferredValue(activeFilters);

  useEffect(() => {
    if (store?.products) {
      setPageIsLoading(false);
    }
  }, [store?.products]);

  useEffect(() => {
    if (store?.products) {
      const sortedProducts = sortProducts(store.products);
      setProducts(sortedProducts);
    }
  }, [store?.products]);

  useEffect(() => {
    if (fetchedProductGroups) {
      setProductGroups(fetchedProductGroups);
    }
  }, [fetchedProductGroups]);

  useEffect(() => {
    const scrollToCategory = menuCategories?.find(
      (pc) => pc.id === _activeCategory
    );
    if (scrollToCategory?.ref?.current) {
      const boundingReact =
        scrollToCategory.ref.current.getBoundingClientRect();
      const scrollPosition = Math.abs(boundingReact.top) + 150;
      setTimeout(() => {
        window.scrollTo({
          top: window.scrollY - scrollPosition,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [deferredMenuCategories]);

  const scrollHandler = useCallback(() => {
    const scrollPoints = productCategoriesWithRef?.map(
      (c) => c.ref?.current?.offsetTop
    );
    let activeCategoryId = "";
    const windowScroll =
      window.scrollY + (filterOpen ? 140 : 100) + alertBoxHeight;

    for (let i = 0; i < scrollPoints.length; i++) {
      if (windowScroll >= scrollPoints[i]) {
        activeCategoryId = productCategoriesWithRef[i].id;
      }
    }
    setActiveCategory(activeCategoryId);
  }, [alertBoxHeight, setActiveCategory, filterOpen, productCategoriesWithRef]);
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [productCategoriesWithRef]);

  const onClickBowl = useRecoilCallback(
    ({ snapshot, set }) =>
      async (productId: string, addons: string[], variantId?: string) => {
        const productGroups = await snapshot.getPromise(ProductGroupState);
        set(ConfiguratorIdState, productId);
        set(
          ConfiguratorSelectedVariantId,
          variantId ||
            productGroups.find((g) => g.id === productId)?.defaultOptionId ||
            null
        );
        set(ConfiguratorInitialAddons, addons);
        set(AddonsSelector, []);
        set(
          ConfiguratorSelectedItemsSelector("addons"),
          addons.map((id) => ({
            id,
            count: 1,
          }))
        );
        set(ConfiguratorSelectedItemsSelector("extras"), []);
        set(ConfiguratorIsOpenState, true);
      },
    [getProductById]
  );
  return (
    <MenuWrapperDumb
      pageIsLoading={pageIsLoading}
      onClickBowl={onClickBowl}
      productCategories={menuCategories}
      addToCart={addProductToCart}
      resetFilters={resetFilters}
      cmsData={{
        labels: getProductLabels("labels"),
        noProductMatchingFilterMessage: getProductLabels(
          "noProductMatchingFilterMessage"
        ),
        resetFilterLabels: getProductLabels("resetFilterLabels"),
        categoryEmptyMessage: getProductLabels("categoryEmptyMessage"),
      }}
      isMobile={isMobile}
      getProductById={getProductById}
      productGroups={productGroups}
      activeFilters={activeFilters}
    />
  );
};

export default withExperiment<IMenuProps>("ab_protein_switch", {
  control: (props: IMenuProps) => (
    <MenuWrapper {...props} variant={"control"} />
  ),
  PROTEIN_A: (props: IMenuProps) => <MenuWrapper {...props} variant={"A"} />,
});
// export default MenuWrapper;
