import styled from "@emotion/styled";
import { LibTheme } from "@stadtsalat/component-lib";

export const _MenuViewPageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  background-color: #fffaf5;
`;

export const _OnlyMobileWrapper = styled.div`
  display: block;
  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    display: none;
  }
`;
