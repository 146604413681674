import { RouteMap } from "@util/router";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { ChatIsOnline, ChatPluginLoaded } from "src/state/Chat";

const useChat = () => {
  const { push } = useRouter();
  const chatIsOnline = useRecoilValue(ChatIsOnline);
  const chatPluginLoaded = useRecoilValue(ChatPluginLoaded);

  const sendMessageAndOpenChat = useCallback(
    (message: string) => {
      try {
        if (chatIsOnline) {
          window.$zopim.livechat.say(message);
          window.$zopim.livechat.window.show();
        }
      } catch (err) {}
    },
    [chatIsOnline]
  );
  const openChat = useCallback(() => {
    try {
      if (chatIsOnline) {
        window.$zopim.livechat.window.show();
      } else {
        push(RouteMap.CONTACT());
      }
    } catch (err) {}
  }, [chatIsOnline]);

  return {
    sendMessageAndOpenChat,
    openChat,
    chatPluginLoaded,
    chatIsOnline,
  };
};

export default useChat;
