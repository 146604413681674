import styled from "@emotion/styled";
import { LibTheme } from "@stadtsalat/component-lib";

export const _HeroComponentWrapper = styled.div<{ isFirstTimeUser: boolean }>`
  position: relative;
  z-index: ${({ isFirstTimeUser }) => (isFirstTimeUser ? 11 : "unset")};
  width: 100%;
  max-width: ${({ isFirstTimeUser }) => (isFirstTimeUser ? "75rem" : "88rem")};
  transition: max-width 0.75s ease-in-out;

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    margin: 0 auto 4rem auto;
  }
`;

export const _ScrollChevronWrapper = styled.div<{
  scrollY: number;
  isActive: boolean;
}>`
  position: fixed;
  z-index: 15;
  bottom: 1rem;
  left: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  display: none;
  opacity: ${({ isActive, scrollY }) =>
    !isActive ? 0 : Math.min(1, 0.8 + scrollY / 100)};
  transform: translateX(-50%);

  pointer-events: none;

  svg {
    animation: bounce 0.5s infinite cubic-bezier(0.5, 0.05, 1, 0.5);
    animation-direction: alternate;

    width: ${({ scrollY }) => Math.min(8, 4 + scrollY / 75)}rem;
    height: ${({ scrollY }) => Math.min(8, 4 + scrollY / 75)}rem;

    path {
      fill: #fffaf5;
    }
  }

  p {
    font-size: ${({ scrollY }) => Math.min(1.5, 1 + scrollY / 600)}rem;
    color: #fffaf5;
  }

  @keyframes bounce {
    from {
      transform: translate3d(0, -7px, 0);
    }
    to {
      transform: translate3d(0, 7px, 0);
    }
  }

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    display: flex;
  }
`;

export const _HeroImageWrapper = styled.div<{ isFirstTimeUser: boolean }>`
  position: relative;
  z-index: ${({ isFirstTimeUser: firstTimeUser }) => (firstTimeUser ? 10 : 1)};

  overflow: hidden;

  transition: margin-top 0.75s ease-in-out, border-radius 0.75s ease-in-out,
    transform 0.75s ease-in-out, height 0.75s ease-in-out;

  height: ${({ isFirstTimeUser: firstTimeUser }) =>
    firstTimeUser ? "40vh" : "45vh"};
  margin-top: 0;

  @media (min-width: ${LibTheme.mediaBreakpoints.desktop}) {
    transform: ${({ isFirstTimeUser: firstTimeUser }) =>
      firstTimeUser ? "scale(1.2)" : "scale(1)"};

    margin-top: ${({ isFirstTimeUser: firstTimeUser }) =>
      firstTimeUser ? "10rem" : "0"};
    border-radius: ${({ isFirstTimeUser: firstTimeUser }) =>
      firstTimeUser ? "1rem" : "0 0 1rem 1rem"};
    height: 25rem;
  }
`;

export const _CloseButtonWrapper = styled.div<{
  isActive: boolean;
  isClosingByButton: boolean;
}>`
  position: absolute;
  z-index: 15;
  top: 1rem;
  right: 1rem;
  align-items: center;
  justify-content: center;

  display: flex;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transform: scale(1);

  animation: ${({ isActive, isClosingByButton }) => {
    if (isClosingByButton) {
      return "spinOut 0.75s";
    } else if (isActive) {
      return "none";
    } else {
      return "none";
    }
  }};
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  border: 2px solid #fffaf5;
  border-radius: 50%;

  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;

    path {
      fill: #fffaf5;
    }
  }

  &:hover {
    transform: scale(1.1);
  }

  @keyframes spinOut {
    0% {
      transform: rotate(180deg);
      opacity: 1;
      box-shadow: 0 0 0 0 rgba(255, 250, 245, 0.7);
    }
    70% {
      box-shadow: 0 0 0 15px rgba(255, 250, 245, 0);
    }
    100% {
      transform: rotate(-180);
      opacity: 0;
      box-shadow: 0 0 0 0 rgba(255, 250, 245, 0);
    }
  }
`;

export const _ImageLoadingWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;

  display: flex;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  transition: opacity 0.75s ease-in-out;

  width: 100%;
  height: 100%;
`;
export const _HeroLogoOuterWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  padding-bottom: var(--hero-image-overlap-mobile);
  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    padding-bottom: var(--hero-image-overlap-tablet);
  }
  @media (min-width: ${LibTheme.mediaBreakpoints.desktop}) {
    padding-bottom: var(--hero-image-overlap-desktop);
  }
`;
export const _HeroLogoRelativeWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
