import { BaseIcon, Icons, Typography } from "@stadtsalat/component-lib";
import { FC, useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import useCMS from "src/hooks/useCMS";

import {
  FirstTimeUserScreenVisitedState,
  FirstTimeUserState,
} from "../../../../state/Menu/Menu.state";
import { PageIsLoadingState } from "../../../../state/Util";
import { CMSDataMenu } from "../../MenuViewPage.interfaces";
import { MenuBackgroundBlurActiveState } from "../../state/Menu";
import DynamicHeroImage from "./components/DynamicHeroImage";
import StoreInfoForm from "./components/StoreInfoForm";
import { StoreInfoSelectDumbProps } from "./StoreInfoSelect.interfaces";
import {
  _CloseButtonWrapper,
  _HeroComponentWrapper,
  _HeroImageWrapper,
  _ScrollChevronWrapper,
} from "./StoreInfoSelect.styled";

export const StoreInfoSelect: FC = () => {
  const MOBILE_SCREEN_WIDTH = 768;
  const SCROLL_THRESHOLD = 300;
  const [isClosingByButton, setIsClosingByButton] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const setBackgroundBlurActive = useSetRecoilState(
    MenuBackgroundBlurActiveState
  );
  const [isFirstTimeUser, setIsFirstTimeUser] =
    useRecoilState(FirstTimeUserState);

  const setFirstTimeUserScreenVisited = useSetRecoilState(
    FirstTimeUserScreenVisitedState
  );
  const isLoading = useRecoilValue(PageIsLoadingState);
  const { getCMSValueByKeyGenerator } = useCMS();
  const getStoreInfoSelectCMSData = getCMSValueByKeyGenerator<
    CMSDataMenu,
    CMSDataMenu["storeInfoSelect"]
  >("storeInfoSelect");

  const handleClose = useCallback(() => {
    setBackgroundBlurActive(false);
    setIsFirstTimeUser(false);
    setFirstTimeUserScreenVisited(true);
  }, [
    setFirstTimeUserScreenVisited,
    setIsFirstTimeUser,
    setBackgroundBlurActive,
  ]);
  useEffect(() => {
    if (!isLoading && isFirstTimeUser) {
      setBackgroundBlurActive(true);
    }
  }, [isLoading, isFirstTimeUser]);

  const handleScroll = useCallback(
    (e: WheelEvent | TouchEvent) => {
      if (!isFirstTimeUser || window.innerWidth < MOBILE_SCREEN_WIDTH) {
        return;
      }
      setScrollY((prevScrollY) => {
        let newScrollY: number = 0;
        if ("deltaY" in e) {
          newScrollY = prevScrollY + e.deltaY;
        } else if ("changedTouches" in e) {
          newScrollY = prevScrollY + 10;
        }
        if (newScrollY > SCROLL_THRESHOLD) {
          handleClose();
        }
        return newScrollY < 0 ? 0 : newScrollY;
      });
    },
    [isFirstTimeUser, setScrollY, handleClose]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape" && isFirstTimeUser) {
        handleClose();
      }
    },
    [handleClose, isFirstTimeUser]
  );

  useEffect(() => {
    window.addEventListener("wheel", handleScroll);
    window.addEventListener("touchmove", handleScroll);
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleScroll, handleKeyDown]);

  return (
    <_StoreInfoSelectDumb
      isFirstTimeUser={isFirstTimeUser && !isLoading}
      onClickOrder={handleClose}
      onClickClose={() => {
        handleClose();
        setIsClosingByButton(true);
      }}
      isClosingByButton={isClosingByButton}
      scrollY={scrollY}
      scrollLabel={getStoreInfoSelectCMSData("scrollLabel")}
      toMenuLabel={getStoreInfoSelectCMSData("toMenuLabel")}
      storeDropdownLabel={getStoreInfoSelectCMSData("storeDropdownLabel")}
      deliveryDropdownLabel={getStoreInfoSelectCMSData("deliveryDropdownLabel")}
    />
  );
};

export const _StoreInfoSelectDumb: FC<StoreInfoSelectDumbProps> = (props) => {
  const {
    isClosingByButton,
    isFirstTimeUser,
    onClickClose,
    onClickOrder,
    scrollY,
    scrollLabel,
    toMenuLabel,
    storeDropdownLabel,
    deliveryDropdownLabel,
  } = props;
  return (
    <_HeroComponentWrapper
      data-testid="hero-image"
      isFirstTimeUser={isFirstTimeUser}
    >
      <_ScrollChevronWrapper scrollY={scrollY} isActive={isFirstTimeUser}>
        <BaseIcon icon={Icons.CHEVRON_DOWN} />
        <Typography style="m20">{scrollLabel}</Typography>
      </_ScrollChevronWrapper>

      <_HeroImageWrapper isFirstTimeUser={isFirstTimeUser}>
        <_CloseButtonWrapper
          onClick={onClickClose}
          isActive={isFirstTimeUser}
          isClosingByButton={isClosingByButton}
          data-testid="close-button"
        >
          <BaseIcon icon={Icons.CLOSE} />
        </_CloseButtonWrapper>
        <DynamicHeroImage />
      </_HeroImageWrapper>
      <StoreInfoForm
        onClickOrder={onClickOrder}
        isFirstTimeUser={isFirstTimeUser}
        toMenuLabel={toMenuLabel}
        storeDropdownLabel={storeDropdownLabel}
        deliveryDropdownLabel={deliveryDropdownLabel}
      />
    </_HeroComponentWrapper>
  );
};

export default StoreInfoSelect;
