import { atom, selector, selectorFamily } from "recoil";
import { ProductGroupState } from "src/state/Products";

import { CMSConfiguratorResponseData } from "../../../../../pages/api/cms/configurator";
import { getConfiguratorCMSData } from "../../../../api/CMS/cms.api";
import { ErrorType } from "../../../../hooks/useErrorHandler/useErrorHandler.interfaces";
import { StoreState } from "../../../../state/Store/Store.state";
import { InternalCartItem } from "../../../../util/order";
import {
  ConfiguratorIdState,
  RemovedIngredientsSelector,
} from "../../hooks/useConfigurator/useConfigurator.state";
import { ICategoryV2 } from "./Configurator.interfaces";
import HydratedProduct = Definitions.HydratedProduct;
import ProductGroup = Definitions.ProductGroup;

export const ConfiguratorProductIdState = atom<string | null>({
  key: "_ConfiguratorV2ProductIdState",
  default: null,
});
export const ConfiguratorAddonIdsState = atom<string[]>({
  key: "_ConfiguratorV2AddonIdsState",
  default: [],
});

export const ConfiguratorProductState = selector<HydratedProduct | null>({
  key: "_ConfiguratorV2ProductState",
  get: ({ get }) => {
    const productId = get(ConfiguratorProductIdState);
    const store = get(StoreState);
    const productGroups = get(ProductGroupState);

    if (!productId) {
      return null;
    }

    const product =
      store?.products?.find(
        (product) =>
          product.id ===
          productGroups?.find((group) => group.id === productId).defaultOptionId
      ) && store?.products?.find((product) => product.id === productId);
    return product ?? null;
  },
});

export const StoreProductState = selector<HydratedProduct[]>({
  key: "_StoreProductState",
  get: ({ get }) => {
    const store = get(StoreState);
    return store?.products ?? [];
  },
});

export const ConfiguratorProductGroupState = selector<ProductGroup[]>({
  key: "_ConfiguratorProductGroupState",
  get: ({ get }) => {
    const productGroups = get(ProductGroupState);
    return productGroups;
  },
});

export const ConfiguratorActiveCategoryState = atom<string | null>({
  key: "_ConfiguratorActiveCategoryState",
  default: null,
});

export const ConfiguratorCMSSelector = selector<CMSConfiguratorResponseData>({
  key: "_ConfiguratorCategoriesState",
  get: async () => {
    try {
      return await getConfiguratorCMSData();
    } catch (err) {}
  },
});

type DisplayItem = {
  id: string;
  count: number;
};

export const ConfiguratorSelectedDisplayAddons = atom<DisplayItem[]>({
  key: "_ConfiguratorSelectedDisplayAddons",
  default: [],
});

export const ConfiguratorSelectedDisplayExtras = atom<DisplayItem[]>({
  key: "_ConfiguratorSelectedDisplayExtras",
  default: [],
});

export const ConfiguratorSelectedCartAddons = atom<DisplayItem[]>({
  key: "_ConfiguratorSelectedCartAddons",
  default: [],
});

export const ConfiguratorSelectedCartExtras = atom<DisplayItem[]>({
  key: "_ConfiguratorSelectedCartExtras",
  default: [],
});

export const ConfiguratorSelectedItemsSelector = selectorFamily<
  DisplayItem[],
  ICategoryV2["upsellType"]
>({
  key: "_ConfifuguratorSelectedItemsSelector",
  get:
    (upsellType) =>
    ({ get }) => {
      return get(
        upsellType === "addons"
          ? ConfiguratorSelectedDisplayAddons
          : ConfiguratorSelectedDisplayExtras
      );
    },
  set:
    (upsellType) =>
    ({ set }, selectedItems) => {
      set(
        upsellType === "addons"
          ? ConfiguratorSelectedDisplayAddons
          : ConfiguratorSelectedDisplayExtras,
        selectedItems
      );
      set(
        upsellType === "addons"
          ? ConfiguratorSelectedCartAddons
          : ConfiguratorSelectedCartExtras,
        selectedItems
      );
    },
});

export const ConfiguratorSelectedProductCountSelector = selectorFamily<
  number,
  {
    upsellType: ICategoryV2["upsellType"];
    productId: string;
  }
>({
  key: "_ConfiguratorSelectedProductCountSelectorV2",
  get:
    ({ upsellType, productId }) =>
    ({ get }) => {
      const selectedProducts = get(
        upsellType === "addons"
          ? ConfiguratorSelectedDisplayAddons
          : ConfiguratorSelectedDisplayExtras
      );
      if (selectedProducts) {
        const upsellItem = selectedProducts?.find(
          (item) => item.id === productId
        );
        if (upsellItem) {
          return upsellItem.count;
        }
      }
      return 0;
    },
});

export const ConfiguratorCartState = atom<InternalCartItem[]>({
  key: "_ConfiguratorCartState",
  default: [],
});

export const ConfiguratorCartFreeItemCountSelector = selectorFamily<
  number,
  string
>({
  key: "_ConfiguratorCartFreeItemCountSelector",
  get:
    (productId) =>
    ({ get }) => {
      let freeItemCount = 0;
      const configuratorId = get(ConfiguratorIdState);
      const selectedVariant = get(ConfiguratorSelectedVariantId);
      const cart = get(ConfiguratorCartState);
      const mainCartItem = cart.find(
        (item) =>
          item.id === (selectedVariant ? selectedVariant : configuratorId)
      );

      if (mainCartItem && mainCartItem.addons) {
        mainCartItem.addons.forEach((addon) => {
          if (addon.id === productId && addon.includedAddon) {
            freeItemCount += addon.count;
          }
        });
      }
      return freeItemCount;
    },
});

const getCategories = (
  isProductGroup: boolean,
  configuratorProductTags: string[],
  cmsData: CMSConfiguratorResponseData
): ICategoryV2[] => {
  return configuratorProductTags.includes("product.configurable")
    ? cmsData.cyoCategories
    : isProductGroup
    ? cmsData.proteinCategories
    : cmsData.categories;
};

export const ConfiguratorInitialAddons = atom<string[]>({
  key: "_ConfiguratorInitialAddons",
  default: [],
});

export const ConfiguratorInitialRemovedIngredients = atom<string[]>({
  key: "_ConfiguratorInitialRemovedIngredients",
  default: [],
});

export const ConfiguratorEditedProductInitialCount = atom<number>({
  key: "_ConfiguratorEditedProductInitialCount",
  default: 1,
});

export const ConfiguratorSelectedVariantId = atom<string | null>({
  key: "_ConfiguratorSelectedVariantId",
  default: null,
});

export const ConfiguratorSelectedProductGroup = selector<ProductGroup | null>({
  key: "_ConfiguratorSelectedProductGroup",
  get: ({ get }) => {
    const productGroups = get(ProductGroupState);
    const configuratorId = get(ConfiguratorIdState);
    const selectedVariant = get(ConfiguratorSelectedVariantId);

    return productGroups.find((group) => group.id === configuratorId)
      ? productGroups.find((group) => group.id === configuratorId)
      : null;
  },
});

export const ConfiguratorProductSelector = selector<HydratedProduct | null>({
  key: "_ConfiguratorProductSelector",
  get: ({ get }) => {
    const products = get(StoreProductState);
    const configuratorId = get(ConfiguratorIdState);
    const productGroups = get(ProductGroupState);
    const selectedVariant = get(ConfiguratorSelectedVariantId);

    let product: HydratedProduct | null = null;
    let productGroup = productGroups?.find(
      (group) => group.id === configuratorId
    );

    const getProductById = (id: string) => {
      return products.find((product) => product.id === id);
    };

    if (productGroup) {
      product = products?.find(
        (product) =>
          product.id ===
          (selectedVariant ? selectedVariant : productGroup.defaultOptionId)
      );
    } else {
      product = products?.find((product) => product.id === configuratorId);
    }

    return !!product ? product : null;
  },
});

export const ConfiguratorCategorySelector = selector<ICategoryV2[]>({
  key: "_ConfiguratorCategorySelector",
  get: ({ get }) => {
    const cmsData = get(ConfiguratorCMSSelector);
    const product = get(ConfiguratorProductSelector);
    const isProductGroup = get(IsProductGroup);
    if (!product) return [];
    return getCategories(isProductGroup, product.tags, cmsData);
  },
});

export const ConfiguratorActiveCategorySelector = selector<ICategoryV2 | null>({
  key: "_ConfiguratorActiveCategorySelector",
  get: ({ get }) => {
    const activeCategory = get(ConfiguratorActiveCategoryState);
    const categories = get(ConfiguratorCategorySelector);
    return (
      categories.find((category) => category.id === activeCategory) ?? null
    );
  },
});

export const ConfiguratorActiveFilterState = atom<string[]>({
  key: "_ConfiguratorActiveFilterState",
  default: [],
});

export const ConfiguratorDescriptionEditable = atom<boolean>({
  key: "_ConfiguratorDescriptionEditable",
  default: false,
});

export const ConfiguratorContinueButtonDisabledState = atom<boolean>({
  key: "_ConfiguratorContinueButtonDisabledState",
  default: false,
});

export const ConfiguratorShowBaseIngredientErrors = atom<boolean>({
  key: "_ConfiguratorShowBaseIngredientErrors",
  default: false,
});

export const ConfiguratorMissingComponentsState = atom<Map<string, boolean>>({
  key: "_ConfiguratorMissingComponentsState",
  default: new Map<string, boolean>(
    new Map([
      ["bases", false],
      ["standards", false],
      ["dressings", false],
    ])
  ),
});

export const ConfiguratorMissingComponentsV2State = atom<{
  [index: string]: boolean;
}>({
  key: "_ConfiguratorMissingComponentsStateV2",
  default: { dressings: false, standards: false },
});

export const ConfiguratorIngredientState = atom<string[]>({
  key: "_ConfiguratorIngredientState",
  default: [],
});

export const ConfiguratorIsOpenState = atom<boolean>({
  key: "_ConfiguratorIsOpenState",
  default: false,
});

export const ConfiguratorMainCartItemSelector =
  selector<InternalCartItem | null>({
    key: "_ConfiguratorMainCartItemSelector",
    get: ({ get }) => {
      const configuratorId = get(ConfiguratorIdState);
      const configuratorCart = get(ConfiguratorCartState);
      const productGroups = get(ProductGroupState);
      const selectedVariant = get(ConfiguratorSelectedVariantId);
      const products = get(StoreProductState);

      let productGroup = productGroups?.find(
        (group) => group.id === configuratorId
      );

      if (productGroup) {
        const getProductById = (id: string) => {
          return products.find((product) => product.id === id);
        };
        const configuratorCartItem = configuratorCart?.find(
          (product) =>
            product.id ===
            (selectedVariant ? selectedVariant : productGroup.defaultOptionId)
        );

        if (configuratorCartItem) {
          return configuratorCartItem;
        }
      } else {
        if (configuratorId) {
          const configuratorCartItem = configuratorCart.find(
            (item) => item.id === configuratorId
          );
          if (configuratorCartItem) {
            return configuratorCartItem;
          }
        }
      }
      return null;
    },
  });

export const ConfiguratorProductErrors = atom<ErrorType[]>({
  key: "_ConfiguratorProductErrors",
  default: [],
});

export const ConfiguratorShowDiscardWarning = selector<boolean>({
  key: "_ConfiguratorShowDiscardWarning",
  get: ({ get }) => {
    const initialAddons = get(ConfiguratorInitialAddons);
    const initialRemovedIngredients = get(
      ConfiguratorInitialRemovedIngredients
    );
    const removedIngredients = get(RemovedIngredientsSelector);
    const selectedAddons = get(ConfiguratorSelectedDisplayAddons);
    const selectedExtras = get(ConfiguratorSelectedDisplayExtras);
    if (initialAddons.length > 0) {
      const addonIds = selectedAddons.reduce((prev, cur) => {
        return [...prev, ...Array(cur.count).fill(cur.id)];
      }, [] as string[]);
      if (initialAddons.length !== addonIds.length) {
        return true;
      } else {
        const sortedInitialAddons = [...initialAddons].sort();
        const sortedAddonIds = [...addonIds].sort();
        for (let i = 0; i < sortedInitialAddons.length; i++) {
          if (sortedInitialAddons[i] !== sortedAddonIds[i]) {
            return true;
          }
        }
      }
    }
    if (initialRemovedIngredients.length > 0) {
      if (initialRemovedIngredients.length !== removedIngredients.length) {
        return true;
      } else {
        const sortedInitialRemovedIngredients = [
          ...initialRemovedIngredients,
        ].sort();
        const sortedRemovedIngredients = [...removedIngredients].sort();
        for (let i = 0; i < sortedInitialRemovedIngredients.length; i++) {
          if (
            sortedRemovedIngredients[i] !== sortedInitialRemovedIngredients[i]
          ) {
            return true;
          }
        }
      }
    }
    return selectedExtras.length > 0;
  },
});

export const ConfiguratorMinimumSuggestedItemsWarningState = atom<boolean>({
  key: "_ConfiguratorMinimumSuggestedItemsWarningState",
  default: false,
});

export const RequiredItemMap = selector<{ bases: number }>({
  key: "_RequiredItemMap",
  get: ({ get }) => {
    const storeProducts = get(StoreProductState);
    const selectedAddons = get(ConfiguratorSelectedDisplayAddons);
    const selectedBases = selectedAddons.reduce((prev, cur) => {
      const product = storeProducts.find((product) => product.id === cur.id);
      if (product) {
        return product.tags.includes("ingredient.base")
          ? prev + cur.count
          : prev;
      }
      return prev;
    }, 0);
    return {
      bases: selectedBases ?? 0,
    };
  },
});

export const ConfiguratorSuggestedIngredientCountsReached = selector<{
  standards: boolean;
  dressings: boolean;
}>({
  key: "_ConfiguratorSuggestedIngredientCountsReached",
  get: ({ get }) => {
    const storeProducts = get(StoreProductState);
    const selectedAddons = get(ConfiguratorSelectedDisplayAddons);
    const selectedStandards = selectedAddons.reduce((prev, cur) => {
      const product = storeProducts.find((product) => product.id === cur.id);
      if (product) {
        return product.tags.includes("ingredient.standard")
          ? prev + cur.count
          : prev;
      }
      return prev;
    }, 0);
    const selectedDressings = selectedAddons.reduce((prev, cur) => {
      const product = storeProducts.find((product) => product.id === cur.id);
      if (product) {
        return product.tags.includes("ingredient.dressing")
          ? prev + cur.count
          : prev;
      }
      return prev;
    }, 0);
    return {
      standards: selectedStandards >= 4,
      dressings: selectedDressings >= 1,
    };
  },
});

export const ConfiguratorWarningMessageSelector = selector<string>({
  key: "_ConfiguratorWarningMessageSelector",
  get: ({ get }) => {
    const missingComponents = get(ConfiguratorMissingComponentsV2State);
    return missingComponents.standards || missingComponents.dressings
      ? `Wir empfehlen mindestens${
          missingComponents.standards ? " **4 Standards**" : ""
        }${
          missingComponents.standards && missingComponents.dressings
            ? " und"
            : ""
        }${missingComponents.dressings ? " **1 Dressing**" : ""}`
      : "";
  },
});

export const ConfiguratorShowBaseIngredientError = atom<boolean>({
  key: "_ConfiguratorShowBaseIngredientError",
  default: false,
});

export const ShowIncompleteModal = atom<boolean>({
  key: "_ShowIncompleteModal",
  default: false,
});

export const ShowMissingDressingModal = atom<boolean>({
  key: "_ShowMissingDressingModal",
  default: false,
});

export const IsProductGroup = selector<boolean>({
  key: "_IsProductGroup",
  get: ({ get }) => {
    const productGroups = get(ProductGroupState);
    const configuratorId = get(ConfiguratorIdState);
    return !!productGroups.some((group) => group.id === configuratorId);
  },
});

export const IsCustomProduct = selector<boolean>({
  key: "_IsCustomProduct",
  get: ({ get }) => {
    const product = get(ConfiguratorProductSelector);
    return !!product && product.tags.includes("product.configurable");
  },
});
