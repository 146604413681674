import { getHeroBannerCMSData } from "@api/CMS/cms.api";
import { validateVoucherForCustomer } from "@api/Shop/shop.api";
import { useDeferredValue, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { HeroBannerProps } from "../../../pages/[[...city]]";
import {
  HeroBannerPropsInitiatedState,
  HeroBannerPropsState,
} from "../../state/Menu/Menu.state";
import { StoreState } from "../../state/Store/Store.state";
import { EmailState } from "../../state/User/User.state";
import { HeroProps } from "../../views/MenuViewPage/components/StoreInfoSelect/StoreInfoSelect.interfaces";
import {
  getVoucherSeenCookie,
  isBannerActive,
  setVoucherSeenCookie,
  showVoucher,
} from "./util";

export const ShiftCheckIntervalMS = 1000 * 45;

const useDynamicHeroProps = () => {
  const activeStore = useRecoilValue(StoreState);
  const deferredStore = useDeferredValue(activeStore);
  const userEmail = useRecoilValue(EmailState);
  const setDynamicHeroInitiated = useSetRecoilState(
    HeroBannerPropsInitiatedState
  );
  const setBannerProps = useSetRecoilState(HeroBannerPropsState);
  const [activeShift, setActiveShift] = useState<HeroBannerProps | null>(null);
  const [defaultBannerProps, setDefaultBannerProps] =
    useState<HeroProps | null>(null);
  const [currentMinutes, setCurrentMinutes] = useState(() =>
    new Date().getMinutes()
  );

  useEffect(() => {
    const shiftCheckInterval = setInterval(() => {
      const minutes = new Date().getMinutes();
      if (currentMinutes !== minutes) {
        setCurrentMinutes(minutes);
      }
    }, ShiftCheckIntervalMS);

    return () => clearInterval(shiftCheckInterval);
  }, [setCurrentMinutes]);
  //fetch cms data
  const heroBannerRequest = getHeroBannerCMSData({
    refreshWhenHidden: false,
    revalidateOnFocus: true,
    refreshInterval: 1000 * 60 * 15,
  });
  const voucherValidationReqeust = validateVoucherForCustomer(
    {
      voucherCode: activeShift?.heroVoucher?.voucherCode,
      email: userEmail,
      storeId: deferredStore?.id,
    },
    { revalidateOnReconnect: false }
  );

  const resetBannerProps = () => {
    setBannerProps(null);
    setDefaultBannerProps(null);
    setActiveShift(null);
    setDynamicHeroInitiated(true);
  };
  useEffect(() => {
    //wait for store
    if (activeStore && heroBannerRequest?.data) {
      //check if store has banner
      const bannerForStore = heroBannerRequest?.data?.data?.find(
        (storeBanner) => storeBanner?.store_id === activeStore.id
      );
      if (bannerForStore) {
        //check if banner time restriction matches
        const availableBanner =
          bannerForStore.heroBannerProps.find(isBannerActive);
        if (availableBanner?.active) {
          //set closest default banner props
          let _defaultBannerProps: HeroProps | null = null;
          if (bannerForStore?.defaultHeroProps?.active) {
            _defaultBannerProps = {
              heroLogo:
                bannerForStore?.defaultHeroProps.heroLogo.data.attributes.url,
              heroImage:
                bannerForStore?.defaultHeroProps.heroImage.data.attributes.url,
              heroImageMobile:
                bannerForStore?.defaultHeroProps.heroImageMobile?.data
                  ?.attributes?.url ??
                bannerForStore?.defaultHeroProps.heroImage.data.attributes.url,
              heroLogoMobile:
                bannerForStore?.defaultHeroProps.heroLogoMobile?.data
                  ?.attributes?.url ??
                bannerForStore?.defaultHeroProps.heroLogo.data.attributes.url,
              heroImageAltText:
                bannerForStore?.defaultHeroProps.heroImageAltText,
              heroLogoAltText: bannerForStore?.defaultHeroProps.heroLogoAltText,
            };
          }
          //check if banner has restrictions
          setDefaultBannerProps(_defaultBannerProps);
          setActiveShift(availableBanner);
        } else if (bannerForStore?.defaultHeroProps?.active) {
          // set banner props to store default banner props if active
          setBannerProps({
            heroLogo:
              bannerForStore.defaultHeroProps.heroLogo.data.attributes.url,
            heroImage:
              bannerForStore.defaultHeroProps.heroImage.data.attributes.url,
            heroLogoMobile:
              bannerForStore.defaultHeroProps.heroLogoMobile?.data?.attributes
                ?.url ??
              bannerForStore.defaultHeroProps.heroLogo.data.attributes.url,
            heroImageMobile:
              bannerForStore.defaultHeroProps.heroImageMobile?.data?.attributes
                ?.url ??
              bannerForStore.defaultHeroProps.heroImage.data.attributes.url,
            heroImageAltText: bannerForStore.defaultHeroProps.heroImageAltText,
            heroLogoAltText: bannerForStore.defaultHeroProps.heroLogoAltText,
          });
          setDynamicHeroInitiated(true);
        } else {
          resetBannerProps();
        }
      } else {
        resetBannerProps();
      }
    }
  }, [activeStore?.id, currentMinutes, heroBannerRequest?.data]);

  useEffect(() => {
    // check if restrictions are met
    if (activeShift) {
      if (activeShift?.restrictions?.length > 0) {
        let allNeededDataLoaded = true;
        const restrictionsMet: boolean[] = activeShift?.restrictions.map(
          (campaignRestriction) => {
            const { restriction } = campaignRestriction;
            if (restriction === "NEW_CUSTOMER_ONLY") {
              // check if user is new customer
              if (voucherValidationReqeust?.data) {
                return (
                  !voucherValidationReqeust?.data?.errorList ||
                  voucherValidationReqeust?.data.errorList.length === 0 ||
                  voucherValidationReqeust.data?.errorList?.findIndex(
                    (error) => error?.type === "userExists"
                  ) === -1
                );
              } else {
                allNeededDataLoaded = false;
              }
            }
            if (restriction === "VALID_FOR_SAME_DAY_ONLY") {
              const { resetCookieAfterDays } = campaignRestriction;
              // check if voucher was first seen today
              if (activeShift?.heroVoucher?.voucherCode) {
                const voucherSeenCookie = getVoucherSeenCookie(
                  activeShift.heroVoucher.voucherCode
                );
                if (!voucherSeenCookie) {
                  setVoucherSeenCookie(
                    activeShift.heroVoucher.voucherCode,
                    resetCookieAfterDays ?? 1
                  );
                  return true;
                } else {
                  return showVoucher(voucherSeenCookie);
                }
              }
              return true;
            }
            return false;
          }
        );
        if (restrictionsMet.every((met) => met)) {
          // if all restrictions are met set active shift banner
          setBannerProps({
            heroLogo: activeShift.heroProps.heroLogo.data.attributes.url,
            heroImage: activeShift.heroProps.heroImage.data.attributes.url,
            heroLogoMobile:
              activeShift.heroProps.heroLogoMobile?.data?.attributes?.url ??
              activeShift.heroProps.heroLogo.data.attributes.url,
            heroImageMobile:
              activeShift.heroProps.heroImageMobile?.data?.attributes?.url ??
              activeShift.heroProps.heroImage.data.attributes.url,
            heroImageAltText: activeShift.heroProps.heroImageAltText,
            heroLogoAltText: activeShift.heroProps.heroLogoAltText,
            ...(activeShift.heroVoucher
              ? {
                  voucher: {
                    voucherCode: activeShift?.heroVoucher?.voucherCode,
                    voucherDescription:
                      activeShift?.heroVoucher?.voucherDescription,
                    textColor: activeShift?.heroVoucher?.textColor,
                    backgroundColor: activeShift?.heroVoucher?.backgroundColor,
                  },
                }
              : {}),
          });
        } else if (allNeededDataLoaded) {
          // if all data to check restrictions is loaded and restrictions were not met, set default banner props
          setBannerProps(defaultBannerProps);
        }
      } else {
        // if no restrictions set active shift banner
        setBannerProps({
          heroLogo: activeShift.heroProps.heroLogo.data.attributes.url,
          heroImage: activeShift.heroProps.heroImage.data.attributes.url,
          heroLogoMobile:
            activeShift.heroProps.heroLogoMobile?.data?.attributes?.url ??
            activeShift.heroProps.heroLogo.data.attributes.url,
          heroImageMobile:
            activeShift.heroProps.heroImageMobile?.data?.attributes?.url ??
            activeShift.heroProps.heroImage.data.attributes.url,
          heroImageAltText: activeShift.heroProps.heroImageAltText,
          heroLogoAltText: activeShift.heroProps.heroLogoAltText,
          ...(activeShift.heroVoucher
            ? {
                voucher: {
                  voucherCode: activeShift?.heroVoucher?.voucherCode,
                  voucherDescription:
                    activeShift?.heroVoucher?.voucherDescription,
                  textColor: activeShift?.heroVoucher?.textColor,
                  backgroundColor: activeShift?.heroVoucher?.backgroundColor,
                },
              }
            : {}),
        });
      }
      setDynamicHeroInitiated(true);
    }
  }, [activeShift, defaultBannerProps, voucherValidationReqeust?.data]);
};

export default useDynamicHeroProps;
