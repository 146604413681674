import styled from "@emotion/styled";

export const _SupportIconWrapper = styled.div`
  position: relative;
  cursor: "pointer";
`;

export const _IconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;
