import styled from "@emotion/styled";
import { LibTheme } from "@stadtsalat/component-lib";

export const _DeliveryAreaModalWrapper = styled.div<{
  active: boolean;
  isFirstRender: boolean;
}>`
  position: fixed;
  pointer-events: ${({ active }) => (active ? "all" : "none")};
  inset: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  transform: translateY(${({ active }) => (active ? "0" : "100%")});
  gap: 0.5rem;
  background-color: #fffaf5;

  animation: ${({ active, isFirstRender }) =>
    isFirstRender ? "none" : active ? "slideUp 0.75s" : "slideDown 0.75s"};
  opacity: ${({ active }) => (active ? 1 : 0)};

  transition: transform 0.6s ease-in-out
    ${({ active }) => (!active ? ", opacity 0.6s 0.6s" : "")};

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  ${({ active }) =>
    !active &&
    `
    @keyframes slideDown {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100%);
    }
  }
  `};
  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    gap: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1300px;
    max-height: 950px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 20px #695f50;
    opacity: ${({ active }) => (active ? 1 : 0)};

    animation: ${({ active, isFirstRender }) =>
      isFirstRender ? "none" : active ? "blowUpModal 0.3s" : "blowDown 0.3s"};

    @keyframes blowUpModal {
      from {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
      }
      to {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
    }

    ${({ active }) =>
      active &&
      `
      @keyframes blowDown {
      from {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
      to {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
      }
    }
    `}
  }
`;

export const _ModalInnerWrapper = styled.div`
  padding: 3rem 1rem;
  width: 100%;
  height: auto;
  position: relative;
  max-height: 100%;
  overflow: scroll;
`;
