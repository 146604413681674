import { atom } from "recoil";

import {
  AccordionTimingStorageAdapter,
  ApplePayExperimentStorageAdapter,
  GooglePayExperimentStorageAdapter,
  MenuUserTypeStorageAdapter,
  MigrationFlagStorageAdapter,
} from "../../util/storage/util.storage";
import { CheckoutPaths } from "../../views/CheckoutPageView/CheckoutPageView.interfaces";
import { localStorageEffect } from "../effect";

export const MigrationFlagState = atom<boolean | null>({
  key: "_MigrationFlagState",
  default: false,
  effects: [
    localStorageEffect({
      get: MigrationFlagStorageAdapter.get,
      set: MigrationFlagStorageAdapter.set,
      delete: MigrationFlagStorageAdapter.delete,
    }),
  ],
});

export const AccordionTimingState = atom<{ [key in CheckoutPaths]?: string }>({
  key: "_AccordionTimingState",
  default: {},
  effects: [
    localStorageEffect({
      get: AccordionTimingStorageAdapter.get,
      set: AccordionTimingStorageAdapter.set,
      delete: AccordionTimingStorageAdapter.delete,
    }),
  ],
});
export const GooglePayExperimentState = atom<boolean>({
  key: "_GooglePayExperimentState",
  default: false,
  effects: [
    localStorageEffect({
      get: GooglePayExperimentStorageAdapter.get,
      set: GooglePayExperimentStorageAdapter.set,
      delete: GooglePayExperimentStorageAdapter.delete,
    }),
  ],
});

export const ApplePayExperimentState = atom<boolean>({
  key: "_ApplePayExperimentState",
  default: false,
  effects: [
    localStorageEffect({
      get: ApplePayExperimentStorageAdapter.get,
      set: ApplePayExperimentStorageAdapter.set,
      delete: ApplePayExperimentStorageAdapter.delete,
    }),
  ],
});

export const PageIsLoadingState = atom<boolean>({
  key: "_PageIsLoadingState",
  default: true,
});

export const PageIsInitialisedState = atom<boolean>({
  key: "_PageIsInitialisedState",
  default: false,
});

export const MenuUserType = atom<"NEW" | "LEGACY" | null>({
  key: "_MenuUserType",
  default: null,
  effects: [localStorageEffect(MenuUserTypeStorageAdapter)],
});
