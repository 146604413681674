import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      fill="none"
      fillRule="nonzero"
      d="M12 2c1.827 0 3.518.457 5.072 1.37a9.478 9.478 0 0 1 3.558 3.558A9.835 9.835 0 0 1 22 12a9.835 9.835 0 0 1-1.37 5.072 9.478 9.478 0 0 1-3.558 3.558A9.835 9.835 0 0 1 12 22a9.835 9.835 0 0 1-5.072-1.37 9.657 9.657 0 0 1-3.558-3.582A9.79 9.79 0 0 1 2 12a9.79 9.79 0 0 1 1.37-5.048A9.842 9.842 0 0 1 6.952 3.37 9.79 9.79 0 0 1 12 2Zm-.228 4.726c-.557 0-1.008.45-1.008 1.007v5.393l4.749 2.884.02.012a.976.976 0 0 0 1.334-.35.993.993 0 0 0-.35-1.355l-3.738-2.219V7.733c0-.556-.451-1.007-1.007-1.007Z"
      transform="matrix(25 0 0 25 -50 -50)"
    />
  </svg>
);
export default SvgComponent;
