import withExperiment from "@components/hocs/withExperiment";
import { FC, useCallback } from "react";
import { useSetRecoilState } from "recoil";

import {
  MenuBackgroundBlurActiveState,
  MenuDeliveryAreaModalOpenState,
} from "../../../../state/Menu";
import OpeningHours from "../OpeningHours";
import { DeliveryStoreInfosProps } from "./DeliveryStoreInfos.interfaces";
import {
  _BioLabel,
  _BioLabelWrapper,
  _BioLogo,
  _DeliveryAndOpeningInfoWrapper,
  _DeliveryAreaAndDietInfoWrapper,
  _DeliveryAreaButton,
  _DeliveryStoreHeadline,
  _DeliveryStoreInfosWrapper,
  _DietaryInfoLink,
} from "./DeliveryStoreInfos.styled";

const DeliveryStoreInfos: FC<DeliveryStoreInfosProps> = (props) => {
  const {
    storeHeadline,
    bioLabel,
    showDeliveryAreaLabel,
    dietaryInfoLabel,
    dietaryInfoUrl,
    openingHours,
    weekendLabel,
    weekdayLabel,
  } = props;

  const StoreHeadlineControl = ({
    storeHeadline,
  }: {
    storeHeadline: string;
  }) => (
    <_DeliveryStoreHeadline style={"m24"} variant={"semibold"}>
      {storeHeadline}
    </_DeliveryStoreHeadline>
  );
  const StoreHeadlineA = ({ storeHeadline }: { storeHeadline: string }) => (
    <_DeliveryStoreHeadline style={"m24"} variant={"semibold"}>
      {storeHeadline}
    </_DeliveryStoreHeadline>
  );
  const StoreHeadline = withExperiment("baseline_experiment", {
    control: StoreHeadlineControl,
    A: StoreHeadlineA,
  });

  const BioLabelControl = ({ bioLabel }: { bioLabel: string }) => (
    <_BioLabel style={"p12"} variant={"semibold"}>
      {bioLabel}
    </_BioLabel>
  );
  const BioLabelA = ({ bioLabel }: { bioLabel: string }) => (
    <_BioLabel style={"p12"} variant={"semibold"}>
      {bioLabel}
    </_BioLabel>
  );

  const BioLabel = withExperiment("ab_cms", {
    control: BioLabelControl,
    A: BioLabelA,
  });

  const setDeliveryAreaModalOpen = useSetRecoilState(
    MenuDeliveryAreaModalOpenState
  );
  const setBackgroundBlurActive = useSetRecoilState(
    MenuBackgroundBlurActiveState
  );

  const openDeliveryAreaModal = useCallback(() => {
    setDeliveryAreaModalOpen(true);
    setBackgroundBlurActive(true);
  }, [setDeliveryAreaModalOpen, setBackgroundBlurActive]);

  return (
    <_DeliveryStoreInfosWrapper>
      <_DeliveryAndOpeningInfoWrapper>
        <StoreHeadline storeHeadline={storeHeadline} />
        <OpeningHours
          openingHours={openingHours}
          weekdayLabel={weekdayLabel}
          weekendLabel={weekendLabel}
        />
        <_DeliveryAreaAndDietInfoWrapper>
          <_DeliveryAreaButton onClick={() => openDeliveryAreaModal()}>
            {showDeliveryAreaLabel}
          </_DeliveryAreaButton>
          <_DietaryInfoLink href={dietaryInfoUrl} target={"_blank"}>
            {dietaryInfoLabel}
          </_DietaryInfoLink>
        </_DeliveryAreaAndDietInfoWrapper>
      </_DeliveryAndOpeningInfoWrapper>
      <_BioLabelWrapper>
        <_BioLogo
          alt={"Bio Logo"}
          src={"/assetsV2/logos/bio.png"}
          width={52}
          height={52}
          priority={false}
        />
        <BioLabel bioLabel={bioLabel} />
      </_BioLabelWrapper>
    </_DeliveryStoreInfosWrapper>
  );
};

export default DeliveryStoreInfos;
