import { atom } from "recoil";

export const MenuActiveState = atom<boolean>({
  key: "_MenuActiveState",
  default: false,
});

export const UserActiveState = atom<boolean>({
  key: "_UserActiveState",
  default: false,
});

export const AccountMenuActiveState = atom<boolean>({
  key: "_AccountMenuActiveState",
  default: false,
});
