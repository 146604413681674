const BurgerIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      fill="#000"
      fillRule="nonzero"
      d="M27.778 444.444h444.444c15.278 0 27.778-12.5 27.778-27.777 0-15.278-12.5-27.778-27.778-27.778H27.778C12.5 388.889 0 401.389 0 416.667c0 15.277 12.5 27.777 27.778 27.777ZM0 250c0 15.278 12.5 27.778 27.778 27.778h444.444C487.5 277.778 500 265.278 500 250c0-15.278-12.5-27.778-27.778-27.778H27.778C12.5 222.222 0 234.722 0 250ZM0 83.333c0 15.278 12.5 27.778 27.778 27.778h444.444c15.278 0 27.778-12.5 27.778-27.778 0-15.277-12.5-27.777-27.778-27.777H27.778C12.5 55.556 0 68.056 0 83.333Z"
    />
  </svg>
);
export default BurgerIcon;
