"use client";

import { Typography } from "@stadtsalat/component-lib";
import { createRef, FC, useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import useBrowserInfo from "src/hooks/useBrowserInfo";

import useCMS from "../../../../hooks/useCMS";
import useFilter from "../../hooks/useFilter";
import { CMSDataMenu } from "../../MenuViewPage.interfaces";
import {
  MenuFilterOpenState,
  MenuScrollActiveCategory,
  StoreAlertAndVoucherBoxHeightSelector,
  StoreAlertBoxHeightState,
  StoreVoucherBoxHeightState,
} from "../../state/Menu";
import {
  ICategoryNavbarProps,
  MenuCategory,
} from "./CategoryNavbar.interfaces";
import {
  _Category,
  _CategoryLabel,
  _CategoryNavbarOutWrapper,
  _CategoryNavbarWrapper,
  _FilterBar,
  _FilterCountChip,
  _FilterIconInnerWrapper,
  _FilterIconWrapper,
  _FilterPill,
  _LayoutWrapper,
  _NavScrollbar,
  _PseudoCategoryLabel,
  _ScrollContainer,
} from "./CategoryNavbar.styled";
import FilterFilledIcon from "./FilterFilledIcon";

export const TestIds = {
  filterIcon: "filter-icon",
};
const CategoryNavbarDumb: FC<ICategoryNavbarProps> = (props) => {
  const {
    navbarRef,
    scrollRef,
    isSticky,
    categories,
    activeCategoryIndex,
    setActiveCategoryIndex,
    filterOpen,
    filters,
    toggleFilter,
    activeFilters,
    setFilterOpen,
    setManuallyScrolled,
    alertBoxHeight,
    isMobile,
  } = props;

  const scrollToCategory = useCallback(
    (index: number, manual: boolean = true) => {
      const category = categories[index];
      if (manual) {
        setManuallyScrolled(true);
      }

      setActiveCategoryIndex(index);
      const element = document.getElementById(category.id);
      if (element) {
        const top =
          element.getBoundingClientRect().top +
          window.pageYOffset -
          (filterOpen ? 140 : 100) -
          alertBoxHeight;
        isMobile
          ? window.scrollTo({ top: top })
          : window.scrollTo({ top: top, behavior: "smooth" });
      }
    },
    [
      categories,
      setManuallyScrolled,
      setActiveCategoryIndex,
      filterOpen,
      alertBoxHeight,
    ]
  );
  const toggleFilterAndScrollToIndex = useCallback(
    (tag: string) => {
      toggleFilter(tag);
      scrollToCategory(activeCategoryIndex, false);
    },
    [toggleFilter, scrollToCategory]
  );

  return (
    <_CategoryNavbarOutWrapper
      isSticky={isSticky}
      alertBoxHeight={alertBoxHeight}
    >
      <_CategoryNavbarWrapper ref={navbarRef} filterActive={filterOpen}>
        <_NavScrollbar>
          <_LayoutWrapper gap={"0.5rem"} active={true}>
            <_ScrollContainer
              gap={"0.5rem"}
              ref={scrollRef}
              isSticky={isSticky}
            >
              {categories?.map((category, index, src) => {
                const active = activeCategoryIndex === index;
                return (
                  <_Category
                    className={index === src.length - 1 ? "last" : ""}
                    ref={category.ref}
                    key={`category#${index}`}
                    isActive={active}
                    onClick={() => scrollToCategory(index)}
                  >
                    <_CategoryLabel
                      isActive={active}
                      style={active ? "p20" : "p16"}
                    >
                      {category.label}
                    </_CategoryLabel>
                    <_PseudoCategoryLabel style={"p20"}>
                      {category.label}
                    </_PseudoCategoryLabel>
                  </_Category>
                );
              })}
              {categories?.length > 0 && filters?.length > 0 ? (
                <_FilterIconWrapper
                  isActive={filterOpen || activeFilters.length > 0}
                  data-testid={TestIds.filterIcon}
                  onClick={() => setFilterOpen((_open) => !_open)}
                >
                  <_FilterIconInnerWrapper>
                    <FilterFilledIcon />
                    <_FilterCountChip isVisible={activeFilters.length > 0}>
                      <Typography style="m10" variant={"bold"}>
                        {activeFilters.length || 0}
                      </Typography>
                    </_FilterCountChip>
                  </_FilterIconInnerWrapper>
                </_FilterIconWrapper>
              ) : null}
            </_ScrollContainer>
          </_LayoutWrapper>
        </_NavScrollbar>
        <_NavScrollbar>
          <_LayoutWrapper gap={"0.75rem"} active={true}>
            <_FilterBar isFilterBarActive={filterOpen}>
              <_ScrollContainer gap={"0.5rem"} isSticky={false}>
                {filters.map((filter) => (
                  <_FilterPill
                    key={`filter-${filter.tag}`}
                    isActive={activeFilters.includes(filter.tag)}
                    onClick={() => toggleFilter(filter.tag)}
                  >
                    <Typography style={"p14"} variant={"light"}>
                      {filter.label}
                    </Typography>
                  </_FilterPill>
                ))}
              </_ScrollContainer>
            </_FilterBar>
          </_LayoutWrapper>
        </_NavScrollbar>
      </_CategoryNavbarWrapper>
    </_CategoryNavbarOutWrapper>
  );
};
export default function CategoryNavbar() {
  const { getCMSValueByKeyGenerator } = useCMS();

  const getProductOverviewCMSData = getCMSValueByKeyGenerator<
    CMSDataMenu,
    CMSDataMenu["productOverview"]
  >("productOverview");
  const [isSticky, setIsSticky] = useState(false);
  const navbarRef = useRef(null);
  const scrollRef = useRef(null);
  const { isMobile } = useBrowserInfo();
  const [filterOpen, setFilterOpen] = useRecoilState(MenuFilterOpenState);
  const [activeCategory, setActiveCategory] = useState(0);
  const { activeFilters, availableFilters, toggleFilter } = useFilter();
  const [manuallyScrolled, setManuallyScrolled] = useState(false);
  const alertBoxHeight = useRecoilValue(StoreAlertBoxHeightState);
  const voucherBoxHeight = useRecoilValue(StoreVoucherBoxHeightState);
  const topBoxHeight = useRecoilValue(StoreAlertAndVoucherBoxHeightSelector);
  const scrollActiveCategory = useRecoilValue(MenuScrollActiveCategory);
  const categories = getProductOverviewCMSData(
    "categories"
  ) as CMSDataMenu["productOverview"]["categories"];
  const categoriesWithRef: MenuCategory[] = categories?.map((category) => ({
    ...category,
    ref: createRef<HTMLDivElement>(),
  }));
  const checkStickiness = useCallback(() => {
    if (navbarRef.current) {
      const navbarTop = (
        navbarRef.current as HTMLElement
      ).getBoundingClientRect().top;
      setIsSticky(navbarTop - topBoxHeight <= 0);
    }
  }, [navbarRef, setIsSticky, topBoxHeight]);

  useEffect(() => {
    const activeCategoryIndex = categoriesWithRef?.findIndex(
      (c) => c.id === scrollActiveCategory
    );
    if (!manuallyScrolled && activeCategoryIndex !== activeCategory) {
      setActiveCategory(activeCategoryIndex);
    }
  }, [scrollActiveCategory]);

  let timeout = null;
  const handleManualScrollReset = () => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setManuallyScrolled(false);
    }, 75);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleManualScrollReset);
    return () => window.removeEventListener("scroll", handleManualScrollReset);
  }, [manuallyScrolled]);

  useEffect(() => {
    if (categoriesWithRef) {
      const scrollToElement = categoriesWithRef[activeCategory]?.ref?.current;
      const scrollContainerElement = scrollRef?.current;

      if (scrollToElement && scrollContainerElement?.scrollTo) {
        const scrollContainerWidth = scrollContainerElement.offsetWidth;
        const elementLeft = scrollToElement.offsetLeft;
        const elementWidth = scrollToElement.offsetWidth;

        const scrollPosition =
          elementLeft + elementWidth / 2 - scrollContainerWidth / 2;

        scrollContainerElement?.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }
    }
  }, [activeCategory]);

  useEffect(() => {
    window.addEventListener("scroll", checkStickiness);
    return () => window.removeEventListener("scroll", checkStickiness);
  }, []);

  return (
    <CategoryNavbarDumb
      toggleFilter={toggleFilter}
      activeCategoryIndex={activeCategory}
      setActiveCategoryIndex={setActiveCategory}
      isSticky={isSticky}
      filterOpen={filterOpen}
      setFilterOpen={setFilterOpen}
      navbarRef={navbarRef}
      scrollRef={scrollRef}
      categories={categoriesWithRef}
      filters={availableFilters}
      activeFilters={activeFilters}
      setManuallyScrolled={setManuallyScrolled}
      alertBoxHeight={topBoxHeight}
      isMobile={isMobile}
    />
  );
}
