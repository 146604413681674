import { FC } from "react";
import { useRecoilValue } from "recoil";

import useCMS from "../../../../hooks/useCMS";
import { StoreState } from "../../../../state/Store/Store.state";
import { DeliveryGroupState } from "../../../../state/User/User.state";
import { CMSDataMenu } from "../../MenuViewPage.interfaces";
import DeliveryStoreInfos from "./components/DeliveryStoreInfos";
import OnSiteStoreInfos from "./components/OnSiteStoreInfos";
import { OpeningHours } from "./components/OpeningHours";
import { StoreInfoSectionProps } from "./StoreInfoSection.interfaces";
import {
  _StoreInfoSectionWrapper,
  _StoreInfosInnerWrapper,
} from "./StoreInfoSection.styled";
import StoreInfo = Definitions.StoreInfo;

const generateStoreImageUrl = (storeImageId: string) =>
  `${process.env.NEXT_PUBLIC_IMG_BASE}/${storeImageId}`;

const getOpeningHoursByStore = (store?: StoreInfo): OpeningHours | null => {
  if (!store) return null;
  const weekendOpeningHours = store.nextBusinessDays.filter((bd) =>
    [0, 6].includes(new Date(bd.end).getDay())
  );
  const weekendOpeningTime = new Date(
    weekendOpeningHours[0].businessHours[0].openingTime
  );
  const weekendClosingTime = new Date(
    weekendOpeningHours.slice(-1)[0].businessHours[0].closingTime
  );

  const weekends = [
    `${weekendOpeningTime.getHours()}:${[weekendOpeningTime.getMinutes(), 0]
      .join("")
      .slice(0, 2)}`,
    `${weekendClosingTime.getHours()}:${[weekendClosingTime.getMinutes(), 0]
      .join("")
      .slice(0, 2)}`,
  ];

  const weekDayOpeningHours = store.nextBusinessDays.filter(
    (bd) => ![0, 6].includes(new Date(bd.end).getDay())
  );
  const weekDayOpeningTime = new Date(
    weekDayOpeningHours[0].businessHours[0].openingTime
  );
  const weekDayClosingTime = new Date(weekDayOpeningHours.slice(-1)[0].end);

  const weekdays = [
    `${weekDayOpeningTime.getHours()}:${[weekDayOpeningTime.getMinutes(), 0]
      .join("")
      .slice(0, 2)}`,
    `${weekDayClosingTime.getHours()}:${[weekDayClosingTime.getMinutes(), 0]
      .join("")
      .slice(0, 2)}`,
  ];

  return {
    weekdays,
    weekends,
  };
};
const StoreInfoSectionDumb: FC<StoreInfoSectionProps> = (props) => {
  const {
    deliveryStoreHeadline,
    showDeliveryAreaLabel,
    bioLabel,
    onSiteStoreHeadline,
    storeImageUrl,
    address,
    openingHours,
    menuDownloadUrl,
    weekdayLabel,
    weekendLabel,
    menuDownloadLabel,
    dietaryInfoLabel,
    dietaryInfoUrl,
    activeDeliveryGroup,
  } = props;
  return (
    <_StoreInfoSectionWrapper>
      <_StoreInfosInnerWrapper>
        <OnSiteStoreInfos
          storeImageUrl={storeImageUrl}
          storeHeadline={onSiteStoreHeadline}
          address={address}
          openingHours={openingHours.inStore}
          menuDownloadUrl={menuDownloadUrl}
          weekdayLabel={weekdayLabel}
          weekendLabel={weekendLabel}
          menuDownloadLabel={menuDownloadLabel}
          activeDeliveryGroup={activeDeliveryGroup}
        />
        <DeliveryStoreInfos
          storeHeadline={deliveryStoreHeadline}
          showDeliveryAreaLabel={showDeliveryAreaLabel}
          bioLabel={bioLabel}
          openingHours={openingHours.delivery}
          weekdayLabel={weekdayLabel}
          weekendLabel={weekendLabel}
          dietaryInfoLabel={dietaryInfoLabel}
          dietaryInfoUrl={dietaryInfoUrl}
        />
      </_StoreInfosInnerWrapper>
    </_StoreInfoSectionWrapper>
  );
};
const StoreInfoSection: FC = () => {
  const store = useRecoilValue(StoreState);
  const activeDeliveryGroup = useRecoilValue(DeliveryGroupState);
  const { getCMSValueByKeyGenerator } = useCMS();
  const getStoreBannerCMSData = getCMSValueByKeyGenerator<
    CMSDataMenu,
    CMSDataMenu["storeBanner"]
  >("storeBanner");

  return (
    <StoreInfoSectionDumb
      deliveryStoreHeadline={getStoreBannerCMSData("deliveryStoreHeadline")} //"Oder einfach liefern lassen!"}
      onSiteStoreHeadline={getStoreBannerCMSData("onSiteStoreHeadline")} //"Besuche uns in deiner Stadt."}
      showDeliveryAreaLabel={getStoreBannerCMSData("showDeliveryAreaLabel")} //"Liefergebiet anzeigen"}
      bioLabel={getStoreBannerCMSData("bioLabel")} //"Bio-Zertifizierung - Kontrollstelle: DE-ÖKO-006"}
      storeImageUrl={
        !!store ? generateStoreImageUrl(store?.images["storeTeaser"]) : ""
      }
      address={{
        streetAndHouseNo: store?.address.street,
        city: store?.address.city,
      }}
      openingHours={{
        delivery: getOpeningHoursByStore(activeDeliveryGroup?.deliveryStore),
        inStore: getOpeningHoursByStore(activeDeliveryGroup?.walkInStores[0]),
      }}
      menuDownloadUrl={getStoreBannerCMSData("menuDownloadUrl")}
      weekdayLabel={getStoreBannerCMSData("weekDayLabel")}
      weekendLabel={getStoreBannerCMSData("weekendLabel")}
      menuDownloadLabel={getStoreBannerCMSData("menuDownloadLabel")}
      dietaryInfoLabel={getStoreBannerCMSData("dietaryInfoLabel")}
      dietaryInfoUrl={getStoreBannerCMSData("dietaryInfoUrl")}
      activeDeliveryGroup={activeDeliveryGroup?.name}
    />
  );
};

export default StoreInfoSection;
