import { useRouter } from "next/router";
import {
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from "react";

import { IUseBrowserInfo } from "./useBrowserInfo.interfaces";

const useBrowserInfo = (): IUseBrowserInfo => {
  const { isReady } = useRouter();
  const timeout = useRef<NodeJS.Timeout>();
  const [userIsActive, setUserIsActive] = useState(true);

  const startTimer = useCallback(() => {
    timeout.current = setTimeout(() => {
      setUserIsActive(false);
    }, 1000 * 15);
  }, [timeout, setUserIsActive]);
  const resetTimer = useCallback(() => {
    clearTimeout(timeout.current);
    setUserIsActive(true);
    startTimer();
  }, [timeout, setUserIsActive, startTimer]);

  useEffect(() => {
    if (isReady) {
      document.addEventListener("mousemove", resetTimer, false);
      document.addEventListener("mousemove", resetTimer, false);
      document.addEventListener("mousedown", resetTimer, false);
      document.addEventListener("keypress", resetTimer, false);
      document.addEventListener("DOMMouseScroll", resetTimer, false);
      document.addEventListener("mousewheel", resetTimer, false);
      document.addEventListener("touchmove", resetTimer, false);
      document.addEventListener("MSPointerMove", resetTimer, false);
      window.addEventListener("focus", resetTimer, false);
      startTimer();

      return () => {
        document.removeEventListener("mousemove", resetTimer);
        document.removeEventListener("mousedown", resetTimer);
        document.removeEventListener("keypress", resetTimer);
        document.removeEventListener("DOMMouseScroll", resetTimer);
        document.removeEventListener("mousewheel", resetTimer);
        document.removeEventListener("touchmove", resetTimer);
        document.removeEventListener("MSPointerMove", resetTimer);
        window.removeEventListener("focus", resetTimer);
        clearTimeout(timeout.current);
      };
    }
  }, [isReady]);

  const [isMobile, setIsMobile] = useState(false);
  const deferredIsMobile = useDeferredValue(isMobile);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => {
        const _isMobile = window.innerWidth < 768;
        if (_isMobile !== deferredIsMobile) {
          setIsMobile(_isMobile);
        }
      };
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [isReady, deferredIsMobile]);
  return {
    userIsActive,
    isMobile,
  };
};

export default useBrowserInfo;
