import * as React from "react";

const RecipeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      fill="none"
      fillRule="nonzero"
      d="M205.186-178.043c-11.866.085-23.604 2.262-34.745 6.36-30.519-61.337-105.002-86.35-166.339-55.874-24.245 12.037-43.837 31.672-55.873 55.874-11.141-4.098-22.879-6.275-34.745-6.36-57.538 0-104.149 46.611-104.149 104.149 0 57.538 46.611 104.191 104.149 104.191V91.25h68.593V-11.362c0-10.116 8.195-18.312 18.311-18.312s18.269 8.196 18.269 18.312V91.25H99.97V-11.362c0-10.116 8.195-18.312 18.311-18.312 10.117 0 18.269 8.196 18.269 18.312V91.25h68.636V30.297c57.495 0 104.149-46.653 104.149-104.191 0-57.538-46.654-104.149-104.149-104.149M-86.516 127.83v34.062c0 7.854 6.36 14.214 14.214 14.214H190.93c7.853 0 14.256-6.36 14.256-14.214V127.83H-86.516Z"
      transform="translate(190.666 282.216)"
    />
  </svg>
);
export default RecipeIcon;
