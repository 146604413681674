"use client";

import { Typography } from "@stadtsalat/component-lib";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { FC, useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import useSeasonColor from "src/hooks/useSeasonColor";
import {
  getStoreDeliveryTime,
  isInStoreOpeningTime,
} from "src/util/store.util";

import { UserLocationMatchedState } from "../../../../../../state/Menu/Menu.state";
import {
  DeliveryTypeState,
  EstimatedDeliveryDurationState,
} from "../../../../../../state/Order/Order.state";
import { StoreState } from "../../../../../../state/Store/Store.state";
import {
  AvailableDeliveryGroupsState,
  DeliveryGroupIdState,
  DeliveryGroupState,
} from "../../../../../../state/User/User.state";
import { RouteMap } from "../../../../../../util/router";
import { OnlineDeliveryType } from "../../../../../../util/storage/order.storage";
import {
  getDaysLabelWithDDMM,
  getHHMMTimeString,
} from "../../../../../../util/time";
import { trackEvent } from "../../../../../../util/tracking.util";
import { LoadingInfoDropDown } from "../InfoFormDropdown";
import {
  DeliveryTypeToLabelMap,
  StoreInfoFormDumbProps,
  StoreInfoFormProps,
} from "./StoreInfoForm.interfaces";
import {
  _InfoFormDropdownWrapper,
  _InfoFormWrapper,
  _LiveStatusBubble,
  _MarginWrapper,
  _OrderButton,
  _StoreDeliveryTime,
} from "./StoreInfoForm.styled";

const STATIC_OPENING_OFFSET_MS = 1000 * 60 * 30;

const InfoFormDropdown = dynamic(() => import("../InfoFormDropdown"), {
  ssr: false,
  loading: () => <LoadingInfoDropDown />,
});

const StoreInfoForm: FC<StoreInfoFormProps> = (props) => {
  const {
    isFirstTimeUser,
    onClickOrder,
    toMenuLabel,
    storeDropdownLabel,
    deliveryDropdownLabel,
  } = props;

  const { replace, isReady } = useRouter();

  const { primary } = useSeasonColor();

  const setActiveDeliveryGroupId = useSetRecoilState(DeliveryGroupIdState);
  const [availableDeliveryGroups, _setAvailableDeliveryGroups] = useRecoilState(
    AvailableDeliveryGroupsState
  );
  const [deliveryType, setDeliveryType] = useRecoilState(DeliveryTypeState);
  const [userLocationMatched, setUserLocationMatched] = useRecoilState(
    UserLocationMatchedState
  );
  const activeDeliveryGroup = useRecoilValue(DeliveryGroupState);

  const estimatedDeliveryDuration = useRecoilValue(
    EstimatedDeliveryDurationState
  );
  const store = useRecoilValue(StoreState);

  const handleDeliveryTypeChange = useCallback(
    (deliveryType: OnlineDeliveryType) => {
      setDeliveryType(deliveryType);
    },
    []
  );

  const handleDeliverygroupChange = useCallback(
    (deliveryGroupId: string) => {
      setActiveDeliveryGroupId(deliveryGroupId);
      replace(RouteMap.MENU(deliveryGroupId), undefined, {
        shallow: true,
      });
      if (
        userLocationMatched &&
        userLocationMatched !== "null" &&
        Date.now() - new Date(userLocationMatched).getTime() <=
          1000 * 60 * 60 * 24
      ) {
        trackEvent({
          event: "location_corrected",
          data: {
            location_corrected_from: activeDeliveryGroup?.id,
            location_corrected_to: store,
          },
        });
      }
    },
    [setUserLocationMatched, userLocationMatched, activeDeliveryGroup]
  );

  const storeInfoComponent = useMemo(() => {
    return !!store && isReady ? (
      <>
        <_LiveStatusBubble live={isInStoreOpeningTime(store)} />
        <_StoreDeliveryTime style={"p10"}>
          {isInStoreOpeningTime(store) ? (
            <>
              in {getStoreDeliveryTime(estimatedDeliveryDuration, store)}{" "}
              Minuten
            </>
          ) : (
            <>
              Lieferung ab{" "}
              {getDaysLabelWithDDMM(
                new Date(store?.nextBusinessDays[0].end),
                "long",
                false
              )}{" "}
              {getHHMMTimeString(
                new Date(
                  new Date(
                    store?.nextBusinessDays[0]?.businessHours[0]?.openingTime
                  ).getTime() + STATIC_OPENING_OFFSET_MS
                ).toUTCString()
              )}{" "}
              Uhr
            </>
          )}
        </_StoreDeliveryTime>
      </>
    ) : null;
  }, [store, isReady]);

  return (
    <_StoreInfoFormDumb
      activeDeliveryGroup={activeDeliveryGroup}
      availableDeliveryGroups={availableDeliveryGroups}
      deliveryDropdownLabel={deliveryDropdownLabel}
      deliveryType={deliveryType}
      isFirstTimeUser={isFirstTimeUser}
      onChangeDeliveryType={handleDeliveryTypeChange}
      onChangeStore={handleDeliverygroupChange}
      onClickOrder={() => {
        isFirstTimeUser && onClickOrder();
      }}
      orderLabel={toMenuLabel}
      storeDropdownLabel={storeDropdownLabel}
      storeInfoComponent={storeInfoComponent}
      dataTestid="store-info-select"
      seasonalColor={primary.background}
      seasonalColorText={primary.text}
    />
  );
};

export const _StoreInfoFormDumb: FC<StoreInfoFormDumbProps> = (props) => {
  const {
    activeDeliveryGroup,
    availableDeliveryGroups,
    deliveryDropdownLabel,
    deliveryType,
    isFirstTimeUser,
    onChangeDeliveryType,
    onChangeStore,
    onClickOrder,
    orderLabel,
    storeDropdownLabel,
    storeInfoComponent,
    dataTestid,
    seasonalColor,
    seasonalColorText,
  } = props;
  return (
    <_MarginWrapper isFirstTimeUser={isFirstTimeUser} data-testid={dataTestid}>
      <_InfoFormWrapper isFirstTimeUser={isFirstTimeUser}>
        <_InfoFormDropdownWrapper isButtonVisible={isFirstTimeUser}>
          <InfoFormDropdown
            label={deliveryDropdownLabel}
            onChange={onChangeDeliveryType}
            selectedOption={deliveryType}
            options={[
              {
                label:
                  DeliveryTypeToLabelMap[OnlineDeliveryType.ONLINE_DELIVERY],
                value: OnlineDeliveryType.ONLINE_DELIVERY,
              },
              {
                label:
                  DeliveryTypeToLabelMap[OnlineDeliveryType.ONLINE_TAKEOUT],
                value: OnlineDeliveryType.ONLINE_TAKEOUT,
              },
              {
                label: DeliveryTypeToLabelMap[OnlineDeliveryType.ONLINE_EATIN],
                value: OnlineDeliveryType.ONLINE_EATIN,
              },
            ]}
          >
            {storeInfoComponent}
          </InfoFormDropdown>
          <InfoFormDropdown
            label={storeDropdownLabel}
            selectedOption={activeDeliveryGroup?.id}
            options={availableDeliveryGroups.map((dg) => ({
              label: dg.name,
              value: dg.id,
            }))}
            onChange={onChangeStore}
            hideMargin={!isFirstTimeUser}
          />
          <_OrderButton
            showButton={isFirstTimeUser}
            onClick={onClickOrder}
            data-testid="order-button"
            color={seasonalColor}
            colorText={seasonalColorText}
          >
            <Typography style="m16" variant={"bold"}>
              {orderLabel}
            </Typography>
          </_OrderButton>
        </_InfoFormDropdownWrapper>
      </_InfoFormWrapper>
    </_MarginWrapper>
  );
};

export default StoreInfoForm;
