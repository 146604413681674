import { PublicAPIFetcher } from "../fetcher";
import ProductGroup = Definitions.ProductGroup;

type ProductWithIncludes = {
  id: string;
  available: boolean;
  includedProductIds: string[];
  tags: string[];
};

export const getProductsWithIncludes = (): Promise<ProductWithIncludes[]> => {
  return PublicAPIFetcher("/product/public?include_shadow_products=true");
};

export const getProductGroups = async (): Promise<ProductGroup[]> => {
  const response = await PublicAPIFetcher<{ payload: ProductGroup[] }>(
    "/product/productGroup"
  );
  return response.payload;
};
