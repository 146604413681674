import { getProductGroups } from "@api/Product/product.api";
import withExperiment from "@components/hocs/withExperiment";
import { NextRouter, useRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";
import { FC, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getUserLocationByIP } from "src/api/Geo/geo.api";
import useProducts from "src/hooks/useProducts/useProducts";
import { ProductGroupState } from "src/state/Products";
import { isPointInsideDeliveryArea } from "src/util/addressBook";
import { RouteMap } from "src/util/router";
import { getDeliveryGroupIdByCity } from "src/views/CheckoutPageView/hooks/useMigration/useMigration";

import {
  getDeliveryGroupById,
  getStoreById,
} from "../../../../api/Shop/shop.api";
import useCart from "../../../../hooks/useCart";
import useCMS from "../../../../hooks/useCMS";
import useDynamicHeroProps from "../../../../hooks/useDynamicHeroProps/useDynamicHeroProps";
import {
  FirstTimeUserScreenVisitedState,
  FirstTimeUserState,
  UserLocationMatchedState,
} from "../../../../state/Menu/Menu.state";
import { DeliveryTypeState } from "../../../../state/Order/Order.state";
import { StoreState } from "../../../../state/Store/Store.state";
import {
  AvailableDeliveryGroupsState,
  DeliveryGroupIdState,
  DeliveryGroupState,
} from "../../../../state/User/User.state";
import { PageIsLoadingState } from "../../../../state/Util";
import { OnlineDeliveryType } from "../../../../util/storage/order.storage";
import { DeliveryGroupStorageAdapter } from "../../../../util/storage/user.storage";
import { trackEvent } from "../../../../util/tracking.util";
import { ConfiguratorIdState } from "../../hooks/useConfigurator/useConfigurator.state";
import { AvailableFilterState } from "../../state/Menu";
import { StoreShiftType } from "../StoreInfoSelect/StoreInfoSelect.interfaces";
import { MenuStateWrapperProps } from "./MenuStateWrapper.interfaces";
import { _MenuStateWrapperWrapper } from "./MenuStateWrapper.styled";
import DeliveryGroupInfo = Definitions.DeliveryGroupInfo;
import ProductGroup = Definitions.ProductGroup;

const getFilteredQuery = (query: ParsedUrlQueryInput, pathName: string) => {
  let filteredQuery: ParsedUrlQueryInput = {};
  Object.keys(query)
    .filter((key) => pathName.indexOf(pathName) === -1)
    .forEach((key) => {
      filteredQuery[key] = query[key];
    });
  return filteredQuery;
};

export const getCurrentShift = () => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  return hours < 19 || (hours === 19 && minutes <= 30)
    ? StoreShiftType.LUNCH
    : StoreShiftType.DINNER;
};
const getActiveStoreIdByDeliveryType = (
  deliveryType: OnlineDeliveryType,
  activeDeliveryGroup?: DeliveryGroupInfo
) => {
  if (!activeDeliveryGroup) return;
  return [
    OnlineDeliveryType.ONLINE_EATIN,
    OnlineDeliveryType.ONLINE_TAKEOUT,
  ].includes(deliveryType)
    ? activeDeliveryGroup?.walkInStores[0]?.id
    : activeDeliveryGroup?.deliveryStore?.id;
};
const MenuStateWrapper: FC<
  MenuStateWrapperProps & { variant: "control" | "A" }
> = (props) => {
  const {
    activeDeliveryGroupId,
    preFetchedAvailableDeliveryGroups,
    pathDeliveryGroupId,
    variant,
  } = props;
  const { initializeCart } = useCart();
  const { cmsData } = useCMS();
  const router = useRouter();
  useDynamicHeroProps();
  const { isReady, replace, query } = router;
  const setIsLoading = useSetRecoilState(PageIsLoadingState);
  const setIsFirstTimeUser = useSetRecoilState(FirstTimeUserState);
  const setAvailableFilter = useSetRecoilState(AvailableFilterState);
  const deliveryType = useRecoilValue(DeliveryTypeState);
  const persistedDeliveryGroupId = DeliveryGroupStorageAdapter.get();
  const firstTimeUserScreenVisited = useRecoilValue(
    FirstTimeUserScreenVisitedState
  );
  const setUserLocationMatched = useSetRecoilState(UserLocationMatchedState);
  const [availableDeliveryGroups, setAvailableDeliveryGroups] = useRecoilState(
    AvailableDeliveryGroupsState
  );
  const setConfiguratorId = useSetRecoilState(ConfiguratorIdState);

  const [store, setStore] = useRecoilState(StoreState);
  const [productGroups, setProductGroups] = useRecoilState(ProductGroupState);
  const [deliveryGroupId, setDeliveryGroupId] =
    useRecoilState(DeliveryGroupIdState);
  const [activeDeliveryGroup, setActiveDeliveryGroup] =
    useRecoilState(DeliveryGroupState);
  const { products, setProducts } = useProducts();

  const storeRequest = getStoreById(
    getActiveStoreIdByDeliveryType(deliveryType, activeDeliveryGroup),
    {
      revalidateOnReconnect: true,
      revalidateIfStale: true,
      refreshInterval: 1000 * 60,
    }
  );
  const deliveryGroupRequest = getDeliveryGroupById(deliveryGroupId);

  const getNewUrl = (router: NextRouter) => {
    const queryMap: Record<string, string> = Object.fromEntries(
      Object.keys(router.query)
        .filter((query) => query !== "city")
        .map((query) => {
          if (Array.isArray(router.query)) {
            return [query, [...router.query[query]].join(",")];
          } else {
            return [query, router.query[query]];
          }
        })
        .filter((query) => !!query)
    );
    const query = new URLSearchParams(queryMap);
    return `${router.pathname}${
      !!query.toString() ? `?${query.toString()}` : ""
    }`;
  };
  useEffect(() => {
    if (deliveryGroupRequest?.data) {
      setActiveDeliveryGroup(deliveryGroupRequest.data);
    }
  }, [deliveryGroupRequest?.data]);

  useEffect(() => {
    if (pathDeliveryGroupId && preFetchedAvailableDeliveryGroups && isReady) {
      const activeDeliveryGroup = preFetchedAvailableDeliveryGroups.find(
        (group) => group.id === pathDeliveryGroupId
      );
      if (activeDeliveryGroup) {
        router.pathname = RouteMap.MENU(pathDeliveryGroupId);
        const newUrl = getNewUrl(router);
        replace(newUrl, undefined, {
          shallow: false,
        });
      }
    }
  }, [pathDeliveryGroupId]);

  useEffect(() => {
    if (isReady) {
      //set initial delivery group state
      if (pathDeliveryGroupId) {
        setDeliveryGroupId(pathDeliveryGroupId);
      }
      setAvailableDeliveryGroups(preFetchedAvailableDeliveryGroups);
    }
  }, [isReady, pathDeliveryGroupId, preFetchedAvailableDeliveryGroups]);

  useEffect(() => {
    if (storeRequest?.data) {
      setStore(storeRequest.data);
    }
  }, [storeRequest?.data]);

  useEffect(() => {
    const getDefaultVariantId = (group: ProductGroup): string => {
      if (!group) {
        return "";
      }

      const defaultProduct = products.find(
        (product) => product.id === group.defaultOptionId
      );
      if (
        defaultProduct &&
        !defaultProduct.permanentlyUnavailable &&
        defaultProduct.available
      ) {
        return group.defaultOptionId;
      }

      let mostExpensiveProduct;
      for (const item of group.items) {
        const product = products.find((product) => product.id === item.id);
        if (!product || product.permanentlyUnavailable || !product.available)
          continue;

        if (
          !mostExpensiveProduct ||
          product.price?.withVat > mostExpensiveProduct.price?.withVat
        ) {
          mostExpensiveProduct = product;
        }
      }
      return mostExpensiveProduct?.id || group.items[0]?.id;
    };

    const fetchProductGroups = async () => {
      variant === "A"
        ? getProductGroups().then((data) => {
            setProductGroups(
              data.map((group) => {
                return {
                  ...group,
                  defaultOptionId: getDefaultVariantId(group),
                  items: group.items
                    .map((item) => {
                      const product = products.find(
                        (product) => product.id === item.id
                      );
                      return {
                        ...item,
                        price: product?.price,
                        image: product?.image,
                        productName: product?.name,
                        available: product?.available,
                        permanentlyUnavailable: product?.permanentlyUnavailable,
                        tags: product?.tags,
                        dietaryTags: product?.dietaryInfo.tags,
                      };
                    })
                    .filter((item) => !!item.variantId && !!item.productName),
                };
              })
            );
          })
        : setProductGroups([]);
    };

    products.length && fetchProductGroups();
  }, [products]);

  useEffect(() => {
    if (
      isReady &&
      storeRequest.data &&
      !!deliveryGroupRequest.data &&
      (!store ||
        store.id !== storeRequest.data.id ||
        activeDeliveryGroup?.id !== deliveryGroupRequest?.data?.id)
    ) {
      setProducts(storeRequest.data.products);
    }
  }, [isReady, storeRequest.data, deliveryGroupRequest.data, store]);

  useEffect(() => {
    if (isReady && products?.length > 0) {
      initializeCart()
        .then()
        .catch((err) => {});
    }
  }, [isReady, products]);

  useEffect(() => {
    const queryAddons: string | ParsedUrlQueryInput = Array.isArray(
      query.addons
    )
      ? query.addons.join(",")
      : query.addons;
    const bowlId = Array.isArray(query.bowl)
      ? query.bowl.join(",")
      : query.bowl;

    if (isReady) {
      if (
        !pathDeliveryGroupId &&
        (persistedDeliveryGroupId || firstTimeUserScreenVisited)
      ) {
        setIsFirstTimeUser(false);
      } else if (
        pathDeliveryGroupId &&
        !firstTimeUserScreenVisited &&
        persistedDeliveryGroupId !== pathDeliveryGroupId
      ) {
        setIsFirstTimeUser(true);
      }
    }
    if (isReady && !pathDeliveryGroupId && availableDeliveryGroups) {
      // check for persisted delivery group in local storage
      if (persistedDeliveryGroupId) {
        router.pathname = RouteMap.MENU(persistedDeliveryGroupId);
        const newUrl = getNewUrl(router);
        replace(newUrl, undefined, {
          shallow: true,
        });
      } else {
        // fetch IP location and match with delivery group and redirect to that delivery group
        getUserLocationByIP().then((userLocation) => {
          if (userLocation) {
            // try to match delivery group by city
            const deliveryGroupIdByCity = getDeliveryGroupIdByCity(
              userLocation.city
            );
            if (deliveryGroupIdByCity !== "") {
              setUserLocationMatched(new Date().toUTCString());
              trackEvent({
                event: "location_matched",
                data: {
                  location_matched_by: "city",
                },
              });
              router.pathname = RouteMap.MENU(deliveryGroupIdByCity);
              const newUrl = getNewUrl(router);
              return replace(newUrl, undefined, {
                shallow: true,
              });
            } else {
              // if no city matches try to find delivery group by geo coordinates
              const deliveryGroupByGeo = preFetchedAvailableDeliveryGroups.find(
                (deliveryGroup) =>
                  isPointInsideDeliveryArea(
                    userLocation.position,
                    deliveryGroup.deliveryArea
                  )
              );
              if (deliveryGroupByGeo) {
                setUserLocationMatched(new Date().toUTCString());
                trackEvent({
                  event: "location_matched",
                  data: {
                    location_matched_by: "geo",
                  },
                });
                router.pathname = RouteMap.MENU(deliveryGroupByGeo.id);
                const newUrl = getNewUrl(router);
                return replace(newUrl, undefined, { shallow: false });
              }
            }
          }
          // if no IP location matches with delivery group, redirect to default store
          router.pathname = RouteMap.MENU(
            preFetchedAvailableDeliveryGroups.find(
              (deliveryGroup) => activeDeliveryGroupId == deliveryGroup.id
            ).id
          );
          const newUrl = getNewUrl(router);
          replace(newUrl, undefined, { shallow: true });
        });
      }
    }
    if (bowlId) {
      setConfiguratorId(bowlId);
    }
  }, [isReady, preFetchedAvailableDeliveryGroups, pathDeliveryGroupId]);

  useEffect(() => {
    if (pathDeliveryGroupId && store && activeDeliveryGroup) {
      setIsLoading(false);
      trackEvent({
        event: "menu_page_view",
        data: {
          version: process.env.NEXT_PUBLIC_APP_VERSION,
        },
      });
    }
  }, [pathDeliveryGroupId, store, activeDeliveryGroup]);

  useEffect(() => {
    if (cmsData?.productFilters?.filters) {
      setAvailableFilter(cmsData.productFilters.filters);
    }
  }, [cmsData]);

  return <_MenuStateWrapperWrapper />;
};

export default withExperiment<MenuStateWrapperProps>("ab_protein_switch", {
  control: (props: MenuStateWrapperProps) => (
    <MenuStateWrapper {...props} variant={"control"} />
  ),
  PROTEIN_A: (props: MenuStateWrapperProps) => (
    <MenuStateWrapper {...props} variant={"A"} />
  ),
});
// export default MenuStateWrapper;
