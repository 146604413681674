import { getExperimentVariant } from "@util/abtest";
import { trackEvent } from "@util/tracking.util";
import { ComponentType } from "react";

class ABSingularTracking {
  private eventMap: Record<string, any>;

  constructor() {
    this.eventMap = {};
  }

  trackEvent = (experimentName: string, variant?: string) => {
    if (!this.eventMap[experimentName]) {
      this.eventMap[experimentName] = variant;
      trackEvent({
        event: "ab_test_experiment",
        data: {
          ab_experiment_name: experimentName,
          ab_experiment_variant: variant ?? "control",
        },
      });
    }
  };
}

const singularTracking = new ABSingularTracking();
const withExperiment = <T,>(
  experimentName: string,
  variants: { control: ComponentType } & Record<string, ComponentType<T>>
): ComponentType<T> => {
  return (props) => {
    if (typeof window !== "undefined") {
      const assignedVariant = getExperimentVariant(experimentName);
      if (assignedVariant) {
        singularTracking.trackEvent(experimentName, assignedVariant?.variant);
        const Variant = variants[assignedVariant.variant] ?? variants.control;
        return <Variant {...props} />;
      } else {
        //testing only - remove once we are sure that the tracking is working
        singularTracking.trackEvent(
          "ab_test_baseline_experiment_diff",
          "CATCH_ALL"
        );
      }
    }
    const DefaultVariant = variants.control;
    return <DefaultVariant {...props} />;
  };
};

export default withExperiment;
