import styled from "@emotion/styled";
import { LibTheme } from "@stadtsalat/component-lib";

export const _InfoViewWrapper = styled.div`
  background-color: #f8f0e5;
  position: relative;
  display: flex;
  gap: 0.5rem;

  @media (min-width: ${LibTheme.mediaBreakpoints
      .tablet}) or (max-height: 530px) {
    width: 440px;
    max-width: 50%;
    flex-direction: column;
    align-items: center;
    height: 100%;
    z-index: 20;
    border-left: 1px solid #f0e8dc;
  }

  @media (min-width: ${LibTheme.mediaBreakpoints.desktop}) {
    max-width: auto;
    width: 35%;
  }
`;

export const _CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #695f50;
  border-radius: 50%;
  padding: 0.25rem;
  z-index: 101;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    path {
      fill: #695f50;
    }
  }
  transform: scale(1);
  transition: background-color 0.2s, transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: ${LibTheme.mediaBreakpoints
      .tablet}) or (max-height: 530px) {
    top: 20px;
    right: 20px;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

export const _BowlBodyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;
  gap: 0.4rem;
  flex: 1;

  @media (min-width: ${LibTheme.mediaBreakpoints
      .tablet}) or (max-height: 530px) {
    width: 100%;
    gap: 0;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0 1rem 1.5rem 1rem;
  }

  @media (min-width: 55rem) {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }

  @media (min-width: ${LibTheme.mediaBreakpoints.desktop}) {
    /* padding: 0 2.5rem 1.5rem 2.5rem; */
  }
`;

export const _Tooltip = styled.div<{ transformX?: number }>`
  opacity: 0;
  transform: translateY(-10%)
    translateX(${({ transformX }) => (transformX ? transformX : -50)}%);
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 0.5rem;
  padding: 0.25rem;
  background-color: #574b3c;
  border-radius: 0.25rem;
  z-index: 100;
  pointer-events: none;
  user-select: none;
  transition: opacity 0.2s, transform 0.2s;
  box-shadow: 2px 2px 5px 1px rgba(40, 25, 10, 0.5);

  p {
    color: #ebe6dc;
    text-align: center;
    font-size: 0.8rem !important;
    font-weight: normal;
  }

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    border-bottom: 0.25rem solid #574b3c;
    top: -0.25rem;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const _BowlButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  padding: 1rem;
  background-color: #f8f0e5;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);

  .separator {
    display: none;
  }

  @media (min-width: ${LibTheme.mediaBreakpoints
      .tablet}) or (max-height: 530px) {
    box-shadow: none;
    padding: 0;
    z-index: 10;
    position: relative;
    justify-content: center;
    align-items: center;

    .separator {
      display: block;
    }
  }
`;

export const _SeparatorWrapper = styled.div<{ isOnlyMobile?: boolean }>`
  width: 100%;
  z-index: 0;
  hr {
    margin: 0;
    &::after {
      color: #c3b9aa;
    }
  }
  @media (min-width: ${LibTheme.mediaBreakpoints
      .tablet}) or (max-height: 530px) {
    display: ${({ isOnlyMobile }) => (isOnlyMobile ? "none" : "block")};
  }
`;

export const _AddToCartButton = styled.div<{ isCustom?: boolean }>`
  background-color: #f8f0e5;
  border-radius: 20px;
  cursor: pointer;
  p {
    color: ${LibTheme.colors.primary};
    transition: color 0.2s;
    text-align: center;
  }
  &:hover {
    p {
      color: #2cace3;
    }
  }
  @media (min-width: ${LibTheme.mediaBreakpoints
      .tablet}) or (max-height: 530px) {
    display: ${({ isCustom }) => (isCustom ? "none" : "block")};
  }
`;

export const _ContinueButton = styled.div<{ isDisabled?: boolean }>`
  user-select: none;
  padding: 0.75rem;
  background-color: ${({ isDisabled }) =>
    isDisabled ? LibTheme.colors.gray : LibTheme.colors.primary};
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "all")};
  border-radius: 4rem;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;

  p {
    color: ${LibTheme.colors.white};
  }
  &:hover {
    background-color: ${({ isDisabled }) =>
      isDisabled ? LibTheme.colors.gray : "#2cace3"};
  }
`;
