import * as React from "react";
import { SVGProps } from "react";

const SuggestedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      fill="#32C3FF"
      fillRule="nonzero"
      d="M492.733 211.345 377.871 316.658l34.163 154.832c2.667 12.087-4.97 24.047-17.056 26.713a22.41 22.41 0 0 1-16.856-2.974l-128.198-81.533-127.999 81.406c-10.443 6.643-24.294 3.56-30.937-6.883a22.41 22.41 0 0 1-2.959-16.923l34.622-154.638L7.302 211.365c-9.141-8.345-9.787-22.52-1.443-31.661a22.41 22.41 0 0 1 14.51-7.209l150.767-13.79 58.013-143.422c4.64-11.474 17.705-17.013 29.179-12.372A22.41 22.41 0 0 1 270.7 15.283l58.012 143.422L479.63 172.51c12.326 1.127 21.404 12.033 20.276 24.359a22.41 22.41 0 0 1-7.172 14.477Z"
    />
  </svg>
);
export default SuggestedIcon;
