import { FC } from "react";

import { DeliveryAreaZipCodesProps } from "./DeliveryAreaZipCodes.interfaces";
import {
  _DeliveryAreaZipCodesWrapper,
  _Vicinity,
  _ZipCode,
  _ZipCodeItem,
  _ZipCodeList,
} from "./DeliveryAreaZipCodes.styled";

const INITIAL_MAX_ZIP_CODES = 6;
const DeliveryAreaZipCodes: FC<DeliveryAreaZipCodesProps> = (props) => {
  const { zipCodes } = props;
  return (
    <_DeliveryAreaZipCodesWrapper>
      <_ZipCodeList>
        {zipCodes.map(({ zipCode, vicinity }, index) => (
          <_ZipCodeItem key={`zipCode#${index}`}>
            <_ZipCode style={"p16"} variant={"semibold"}>
              {zipCode}
            </_ZipCode>
            <_Vicinity style={"p12"}>{vicinity}</_Vicinity>
          </_ZipCodeItem>
        ))}
      </_ZipCodeList>
    </_DeliveryAreaZipCodesWrapper>
  );
};

export default DeliveryAreaZipCodes;
