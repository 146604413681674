import { useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { ActiveFilterState, AvailableFilterState } from "../../state/Menu";
import { IUseFilter } from "./useFilter.interfaces";

const useFilter = (): IUseFilter => {
  const availableFilters = useRecoilValue(AvailableFilterState);
  const [activeFilters, setActiveFilters] = useRecoilState(ActiveFilterState);

  const toggleFilter = useCallback(
    (filter: string) => {
      if (activeFilters.includes(filter)) {
        setActiveFilters(activeFilters.filter((f) => f !== filter));
      } else {
        setActiveFilters([...activeFilters, filter]);
      }
    },
    [activeFilters, setActiveFilters]
  );

  const resetFilters = () => {
    setActiveFilters([]);
  };
  return {
    toggleFilter,
    availableFilters,
    activeFilters,
    resetFilters,
  };
};

export default useFilter;
