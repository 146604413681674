import { FC } from "react";

import OpeningHours from "../OpeningHours";
import { OnSiteStoreInfosProps } from "./OnSiteStoreInfos.interfaces";
import {
  _AddressGroup,
  _AddressLabel,
  _ImageWrapper,
  _InfoGroup,
  _InfoHeadline,
  _InfoWrapper,
  _MenuDownloadButton,
  _MenuDownloadWrapper,
  _OnSiteStoreInfosWrapper,
  _StoreImage,
} from "./OnSiteStoreInfos.styled";

const OnSiteStoreInfos: FC<OnSiteStoreInfosProps> = (props) => {
  const {
    storeHeadline,
    storeImageUrl,
    address,
    openingHours,
    menuDownloadUrl,
    weekdayLabel,
    weekendLabel,
    menuDownloadLabel,
    activeDeliveryGroup,
  } = props;

  return (
    <_OnSiteStoreInfosWrapper>
      <_ImageWrapper>
        <_StoreImage fill src={storeImageUrl} alt={"Store Image"} />
      </_ImageWrapper>
      <_InfoWrapper>
        <_InfoGroup>
          <_InfoHeadline style={"m24"} variant={"semibold"} as="h1">
            STADTSALAT {activeDeliveryGroup} - {storeHeadline}
          </_InfoHeadline>
          <_AddressGroup>
            <_AddressLabel style={"p20"}>
              {address.streetAndHouseNo}
            </_AddressLabel>
            <_AddressLabel style={"p20"}>{address.city}</_AddressLabel>
          </_AddressGroup>
          <OpeningHours
            openingHours={openingHours}
            weekendLabel={weekendLabel}
            weekdayLabel={weekdayLabel}
          />
        </_InfoGroup>
        <_MenuDownloadWrapper>
          <_MenuDownloadButton
            href={menuDownloadUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={"outlined"}
          >
            {menuDownloadLabel}
          </_MenuDownloadButton>
        </_MenuDownloadWrapper>
      </_InfoWrapper>
    </_OnSiteStoreInfosWrapper>
  );
};

export default OnSiteStoreInfos;
