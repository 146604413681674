import styled from "@emotion/styled";
import { LibTheme, Typography } from "@stadtsalat/component-lib";

export const _MenuWrapper = styled.div`
  margin: 0 1rem 0 1rem;
  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    padding: 1rem 1.25rem;
    max-width: 90rem;
    width: 100%;
    margin: 0rem auto;
  }
`;
export const _NewCustomerWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

export const _NewCustomerProductCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const _NewCustomerInfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 400px;
`;
export const _CategoryWrapper = styled.div`
  width: 100%;
`;

export const _CategoryHeader = styled(Typography)`
  margin-left: 1.3rem !important;
  margin-bottom: 1rem !important;
`;

export const _ProductGrid = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "grid" : "none")};
  grid-template-columns: 1fr;
  column-gap: 1rem;
  row-gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;

export const _CreateYourOwn = styled.div`
  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    grid-column: span 3;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
`;

export const _CreateYourOwnHeroWrapper = styled.div`
  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    background-color: #f8f0e5;
    display: grid;
    grid-template-columns: 2fr 1fr;
    border-radius: 2rem;
    padding: 2rem;
  }
`;

export const _CreateYourOwnImageWrapper = styled.div`
  position: relative;
  background-color: #f8f0e5;
  border-radius: 1rem;
  padding: 1.5rem;
`;

export const _CreateYourOwnInfoWrapper = styled.div`
  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const _CreateYourOwnTitle = styled(Typography)`
  align-self: flex-start;
  text-transform: uppercase;
  line-height: 1.17;
  letter-spacing: -0.3px;
  color: #28190a !important;
  margin-top: 1.25rem !important;

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    font-size: 40px !important;
    margin-bottom: 1rem !important;
  }
`;

export const _CreateYourOwnDescription = styled(Typography)`
  color: #28190a !important;
  margin-top: 0.25rem !important;
  line-height: 1.43;
  letter-spacing: normal;
`;

export const _CreateYourOwnButton = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .button-wrapper {
    p {
      color: #fffaf5 !important;
    }

    img {
      display: none;
    }
  }

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    display: flex;
    width: 100%;
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const _LastBowls = styled.div`
  margin-top: 2rem;
`;

export const _LastBowlsWrapper = styled.div`
  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    padding: 1rem 0;
  }
`;

export const _LastBowlButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button-wrapper {
    max-width: 180px;

    p {
      color: #fffaf5 !important;
    }

    img {
      display: none;
    }
  }
`;

export const _SeparatorWrapper = styled.div`
  hr {
    margin: 1rem 0;
  }
`;

export const _ImageWrapper = styled.div`
  position: relative;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    height: 75px;
  }
`;

export const _LoadingProduct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  z-index: 1;

  .loading-image {
    aspect-ratio: 1;
    height: auto;
  }

  .loading-button {
    border-radius: 3rem;
    overflow: hidden;
  }
`;

export const _MenuCategoryInfoWrapper = styled.div`
  width: 100%;
  grid-column-start: 1;
  grid-column-end: none;
  padding: 1.3rem 0;
`;
