import { AtomEffect } from "recoil";

import { InternalCartItem } from "../util/order";
import { setMouseFlowVariable } from "../util/tracking.util";

type CRUD<T> = {
  get: () => T;
  set: (item: T) => void;
  delete: () => void;
};
export const localStorageEffect: <T>(
  crud: CRUD<T>
) => ({ setSelf, onSet }: { setSelf: any; onSet: any }) => void =
  (crud) =>
  ({ setSelf, onSet }) => {
    const savedValue = crud.get();
    if (savedValue != null) {
      setSelf(savedValue);
    }

    onSet((newValue, _, isReset) => {
      isReset ? crud.delete() : crud.set(newValue);
    });
  };

export const logCartChangeToMouseflow: AtomEffect<InternalCartItem[]> = ({
  onSet,
}) => {
  onSet((newValue) => {
    const numOfPax = newValue.reduce((prev, cur) => {
      if (cur.tags.includes("product.bowl")) {
        return prev + (cur.count ?? 1);
      }
      return prev;
    }, 0);
    if (numOfPax) {
      setMouseFlowVariable("numOfPax", numOfPax.toString());
    }
  });
};
