import * as React from "react";

const ChatBubbleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={493.557}
    height={500.001}
    preserveAspectRatio="none"
    viewBox="3.222 0 493.557 500.001"
    {...props}
  >
    <path
      d="M117.433 65.931h2.62c16.15 0 32.3-.03 48.44.01 12.87.03 23.16 8.35 25.72 20.77.38 1.87.53 3.81.53 5.73.04 18.33.04 36.66.02 54.99-.02 15.25-11.09 26.41-26.38 26.65-.57 0-1.13.05-1.93.09v2.16c0 4.81.06 9.61-.03 14.42-.09 5.05-2.53 8.72-7.16 10.64-4.64 1.92-8.99 1.01-12.58-2.51-7.91-7.77-15.75-15.6-23.57-23.47-1-1-1.99-1.36-3.37-1.35-11.73.04-23.45.07-35.18.01-12.8-.07-23.15-8.48-25.68-20.88-.39-1.93-.51-3.94-.52-5.92-.04-13.46-.02-26.92-.02-40.38v-2.41c-.77.71-1.23 1.11-1.67 1.54-4.36 4.34-8.72 8.68-13.09 13.01-3.34 3.31-7.28 4.28-11.63 2.48-4.34-1.8-6.44-5.28-6.46-9.98-.02-4.16 0-8.31 0-12.3-3.08-.65-6.09-.96-8.88-1.92-8.75-3-14.7-11.21-14.77-20.48-.11-16.02-.14-32.05 0-48.07.11-12.22 10.13-21.91 22.4-21.92 23.32-.02 46.65-.01 69.97 0 13.51 0 23.11 9.56 23.17 23.11.05 11.22.01 22.43.01 33.65v2.33h.04Zm9.24 9.23c-13.91 0-27.81-.03-41.72 0-10.34.03-17.36 7.09-17.37 17.43-.02 18.27-.02 36.53.02 54.8 0 1.59.15 3.22.52 4.76 1.87 7.71 8.44 12.65 16.87 12.68 12.5.04 24.99.04 37.49-.02 2.15-.01 3.78.68 5.28 2.2 4.67 4.75 9.43 9.42 14.16 14.12 3.68 3.66 7.31 7.37 11.08 10.94.61.58 1.98.97 2.69.68.71-.28 1.11-1.49 1.5-2.36.19-.43.04-1.01.04-1.52v-17.88c0-4.64 1.49-6.15 6.05-6.15h4.42c10.81-.03 17.83-7.02 17.84-17.79 0-18.01-.15-36.02.06-54.03.12-10.24-7.48-18.14-17.97-17.93-13.64.28-27.3.07-40.95.07h-.01Zm-18.7-9.23c.13-.45.19-.57.19-.69 0-12.18.18-24.36-.06-36.53-.14-7.34-5.79-12.61-13.08-12.63-23.65-.06-47.29-.06-70.94 0-7.2.02-12.92 5.57-12.98 12.76-.13 15.96-.12 31.92 0 47.88.05 6.39 4.62 11.52 10.91 12.52 2.32.37 4.72.25 7.09.28 3.91.05 5.61 1.7 5.62 5.63.02 5.19-.02 10.38.04 15.57 0 .75.43 1.5.66 2.25.75-.36 1.66-.56 2.23-1.11 6.4-6.29 12.72-12.66 19.13-18.94 1.07-1.05 1.6-2.13 1.78-3.62 1.65-13.98 12.22-23.35 26.33-23.37 6.98-.01 13.97 0 20.95 0h2.13Z"
      className="st1"
      style={{
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 4,
      }}
      transform="translate(-1.25 -17.462) scale(2.55693)"
    />
    <path
      d="M119.793 120.051c0 3.78 3.06 6.84 6.81 6.81 3.68-.02 6.69-3.03 6.73-6.71.04-3.83-2.9-6.84-6.72-6.86-3.82-.03-6.82 2.95-6.82 6.76Zm-21.76 6.81c3.74-.02 6.82-3.14 6.78-6.88-.04-3.76-3.14-6.75-6.94-6.69-3.73.06-6.65 3.02-6.66 6.75 0 3.78 3.06 6.84 6.81 6.82h.01Zm63.88-6.76c.03-3.8-2.98-6.82-6.79-6.81-3.7 0-6.73 2.95-6.81 6.63-.08 3.74 2.97 6.89 6.71 6.94 3.76.05 6.86-2.99 6.89-6.76Z"
      className="st0"
      style={{
        fill: "#fff",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 4,
      }}
      transform="translate(-1.25 -17.462) scale(2.55693)"
    />
    <path
      d="M119.793 120.051c0-3.82 3-6.79 6.82-6.76 3.82.03 6.76 3.03 6.72 6.86-.04 3.68-3.05 6.69-6.73 6.71-3.76.02-6.81-3.03-6.81-6.81ZM98.033 126.861c-3.76.02-6.82-3.04-6.81-6.82 0-3.73 2.93-6.69 6.66-6.75 3.79-.06 6.9 2.93 6.94 6.69.04 3.74-3.04 6.86-6.78 6.88h-.01ZM161.913 120.101c-.03 3.77-3.13 6.81-6.89 6.76-3.74-.05-6.79-3.21-6.71-6.94.08-3.68 3.1-6.62 6.81-6.63 3.81 0 6.82 3.02 6.79 6.81Z"
      className="st1"
      style={{
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 4,
      }}
      transform="translate(-1.25 -17.462) scale(2.55693)"
    />
  </svg>
);
export default ChatBubbleIcon;
