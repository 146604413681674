import styled from "@emotion/styled";
import { LibTheme, Typography } from "@stadtsalat/component-lib";

export const _DeliveryAreaZipCodesWrapper = styled.div`
  width: 100%;
  position: relative;
`;
export const _ZipCodeList = styled.div`
  width: auto;
  display: grid;
  height: auto;
  grid-template-columns: 1fr;
  row-gap: 0;
  column-gap: 1.5rem;
  transition: grid-template-columns 0.3s ease;

  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    grid-template-columns: repeat(2, auto);
  }
  @media (min-width: ${LibTheme.mediaBreakpoints.desktop}) {
    grid-template-columns: repeat(3, auto);
  }
`;
export const _ZipCodeItem = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  opacity: 1;
  transition: opacity 0.3s ease-in;
  margin-bottom: 1rem;

  &.hidden {
    margin: 0;
    height: 0;
    opacity: 0;
    transition: opacity 0.3s ease-out, height 0.25s ease-out 0.25s;

    & > p {
      opacity: 0;
      height: 0;
      transition: opacity 0.3s ease-out, height 0.25s ease-out 0.25s;
    }
  }
`;

export const _ZipCode = styled(Typography)`
  && {
    line-height: 1;
  }
`;
export const _Vicinity = styled(Typography)`
  && {
    line-height: 1;
    color: ${LibTheme.colors.paper_dark_600};
  }
`;

export const _ShowAllWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 2rem 0 0;
  background: rgb(255, 250, 245);
  background: linear-gradient(
    180deg,
    rgba(255, 250, 245, 0) 0%,
    rgba(255, 250, 245, 1) 55%,
    rgba(255, 250, 245, 1) 100%,
    rgba(255, 250, 245, 1) 100%,
    rgba(255, 250, 245, 1) 100%
  );
  display: flex;
  justify-content: center;
`;
export const _IconWrapper = styled.div`
  width: 1.5em;
  height: 1.5em;
  transform: rotate(360deg);
  transition: transform 0.4s ease-in-out;

  &.show-all {
    transform: rotate(180deg);
  }
`;
export const _ShowAllButton = styled.button`
  padding: 0.35rem;
  background-color: #fffaf5;
  outline: none;
  box-shadow: none;
  border-radius: 15rem;
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: row;
  border: 1px solid ${LibTheme.colors.paper_dark_600};
`;
export const _ShowAllLabel = styled(Typography)`
  && {
    width: 7rem;
    text-align: center;
  }
`;
