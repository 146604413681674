import * as React from "react";
import { SVGProps } from "react";

const SeasonalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      fill="#000"
      fillRule="nonzero"
      d="M494.7 65.938a20.814 20.814 0 0 0-12.488-15.82 20.815 20.815 0 0 0-20.817 2.081 281.003 281.003 0 0 1-159.447 41.632c-127.809-1.04-181.513 52.039-182.553 52.039a168.818 168.818 0 0 0-68.276 135.093 166.532 166.532 0 0 0 11.865 64.113 10.613 10.613 0 0 1-2.082 11.031L6.157 416.474c-8.162 8.095-8.216 21.275-.12 29.438l.12.12c7.594 7.207 19.36 7.653 27.477 1.04 36.636-32.68 94.92-130.513 258.322-198.997 10.692-4.484 22.993.55 27.477 11.24 4.483 10.692-.55 22.994-11.24 27.477A544.956 544.956 0 0 0 132.715 402.11l-4.996 5.204a10.62 10.62 0 0 0-2.289 9.16 10.407 10.407 0 0 0 4.996 7.493 166.519 166.519 0 0 0 88.675 25.395 254.154 254.154 0 0 0 120.106-32.888c181.72-100.54 165.068-293.71 155.493-350.536"
    />
  </svg>
);
export default SeasonalIcon;
