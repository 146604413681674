import styled from "@emotion/styled";
import { Typography } from "@stadtsalat/component-lib";

import { _MenuWrapper } from "../../../Menu/Menu.styled";

export const _DeliveryAreaZipCodesInfoWrapper = styled(_MenuWrapper)`
  && {
    margin-bottom: 1rem;
  }
`;

export const _DeliveryAreaZipCodesInfoTextWrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
`;
export const _DeliveryAreaZipCodesInfoHeader = styled(Typography)``;

export const _DeliveryAreaZipCodesInfoPriceLabel = styled(Typography)``;
