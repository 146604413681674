import { FC, useCallback, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useRecoilValue, useSetRecoilState } from "recoil";
import remarkGfm from "remark-gfm";

import useCMS from "../../../../hooks/useCMS";
import { HeroVoucherPropsSelector } from "../../../../state/Menu/Menu.state";
import { CMSDataMenu } from "../../MenuViewPage.interfaces";
import {
  StoreAlertBoxHeightState,
  StoreVoucherBoxHeightState,
} from "../../state/Menu";
import {
  _CopyVoucherButton,
  _DynamicVoucherBoxWrapper,
  _VoucherDescription,
} from "./DynamicVoucherBox.styled";

export interface CMSDynamicVoucherBox {
  voucherCopyButtonLabel: string;
  voucherCopiedLabel: string;
}

export const DynamicVoucherBoxTestIds = {
  wrapper: "dynamic-voucher-box-wrapper",
  voucherCopyButton: "dynamic-voucher-box-copy-button",
  voucherCopyLabel: "dynamic-voucher-box-copy-label",
  voucherCopiedLabel: "dynamic-voucher-box-copied-label",
};
export const CopyButtonResetTimeout = 5000;

const DynamicVoucherBox: FC = () => {
  const { getCMSValueByKeyGenerator } = useCMS();
  const getDynamicVoucherBoxLabels = getCMSValueByKeyGenerator<
    CMSDataMenu,
    CMSDataMenu["dynamicVoucherBox"]
  >("dynamicVoucherBox");
  const alertBoxHeight = useRecoilValue(StoreAlertBoxHeightState);
  const voucherBoxRef = useRef<HTMLDivElement>();
  const setVoucherBoxHeight = useSetRecoilState(StoreVoucherBoxHeightState);
  const [voucherCopied, setVoucherCopied] = useState(false);
  const voucherProps = useRecoilValue(HeroVoucherPropsSelector);

  useEffect(() => {
    if (voucherBoxRef.current) {
      const resizeObserver = new ResizeObserver(([element]) => {
        setVoucherBoxHeight(element.contentRect.height);
      });
      resizeObserver.observe(voucherBoxRef.current);
      return () => resizeObserver.disconnect();
    }
  }, [voucherBoxRef]);

  useEffect(() => {
    let height = 0;
    if (voucherProps && voucherBoxRef.current) {
      height = voucherBoxRef.current.getBoundingClientRect().height;
    }
    setVoucherBoxHeight(height);
  }, [voucherBoxRef, voucherProps]);

  useEffect(() => {
    if (voucherCopied) {
      const timeout = setTimeout(() => {
        setVoucherCopied(false);
      }, CopyButtonResetTimeout);
      return () => clearTimeout(timeout);
    }
  }, [voucherCopied]);

  const copyVoucher = useCallback(() => {
    if (voucherProps?.voucherCode) {
      navigator.clipboard.writeText(voucherProps.voucherCode);
      setVoucherCopied(true);
    }
  }, [voucherProps]);

  return !!voucherProps ? (
    <_DynamicVoucherBoxWrapper
      ref={voucherBoxRef}
      backgroundColor={voucherProps?.backgroundColor}
      textColor={voucherProps?.textColor}
      top={alertBoxHeight}
      data-testid={DynamicVoucherBoxTestIds.wrapper}
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          p({ children }) {
            return (
              <_VoucherDescription style={"p12"}>
                {children}
              </_VoucherDescription>
            );
          },
        }}
      >
        {voucherProps.voucherDescription}
      </ReactMarkdown>
      <_CopyVoucherButton
        className={voucherCopied ? "copied" : ""}
        color={voucherProps.textColor}
        backgroundColor={voucherProps.backgroundColor}
        onClick={() => copyVoucher()}
        data-testid={DynamicVoucherBoxTestIds.voucherCopyButton}
      >
        {voucherCopied ? (
          <span data-testid={DynamicVoucherBoxTestIds.voucherCopiedLabel}>
            <div style={{ width: "12px", height: "12px", marginRight: "6px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12px"
                height="12px"
                strokeWidth="1.5"
                viewBox="0 0 18 18"
                fill="none"
                color={voucherProps.backgroundColor}
              >
                <path
                  d="M5 13L9 17L19 7"
                  stroke="#000000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </div>
            {getDynamicVoucherBoxLabels("voucherCopiedLabel")}
          </span>
        ) : (
          <span data-testid={DynamicVoucherBoxTestIds.voucherCopyLabel}>
            {getDynamicVoucherBoxLabels("voucherCopyButtonLabel")}
          </span>
        )}
      </_CopyVoucherButton>
    </_DynamicVoucherBoxWrapper>
  ) : null;
};

export default DynamicVoucherBox;
