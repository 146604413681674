import { BaseIcon, Icons } from "@stadtsalat/component-lib";
import { FC, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import {
  MenuBackgroundBlurActiveState,
  MenuDeliveryAreaModalOpenState,
} from "../../state/Menu";
import { _CloseButton } from "../Configurator/components/InfoView/InfoView.styled";
import DeliveryAreaZipCodesInfo from "./components/DeliveryAreaZipCodesInfo";
import { DeliveryAreaModalProps } from "./DeliveryAreaModal.interfaces";
import {
  _DeliveryAreaModalWrapper,
  _ModalInnerWrapper,
} from "./DeliveryAreaModal.styled";

const DeliveryAreaModal: FC<DeliveryAreaModalProps> = (props) => {
  const [deliveryAreaModalOpen, setDeliveryAreaModalOpen] = useRecoilState(
    MenuDeliveryAreaModalOpenState
  );
  const setBackgroundBlurActive = useSetRecoilState(
    MenuBackgroundBlurActiveState
  );
  const [isFirstRender, setIsFirstRender] = useState(true);

  const closeModal = () => {
    setIsFirstRender(false);
    setDeliveryAreaModalOpen(false);
    setBackgroundBlurActive(false);
  };

  return (
    <_DeliveryAreaModalWrapper
      active={deliveryAreaModalOpen}
      isFirstRender={isFirstRender}
    >
      <_ModalInnerWrapper>
        <_CloseButton onClick={closeModal}>
          <BaseIcon icon={Icons.CLOSE} />
        </_CloseButton>
        <DeliveryAreaZipCodesInfo />
      </_ModalInnerWrapper>
    </_DeliveryAreaModalWrapper>
  );
};

export default DeliveryAreaModal;
