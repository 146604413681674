import * as React from "react";

const HighProteinIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <g
      data-name="labels-/-neue-rezeptur"
      transform="translate(-145.16 -39.565) scale(30.72782)"
    >
      <rect
        width={5.57}
        height={1.7}
        x={10.07}
        y={9.15}
        className="cls-1"
        rx={0.12}
        ry={0.12}
        transform="rotate(-11 12.874 9.992)"
      />
      <rect
        width={8.16}
        height={1.7}
        x={4.75}
        y={9.93}
        className="cls-1"
        rx={0.41}
        ry={0.41}
        transform="rotate(-101 8.827 10.779)"
      />
      <rect
        width={8.16}
        height={1.7}
        x={12.78}
        y={8.37}
        className="cls-1"
        rx={0.41}
        ry={0.41}
        transform="rotate(-101 16.857 9.223)"
      />
      <rect
        width={7.05}
        height={1.47}
        x={3.3}
        y={10.43}
        className="cls-1"
        rx={0.36}
        ry={0.36}
        transform="rotate(-101 6.832 11.171)"
      />
      <path
        d="m5.52 10.56-.11.02c-.46.09-.76.54-.67 1s.54.76 1 .67l.11-.02-.32-1.67Z"
        className="cls-1"
      />
      <rect
        width={7.05}
        height={1.47}
        x={15.34}
        y={8.09}
        className="cls-1"
        rx={0.36}
        ry={0.36}
        transform="rotate(-101 18.87 8.823)"
      />
      <path
        d="m19.99 7.75-.11.02.32 1.67.11-.02c.46-.09.76-.54.67-1s-.54-.76-1-.67Z"
        className="cls-1"
      />
    </g>
  </svg>
);
export default HighProteinIcon;
