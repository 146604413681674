import styled from "@emotion/styled";
import { LibTheme, Typography } from "@stadtsalat/component-lib";
import Image from "next/image";

export const _OnSiteStoreInfosWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  @media (min-width: ${LibTheme.mediaBreakpoints.desktop}) {
    padding-right: 1rem;
    border-right: 2px dotted ${LibTheme.colors.paper_light_900};
    grid-template-columns: repeat(2, 50%);
  }
`;

export const _ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  border-radius: 0.25rem;
`;
export const _StoreImage = styled(Image)`
  && {
    width: 100%;
    object-fit: cover;
    position: relative !important;
    @media (min-width: ${LibTheme.mediaBreakpoints.desktop}) {
      height: auto;
    }
  }
`;
export const _InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media (min-width: ${LibTheme.mediaBreakpoints.desktop}) {
    gap: 2rem;
  } ;
`;

export const _InfoGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;
export const _MenuDownloadWrapper = styled.div`
  width: 100%;
  display: flex;
`;
export const _MenuDownloadButton = styled.a`
  text-decoration: none;
  cursor: pointer;
  //color: ${LibTheme.colors.background};
  //background-color: ${LibTheme.colors.paper_dark_700};
  background-color: transparent;
  border: 1px solid ${LibTheme.colors.paper_dark_700};
  color: ${LibTheme.colors.paper_dark_700};
  font-size: 0.875rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  padding: 0.5rem 2rem;
  border-radius: 12rem;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    color: ${LibTheme.colors.background};
    background-color: ${LibTheme.colors.paper_dark_700};
    transition: background-color 0.5s ease-in-out, color 1s ease-in-out;
  }
`;
export const _InfoHeadline = styled(Typography)`
  && {
    line-height: 1;
  }
`;

export const _AddressGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
export const _AddressLabel = styled(Typography)`
  && {
    line-height: 1;
    color: ${LibTheme.colors.paper_dark_600};
    font-weight: 200;
  }
`;
