import { HeroBannerProps } from "../../../pages/[[...city]]";

export const isBannerActive = (bannerProps: HeroBannerProps) => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();

  const [bannerStartHours, bannerStartMinutes] =
    bannerProps.startTime.split(":");
  const [bannerEndHours, bannerEndMinutes] = bannerProps.endTime.split(":");

  const isInTimeRange =
    (hours >= Number(bannerStartHours) && hours < Number(bannerEndHours)) ||
    (hours === Number(bannerEndHours) &&
      minutes >= Number(bannerStartMinutes) &&
      minutes <= Number(bannerEndMinutes));
  const bannerIsActive = !!bannerProps.active;
  return isInTimeRange && bannerIsActive;
};

export const showVoucher = (voucherSeenTimeStamp?: string): boolean => {
  if (!voucherSeenTimeStamp) return true;
  const today = new Date();
  const voucherSeenDate = new Date(parseInt(voucherSeenTimeStamp));
  return (
    today.getDate() === voucherSeenDate.getDate() &&
    today.getMonth() === voucherSeenDate.getMonth() &&
    today.getFullYear() === voucherSeenDate.getFullYear()
  );
};

const getCookieKey = (voucherCode: string) => `voucher_${voucherCode}`;
export const getVoucherSeenCookie = (voucherCode: string): string | null => {
  if (!voucherCode || voucherCode === "") return null;
  const cookies = Object.fromEntries(
    document.cookie.split(";").map((c) => c.trim().split("="))
  );
  return cookies[getCookieKey(voucherCode)] ?? null;
};
export const setVoucherSeenCookie = (
  voucherCode: string,
  expiresInDays: number
) => {
  if (voucherCode && voucherCode !== "") {
    const expiresInMS = expiresInDays * 24 * 60 * 60 * 1000;
    document.cookie = `${getCookieKey(
      voucherCode
    )}=${Date.now()};expires=${new Date(
      Date.now() + expiresInMS
    ).toUTCString()};path=/`;
  }
};
