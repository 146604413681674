import Loading from "@components/Loading";
import Image from "next/image";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import useBrowserInfo from "../../../../../../hooks/useBrowserInfo";
import useCMS from "../../../../../../hooks/useCMS";
import {
  HeroBannerPropsInitiatedState,
  HeroBannerPropsState,
  HeroVisibleState,
} from "../../../../../../state/Menu/Menu.state";
import { CMSDataMenu } from "../../../../MenuViewPage.interfaces";
import { HeroProps } from "../../StoreInfoSelect.interfaces";
import {
  _HeroLogoOuterWrapper,
  _HeroLogoRelativeWrapper,
  _ImageLoadingWrapper,
} from "../../StoreInfoSelect.styled";

export const DynamicHeroImageTestIds = {
  heroImage: "hero-image",
  heroLogo: "hero-logo",
  loadingWrapper: "loading-wrapper",
};
const HeroImage: FC<HeroProps> = (props) => {
  const {
    heroImageAltText,
    heroLogoAltText,
    heroImage,
    heroImageMobile,
    heroLogo,
    heroLogoMobile,
  } = props;
  const { isMobile } = useBrowserInfo();
  const [isHeroImageLoading, setIsHeroImageLoading] = useState(true);
  const [isHeroLogoLoading, setIsHeroLogoLoading] = useState(true);
  const setHeroIsVisible = useSetRecoilState(HeroVisibleState);
  const heroRef = useRef();
  useEffect(() => {
    if (heroRef.current) {
      const observer = new IntersectionObserver(
        ([element]) => {
          setHeroIsVisible(element.isIntersecting);
        },
        { threshold: 0 }
      );
      observer.observe(heroRef.current);
      return () => observer.disconnect();
    }
  }, [heroRef]);

  const onHeroLoad = useCallback(() => {
    setIsHeroImageLoading(false);
  }, [setIsHeroImageLoading]);
  const onLogoLoad = useCallback(() => {
    setIsHeroLogoLoading(false);
  }, [setIsHeroLogoLoading]);
  const heroProps = useRecoilValue(HeroBannerPropsState);
  const heroIsInitiated = useRecoilValue(HeroBannerPropsInitiatedState);
  return (
    <div>
      <_ImageLoadingWrapper
        isVisible={
          (!heroProps && !heroIsInitiated) ||
          isHeroImageLoading ||
          isHeroLogoLoading
        }
        data-testid={DynamicHeroImageTestIds.loadingWrapper}
      >
        <Loading
          width={"100%"}
          height={"100%"}
          baseColor="#f8f0e5"
          highlightColor="#e4ddd3"
        />
      </_ImageLoadingWrapper>
      <Image
        ref={heroRef}
        onLoad={onHeroLoad}
        src={isMobile ? heroImageMobile : heroImage}
        alt={heroImageAltText}
        fill
        sizes={"100vw, (min-width: 1201px) 1200px"}
        style={{ objectFit: "cover" }}
        priority={true}
        data-testid={DynamicHeroImageTestIds.heroImage}
      />
      <_HeroLogoOuterWrapper>
        <_HeroLogoRelativeWrapper>
          <Image
            onLoad={onLogoLoad}
            src={isMobile ? heroLogoMobile : heroLogo}
            fill
            alt={heroLogoAltText}
            style={{ objectFit: "contain" }}
            data-testid={DynamicHeroImageTestIds.heroLogo}
          />
        </_HeroLogoRelativeWrapper>
      </_HeroLogoOuterWrapper>
    </div>
  );
};

export const StaticFallbackHeroProps = {
  heroImage: "/assetsV2/images/menu-hero.jpg",
  heroLogo: "/assetsV2/images/hero-logo.png",
  heroImageAltText:
    "Zwei ansprechend angerichtete Bowls mit einer Vielfalt an frischen und farbenfrohen Zutaten auf einem dunklen grünen Stoffhintergrund",
  heroLogoAltText: "Stadtsalat Logo über dem Banner",
};

const DynamicHeroImage: FC = () => {
  const heroProps = useRecoilValue(HeroBannerPropsState);
  const { getCMSValueByKeyGenerator } = useCMS();
  const getStoreInfoSelectCMSData = getCMSValueByKeyGenerator<
    CMSDataMenu,
    CMSDataMenu["storeInfoSelect"]
  >("storeInfoSelect");
  const defaultBanner: HeroProps =
    getStoreInfoSelectCMSData("defaultBanners") ?? StaticFallbackHeroProps;
  return (
    <HeroImage
      heroImage={heroProps?.heroImage ?? defaultBanner?.heroImage}
      heroImageMobile={
        heroProps?.heroImageMobile ?? defaultBanner?.heroImageMobile
      }
      heroImageAltText={
        heroProps?.heroImageAltText ?? defaultBanner?.heroImageAltText
      }
      heroLogo={heroProps?.heroLogo ?? defaultBanner?.heroLogo}
      heroLogoMobile={
        heroProps?.heroLogoMobile ?? defaultBanner?.heroLogoMobile
      }
      heroLogoAltText={
        heroProps?.heroLogoAltText ?? defaultBanner?.heroLogoAltText
      }
    />
  );
};

export default DynamicHeroImage;
